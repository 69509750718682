// UnsubscribeConfirmationScreen.js
import React, { useEffect, useState, useContext } from "react";
import { View, Text, ActivityIndicator, StyleSheet, TouchableOpacity } from "react-native";
import { SERVER_ADDRESS } from "../docs/config";
import { AppContext } from "../../AppContext";

export default function UnsubscribeConfirmationScreen({ route, navigation }) {
  const { token } = route.params ?? {};
  const [status, setStatus] = useState("procesando"); // 'procesando', 'exito', 'error'
  const { language } = useContext(AppContext);

  const translations = {
    ESP: {
      processing: "Procesando tu solicitud...",
      successTitle: "¡Desuscripción Exitosa!",
      successMessage: "Te has desuscrito correctamente de los correos de Trocalia. Puedes volver a suscribirte en cualquier momento desde la configuración de tu cuenta.",
      errorTitle: "¡Error!",
      errorMessage: "Lo sentimos, no pudimos procesar tu solicitud en este momento. Por favor, intenta de nuevo más tarde o contacta con nuestro soporte.",
      buttonText: "Principal",
    },
    CAT: {
      processing: "Processant la teva sol·licitud...",
      successTitle: "¡Desubscripció Exitosa!",
      successMessage: "T'has desubscrit correctament dels correus de Trocalia. Pots tornar a subscriptar en qualsevol moment des de la configuració del teu compte.",
      errorTitle: "¡Error!",
      errorMessage: "Ho sentim, no hem pogut processar la teva sol·licitud en aquest moment. Si us plau, intenta-ho de nou més tard o contacta amb el nostre suport.",
      buttonText: "Principal",
    },
    ENG: {
      processing: "Processing your request...",
      successTitle: "Unsubscription Successful!",
      successMessage: "You have successfully unsubscribed from Trocalia emails. You can resubscribe at any time from your account settings.",
      errorTitle: "Error!",
      errorMessage: "Sorry, we couldn't process your request at this time. Please try again later or contact our support.",
      buttonText: "Home",
    },
  };

  // Obtener las traducciones según el idioma actual, o por defecto ESP
  const t = translations[language] || translations["ESP"];

  useEffect(() => {
    async function doUnsubscribe() {
      if (!token) {
        setStatus("error");
        return;
      }
      try {
        const res = await fetch(`${SERVER_ADDRESS}/unsubscribe?token=${token}`, {
          method: "GET",
          headers: { Accept: "application/json" }, // Importante para recibir JSON
        });
        if (res.ok) {
          setStatus("exito");
        } else {
          setStatus("error");
        }
      } catch (error) {
        console.error("Error al desuscribir:", error);
        setStatus("error");
      }
    }
    doUnsubscribe();
  }, [token]);

  const handleGoHome = () => {
    navigation.navigate("Home"); // Asegúrate de que "Home" sea el nombre correcto de tu pantalla principal
  };

  if (status === "procesando") {
    return (
      <View style={styles.container}>
        <ActivityIndicator size="large" color="#4CAF50" />
        <Text style={styles.processingText}>{t.processing}</Text>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      {status === "exito" ? (
        <View style={styles.messageContainer}>
          <Text style={styles.successTitle}>{t.successTitle}</Text>
          <Text style={styles.successMessage}>{t.successMessage}</Text>
        </View>
      ) : (
        <View style={styles.messageContainer}>
          <Text style={styles.errorTitle}>{t.errorTitle}</Text>
          <Text style={styles.errorMessage}>{t.errorMessage}</Text>
        </View>
      )}
      <TouchableOpacity style={styles.button} onPress={handleGoHome}>
        <Text style={styles.buttonText}>{t.buttonText}</Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F5F5F5",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
  processingText: {
    marginTop: 20,
    fontSize: 16,
    color: "#555",
    textAlign: "center",
    fontFamily: "Poppins-Regular", // Asegúrate de que la fuente esté cargada
  },
  messageContainer: {
    marginBottom: 30,
    alignItems: "center",
  },
  successTitle: {
    fontSize: 20,
    color: "#4CAF50",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 10,
    fontFamily: "Poppins-SemiBold",
  },
  successMessage: {
    fontSize: 16,
    color: "#333",
    textAlign: "center",
    maxWidth: 300,
    fontFamily: "Poppins-Regular",
  },
  errorTitle: {
    fontSize: 20,
    color: "#F44336",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 10,
    fontFamily: "Poppins-SemiBold",
  },
  errorMessage: {
    fontSize: 16,
    color: "#333",
    textAlign: "center",
    maxWidth: 300,
    fontFamily: "Poppins-Regular",
  },
  button: {
    backgroundColor: "#4CAF50",
    paddingVertical: 12,
    paddingHorizontal: 25,
    borderRadius: 25,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 3.84,
    elevation: 5,
  },
  buttonText: {
    color: "#FFFFFF",
    fontSize: 16,
    fontWeight: "600",
    textAlign: "center",
    fontFamily: "Poppins-Medium",
  },
});
