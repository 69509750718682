import React, { useContext, useState, useRef, useEffect } from "react";
import {
  Text,
  View,
  StyleSheet,
  Modal,
  TouchableOpacity,
  Image,
  Animated,
  Easing,
} from "react-native";
import { AppContext } from "../../AppContext";
import Ubication from "./Ubication";
import pinImage from "../img/icons/location.png";
import { Colors, Fonts } from "../styles/styles.js";

/* ====== TRADUCCIONES ====== */
const translations = {
  ESP: {
    greeting: "Hola,",
    convidat: "Invitado",
  },
  CAT: {
    greeting: "Hola,",
    convidat: "Convidat",
  },
  ENG: {
    greeting: "Hello,",
    convidat: "Guest",
  },
};

const Greeting = () => {
  const { userData, language } = useContext(AppContext);
  const t = translations[language];
  const [modalVisible, setModalVisible] = useState(false);

  const showLocationModal = () => setModalVisible(true);
  const hideLocationModal = () => setModalVisible(false);

  /** 
   * Determinamos si ya se han cargado los datos 
   * (userData no es null/undefined).
   */
  const isLoaded = !!userData;

  /**
   * Función para obtener el nombre a mostrar.
   * - Si no hay datos, retornamos cadena vacía.
   * - Si es "Convidat", lo traducimos.
   */
  const getDisplayName = () => {
    if (!isLoaded) return "";
    const rawName = userData.data[0]?.nomUsuari?.trim() || "";
    return rawName === "Convidat" ? t.convidat : rawName;
  };

  /**
   * Función para obtener la ubicación (zona).
   */
  const getLocation = () => {
    if (!isLoaded) return "";
    return userData.data[0]?.zona || "";
  };

  /* ============== ANIMACIONES ============== */
  // Animaciones para el saludo + nombre
  const greetingOpacity = useRef(new Animated.Value(0)).current; // Opacidad inicial 0
  const greetingScale = useRef(new Animated.Value(0.95)).current;  // Escala inicial 0.95

  // Animaciones para la ubicación
  const locationOpacity = useRef(new Animated.Value(0)).current; // Opacidad inicial 0
  const locationTranslateX = useRef(new Animated.Value(-20)).current; // Desplazamiento inicial desde la izquierda

  useEffect(() => {
    if (isLoaded) {
      // Animación para el saludo + nombre: Fade-in y Scale-up
      Animated.parallel([
        Animated.timing(greetingOpacity, {
          toValue: 1,
          duration: 700,
          easing: Easing.out(Easing.ease),
          useNativeDriver: true,
        }),
        Animated.spring(greetingScale, {
          toValue: 1,
          friction: 5,
          useNativeDriver: true,
        }),
      ]).start();

      // Animación para la ubicación: Fade-in y TranslateX
      Animated.parallel([
        Animated.timing(locationOpacity, {
          toValue: 1,
          duration: 700,
          delay: 500, // Retardo para que aparezca después del saludo
          easing: Easing.out(Easing.ease),
          useNativeDriver: true,
        }),
        Animated.timing(locationTranslateX, {
          toValue: 0,
          duration: 700,
          delay: 500, // Sincroniza con la opacidad
          easing: Easing.out(Easing.ease),
          useNativeDriver: true,
        }),
      ]).start();
    }
  }, [isLoaded, greetingOpacity, greetingScale, locationOpacity, locationTranslateX]);

  return (
    <View style={styles.container}>
      {/* Modal de ubicación */}
      <Modal
        animationType="slide"
        transparent={false}
        visible={modalVisible}
        onRequestClose={hideLocationModal}
      >
        <Ubication hideModal={hideLocationModal} />
      </Modal>

      <View style={styles.greetingContainer}>
        <View style={styles.overlapGroup}>
          {/* 
            1) Texto del saludo "Hola," + nombre dentro de Animated.Text
            2) Inicialmente invisibles, se animan al cargar
          */}
          <Animated.Text
            style={[
              styles.greetingName,
              {
                opacity: greetingOpacity,
                transform: [{ scale: greetingScale }],
              },
            ]}
          >
            {t.greeting} {getDisplayName()}
          </Animated.Text>

          {/* 
            2) Botón de ubicación con icono + texto dentro de Animated.View
            3) Inicialmente invisibles, se animan al cargar
          */}
          <Animated.View
            style={[
              styles.locationContainer,
              {
                opacity: locationOpacity,
                transform: [{ translateX: locationTranslateX }],
              },
            ]}
          >
            <TouchableOpacity onPress={showLocationModal} style={styles.locRow}>
              <Image source={pinImage} style={styles.pinInstance} />
              <Text style={styles.location}>{getLocation()}</Text>
            </TouchableOpacity>
          </Animated.View>
        </View>
      </View>
    </View>
  );
};

/* ============== ESTILOS ============== */
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: -4,
    marginLeft: -90,
  },
  greetingContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  overlapGroup: {
    height: 63,
    width: 233,
    position: "relative",
  },
  /* El saludo ("Hola,") y el nombre juntos */
  greetingName: {
    ...Fonts.dmSerifDisplayRegular24,
    color: Colors.black,
    // Eliminamos el ancho fijo para evitar espacio adicional
    // width: 300, // <-- Eliminado
  },
  /* Contenedor de ubicación */
  locationContainer: {
    flexDirection: "row",
    alignItems: "center",
    position: "absolute",
    top: 36,
    left: 0,
  },
  locRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  location: {
    ...Fonts.poppinsRegular14,
    color: Colors.primary,
    marginLeft: 5,
  },
  pinInstance: {
    height: 16,
    width: 16,
  },
});

export default Greeting;
