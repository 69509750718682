import React, { useState, useEffect, useRef, useContext } from "react";
import { LogBox } from "react-native";
import { Linking } from "react-native";
import * as ScreenOrientation from "expo-screen-orientation";

// Ignorar avisos específicos
LogBox.ignoreLogs(["onAnimatedValueUpdate"]);
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import {
  TouchableOpacity,
  Text,
  TextInput,
  StatusBar,
  Modal,
  Platform,
  View,
  StyleSheet,
} from "react-native";
import { useFonts } from "expo-font";
import * as SecureStore from "expo-secure-store";
import { AppContextProvider, AppContext } from "./AppContext"; // Asegúrate de importar AppContext
import { Colors, Fonts } from "./src/styles/styles.js";
import { NavBar } from "./src/components/NavBar.js";
import CategoryDetailsScreen from "./src/screens/CategoryDetailsScreen";
import ProfileOther from "./src/screens/ProfileOther.js";
import Filters from "./src/components/Filters.js";
import Pro from "./src/components/Pro";
import Login from "./src/screens/Login";
import OnboardingScreen from "./src/screens/OnboardingScreen";
import Register from "./src/screens/Register";
import { ToastProvider } from "./ToastContext";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { DataJsonProvider } from "./DataContext.js";
import Forgot from "./src/screens/Forgot.js";
import Terms from "./src/screens/Terms.js";
import Privacy from "./src/screens/Privacy.js";
import ProfileScreen from "./src/screens/ProfileScreen";
import Faq from "./src/screens/faq.js";
import ChatScreen from "./src/screens/ChatScreen.js";
import { SERVER_ADDRESS, SHARE_BASE_URL } from "./src/docs/config.js";
import SocketIOClient from "socket.io-client";
import SearchScreen from "./src/screens/SearchScreen.js";
import { SocketProvider } from "./SocketContext.js";
import { RevenueCatProvider } from "./RevenueCatContext.js";
import { ExpoPushProvider } from "./ExpoPushContext.js";
import NeedRegister from "./src/selectables/NeedRegister.js";
import Loading from "./src/components/Loading.js";
import UnsubscribeConfirmationScreen from "./src/screens/UnsubscribeConfirmationScreen.js";


// Desactivar el escalado de fuentes para todos los componentes de texto
Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.allowFontScaling = false;

// Hacer lo mismo para TextInput si es necesario
TextInput.defaultProps = TextInput.defaultProps || {};
TextInput.defaultProps.allowFontScaling = false;

if (typeof global === "undefined") {
  global = window;
}


const Stack = createStackNavigator();

const linking = {
  prefixes: ["trocalia://", "https://trocalia.net", "https://trocalia.net/"],
  config: {
    screens: {
      ProfileOther: "perfil/:userId",
      UnsubscribeConfirmation: {
        path: "unsubscribe",     // matches "/unsubscribe"
        parse: {
          token: (token) => `${token}`, // coge token de ?token=...
        },
      },
    },
  },
};

const fontAssets = {
  "DM-Serif-Display-Regular": require("./assets/fonts/DM-Serif-Display-Regular.ttf"),
  "Poppins-Regular": require("./assets/fonts/Poppins-Regular.ttf"),
  "Poppins-Medium": require("./assets/fonts/Poppins-Medium.ttf"),
  "Poppins-SemiBold": require("./assets/fonts/Poppins-SemiBold.ttf"),
};

const getBackButtonTitle = (language) => {
  switch (language) {
    case "ESP":
      return "Volver";
    case "CAT":
      return "Tornar";
    case "ENG":
      return "Back";
    default:
      return "Back";
  }
};

export default function App() {
  const navigationRef = useRef(null);
  const [fontsLoaded] = useFonts(fontAssets);
  const [hasToken, setHasToken] = useState(null);
  const [proModalVisible, setProModalVisible] = useState(false);
  const [hasSeenOnboarding, setHasSeenOnboarding] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [token, setToken] = useState(null);
  const appContext = useContext(AppContext);

  useEffect(() => {
    const loadToken = async () => {
      let storedToken;
      if (storedToken) {
        setToken(storedToken);
      }
    };
    loadToken();
  }, []);

  useEffect(() => {
    const lockOrientation = async () => {
      await ScreenOrientation.lockAsync(
        ScreenOrientation.OrientationLock.PORTRAIT_UP
      );
    };
    lockOrientation();
  }, []);

  const checkOnboarding = async () => {
    if (Platform.OS === "web") {
      setHasSeenOnboarding(true);
    } else {
      let value = await SecureStore.getItemAsync("hasSeenOnboarding");
      setHasSeenOnboarding(value === "true");
    }
  };

  useEffect(() => {
    checkOnboarding();

    const verifyToken = async () => {
      let storedToken;
      if (Platform.OS !== "web") {
        storedToken = await SecureStore.getItemAsync("userToken");
      } else {
        storedToken = localStorage.getItem("userToken");
      }

      if (!storedToken) {
        setHasToken(false);
        return;
      }

      try {
        const response = await fetch(`${SERVER_ADDRESS}/comprovarToken`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });

        if (response.status === 200) {
          setHasToken(true);
        } else {
          setHasToken(false);
          appContext.handleTokenExpiry();
          if (Platform.OS !== "web") {
            await SecureStore.deleteItemAsync("userToken");
          } else {
            localStorage.removeItem("userToken");
          }
        }
      } catch (error) {
        setHasToken(false);
        if (appContext && typeof appContext.handleTokenExpiry === "function") {
          appContext.handleTokenExpiry();
        }
      }
    };

    verifyToken();
  }, []);

  useEffect(() => {
    setRefresh(!refresh);
  }, [hasToken]);

  useEffect(() => {
    if (hasToken === false) {
      navigationRef.current?.reset({
        index: 0,
        routes: [{ name: "Register" }],
      });
    }
  }, [hasToken]);

  const toggleProModal = () => setProModalVisible(!proModalVisible);

  let initialScreen = "Register";
  if (!hasSeenOnboarding) {
    initialScreen = "OnboardingScreen";
  } else if (hasToken) {
    initialScreen = "Home";
  }

  if (hasToken === null || hasSeenOnboarding === null || !fontsLoaded) {
    return <Loading />;
  }

  return (
    <AppContextProvider>
      <RevenueCatProvider>
        <ToastProvider>
          <ExpoPushProvider>
            <SocketProvider>
              <DataJsonProvider serverAddress={SERVER_ADDRESS}>
                <AppContext.Consumer>
                  {(appContext) => (
                    <View style={styles.appContainer}>
                      <GestureHandlerRootView style={{ flex: 1 }}>
                        <NavigationContainer
                          linking={linking}
                          ref={navigationRef}
                        >
                          <StatusBar style="auto" />
                          <Modal
                            animationType="slide"
                            transparent={true}
                            visible={proModalVisible}
                            onRequestClose={toggleProModal}
                          >
                            <TouchableOpacity
                              style={{ flex: 1 }}
                              onPress={toggleProModal}
                              activeOpacity={1}
                            >
                              <Pro closeModal={toggleProModal} />
                            </TouchableOpacity>
                          </Modal>
                          <Stack.Navigator initialRouteName={initialScreen}>
                            <Stack.Screen
                              name="OnboardingScreen"
                              component={OnboardingScreen}
                              options={{ headerShown: false }}
                            />
                            <Stack.Screen
                              name="Login"
                              component={Login}
                              options={{
                                headerShown: false,
                                gestureEnabled: true,
                              }}
                            />
                            <Stack.Screen
                              name="ProfileScreen"
                              component={ProfileScreen}
                              options={{
                                headerShown: true,
                                gestureEnabled: true,
                                headerTitle: "",
                                headerBackTitle: getBackButtonTitle(
                                  appContext.language
                                ),
                              }}
                            />
                            <Stack.Screen
                              name="SearchScreen"
                              component={SearchScreen}
                              options={({ route }) => ({
                                title: route.params?.categoryName || "Buscar",
                                headerShown: true,
                                gestureEnabled: true,
                                headerBackTitle: getBackButtonTitle(
                                  appContext.language
                                ),
                              })}
                            />
                            <Stack.Screen
                              name="Register"
                              component={Register}
                              options={{ headerShown: false }}
                            />
                            <Stack.Screen
                              name="Forgot"
                              component={Forgot}
                              options={{
                                headerTitle: "",
                                headerBackTitle: getBackButtonTitle(
                                  appContext.language
                                ),
                              }}
                            />
                            <Stack.Screen
                              name="Terms"
                              component={Terms}
                              options={{
                                headerTitle: "",
                                headerBackTitle: getBackButtonTitle(
                                  appContext.language
                                ),
                              }}
                            />
                            <Stack.Screen
                              name="Privacy"
                              component={Privacy}
                              options={{
                                headerTitle: "",
                                headerBackTitle: getBackButtonTitle(
                                  appContext.language
                                ),
                              }}
                            />
                            <Stack.Screen
                              name="Faq"
                              component={Faq}
                              options={{
                                headerTitle: "",
                                headerBackTitle: getBackButtonTitle(
                                  appContext.language
                                ),
                              }}
                            />
                            <Stack.Screen
                              name="Home"
                              component={NavBar}
                              options={{
                                headerShown: false,
                                gestureEnabled: false,
                              }}
                            />
                            <Stack.Screen
                              name="ChatScreen"
                              component={ChatScreen}
                              options={{
                                headerBackTitle: getBackButtonTitle(
                                  appContext.language
                                ),
                              }}
                            />
                            <Stack.Screen
                              name="CategoryDetails"
                              component={CategoryDetailsScreen}
                              options={({ route }) => {
                                const category = route.params?.category;
                                const currentLanguage =
                                  appContext.language || "CAT";

                                const categoryTitle =
                                  currentLanguage === "CAT"
                                    ? category?.nombre_CAT
                                    : currentLanguage === "ENG"
                                    ? category?.nombre_ENG
                                    : category?.nombre;

                                return {
                                  title:
                                    categoryTitle || "Detalles de la Categoría",
                                  headerBackTitle: getBackButtonTitle(
                                    appContext.language
                                  ),
                                };
                              }}
                            />
                            <Stack.Screen
                              name="ProfileOther"
                              component={ProfileOther}
                              options={({ route }) => ({
                                title: "",
                                headerBackTitle: getBackButtonTitle(
                                  appContext.language
                                ),
                              })}
                            />
                                                    <Stack.Screen
                              name="UnsubscribeConfirmation"
                              component={UnsubscribeConfirmationScreen}
                              options={{ headerShown: false }}
                            />
                          </Stack.Navigator>
                        </NavigationContainer>
                      </GestureHandlerRootView>
                    </View>
                  )}
                </AppContext.Consumer>
              </DataJsonProvider>
            </SocketProvider>
          </ExpoPushProvider>
        </ToastProvider>
      </RevenueCatProvider>
    </AppContextProvider>
  );
}

const styles = StyleSheet.create({
  appContainer: {
    flex: 1,
    marginTop: Platform.OS === "web" ? -50 : 0,
  },
});
