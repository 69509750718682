// NavBar.js
import React, { useContext, useState, useEffect, useRef } from "react";
import {
  View,
  Image,
  TouchableOpacity,
  Animated,
  Easing,
  StyleSheet,
} from "react-native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { Colors, Fonts } from "../styles/styles.js";
import HomeScreen from "../screens/HomeScreen";
import TrocScreen from "../screens/TrocScreen";
import SearchScreen from "../screens/SearchScreen";
import ProfileScreen from "../screens/ProfileScreen";
import MessagesScreen from "../screens/MessagesScreen";
import { AppContext } from "../../AppContext";
import { SocketContext } from "../../SocketContext";
import NeedRegister from "../selectables/NeedRegister.js";

// Importación de íconos
import IniciIcon from "../img/navbar/muro.png";
import TrocIcon from "../img/navbar/troc.png";
import BuscarIcon from "../img/navbar/buscar.png";
import MissatgesIcon from "../img/navbar/missatge.png";

// Importación de imágenes de perfil (sin default)
import profileImages from "../img/profileimage";
// Se elimina la importación de defaultProfileImg

const translations = {
  ESP: {
    inici: "Muro",
    troc: "Troc",
    buscar: "Buscar",
    missatges: "Mensajes",
    perfil: "Perfil",
  },
  CAT: {
    inici: "Mur",
    troc: "Troc",
    buscar: "Cercar",
    missatges: "Missatges",
    perfil: "Perfil",
  },
  ENG: {
    inici: "Wall",
    troc: "Troc",
    buscar: "Search",
    missatges: "Messages",
    perfil: "Profile",
  },
};

const Tab = createBottomTabNavigator();

// Ajuste de tamaños y radios para la imagen de perfil
const ICON_SIZE = 38;
const PROFILE_IMAGE_SIZE = 26; // Reducido para acomodar el borde
const PROFILE_BORDER_WIDTH = 2;
const PROFILE_CONTAINER_SIZE = PROFILE_IMAGE_SIZE + PROFILE_BORDER_WIDTH;
const PROFILE_BORDER_RADIUS = PROFILE_CONTAINER_SIZE / 2; // 15
const PROFILE_IMAGE_RADIUS = PROFILE_IMAGE_SIZE / 2; // 13

export const NavBar = () => {
  const { language, userData, serverAddress } = useContext(AppContext);
  const {
    fetchNotifications,
    hasUnseenMessageNotifications,
    hasUnseenGeneralNotifications,
    clearMessageNotificationsDot,
  } = useContext(SocketContext);
  const [isNeedRegisterVisible, setIsNeedRegisterVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // Estado para rastrear si la pestaña de perfil está enfocada
  const [isProfileFocused, setIsProfileFocused] = useState(false);

  const routeNameToUniqueIdentifier = {};
  Object.keys(translations[language]).forEach((key) => {
    const translatedName = translations[language][key];
    routeNameToUniqueIdentifier[translatedName] = key;
  });

  const checkAccess = (translatedRouteName) => {
    const uniqueIdentifier = routeNameToUniqueIdentifier[translatedRouteName];
    if (!uniqueIdentifier) {
      return true; // Si no podemos mapear el nombre de la ruta, permitir la navegación
    }
    const routesRequiringRegistration = ["missatges", "perfil"];

    if (
      userData?.data?.[0]?.tipusUsuari === "Guest" &&
      routesRequiringRegistration.includes(uniqueIdentifier)
    ) {
      setIsNeedRegisterVisible(true);
      return false; // Detener la navegación si el usuario es invitado y la ruta requiere registro
    }
    return true; // Permitir la navegación
  };

  useEffect(() => {
    if (userData && userData.data[0].foto) {
      setSelectedImage({
        uri: `${serverAddress}/fotos/${userData.data[0].foto}`,
      });
    } else {
      setSelectedImage(null);
    }
  }, [userData, serverAddress]);

  const userId =
    userData && userData.data[0].id_usuari != null
      ? userData.data[0].id_usuari
      : null;

  const scaleAnim = useRef(new Animated.Value(1)).current;
  const rotateAnim = useRef(new Animated.Value(0)).current;
  const [rotateDirection, setRotateDirection] = useState(1);

  useEffect(() => {
    let interval = null;
    let animationTimeout = null;

    const animateProfileImage = () => {
      Animated.sequence([
        Animated.timing(scaleAnim, {
          toValue: 1.3,
          duration: 150,
          useNativeDriver: true,
          easing: Easing.out(Easing.ease),
        }),
        Animated.timing(scaleAnim, {
          toValue: 1,
          duration: 150,
          useNativeDriver: true,
          easing: Easing.in(Easing.ease),
        }),
        Animated.timing(scaleAnim, {
          toValue: 1.3,
          duration: 150,
          useNativeDriver: true,
          easing: Easing.out(Easing.ease),
        }),
        Animated.timing(scaleAnim, {
          toValue: 1,
          duration: 150,
          useNativeDriver: true,
          easing: Easing.in(Easing.ease),
        }),
        Animated.delay(100),
        Animated.timing(rotateAnim, {
          toValue: 1,
          duration: 300,
          useNativeDriver: true,
          easing: Easing.out(Easing.ease),
        }),
      ]).start(() => {
        rotateAnim.setValue(0);
        setRotateDirection((prev) => prev * -1);
      });
    };

    if (!isProfileFocused) {
      // Esperar 6 segundos antes de la primera ejecución
      animationTimeout = setTimeout(() => {
        animateProfileImage(); // Primera animación después de 6 segundos

        // Configurar repetición cada 24 segundos
        interval = setInterval(() => {
          animateProfileImage();
        }, 24000); // Intervalo de repetición (24 segundos)
      }, 6000); // Tiempo de espera inicial (6 segundos)
    }

    return () => {
      // Limpieza de timeout e intervalo
      if (animationTimeout) clearTimeout(animationTimeout);
      if (interval) clearInterval(interval);
      scaleAnim.setValue(1);
      rotateAnim.setValue(0);
    };
  }, [isProfileFocused]);

  const rotateInterpolate = rotateAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [`0deg`, `${360 * rotateDirection}deg`],
  });

  return (
    <>
      <Tab.Navigator
        screenOptions={({ route }) => ({
          tabBarIcon: ({ focused }) => {
            let iconName;
            switch (route.name) {
              case translations[language].inici:
                iconName = IniciIcon;
                break;
              case translations[language].troc:
                iconName = TrocIcon;
                break;
              case translations[language].buscar:
                iconName = BuscarIcon;
                break;
              case translations[language].missatges:
                iconName = MissatgesIcon;
                break;
              case translations[language].perfil:
                break;
              default:
                iconName = IniciIcon;
            }

            const shouldShowDot =
              route.name === translations[language].missatges &&
              (hasUnseenMessageNotifications || hasUnseenGeneralNotifications);

            const isProfile = route.name === translations[language].perfil;

            return (
              <View
                style={{
                  width: ICON_SIZE,
                  height: ICON_SIZE,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isProfile ? (
                  <Animated.View
                    style={[
                      styles.profileContainer,
                      {
                        transform: [
                          { scale: scaleAnim },
                          { rotate: rotateInterpolate },
                        ],
                        borderWidth: focused ? PROFILE_BORDER_WIDTH : 0,
                        borderColor: focused ? Colors.secondary : "transparent",
                        backgroundColor: selectedImage
                          ? "transparent"
                          : Colors.primary,
                      },
                    ]}
                  >
                    {selectedImage ||
                    (userId != null && profileImages[userId % 10]) ? (
                      <Image
                        source={
                          selectedImage
                            ? selectedImage
                            : profileImages[userId % 10]
                        }
                        style={styles.profileImage}
                        resizeMode="cover"
                      />
                    ) : null}
                  </Animated.View>
                ) : (
                  <Image
                    source={iconName}
                    style={{
                      tintColor: focused ? Colors.primary : Colors.inactive,
                      width:
                        route.name === translations[language].inici
                          ? 29
                          : ICON_SIZE, // Ajusta el tamaño solo para Muro
                      height:
                        route.name === translations[language].inici
                          ? 29
                          : ICON_SIZE,
                    }}
                  />
                )}
                {shouldShowDot && (
                  <View
                    style={{
                      position: "absolute",
                      right: -6,
                      top: -3,
                      backgroundColor: "red",
                      borderRadius: 6,
                      width: 12,
                      height: 12,
                    }}
                  />
                )}
              </View>
            );
          },
          tabBarButton: (props) => (
            <TouchableOpacity
              {...props}
              onPress={(e) => {
                const canNavigate = checkAccess(route.name);
                if (canNavigate && props.onPress) {
                  props.onPress(e);
                }
                if (route.name === translations[language].missatges) {
                  clearMessageNotificationsDot();
                }
                if (route.name === translations[language].perfil) {
                  setIsProfileFocused(true); // Actualiza el estado cuando se enfoca perfil
                } else {
                  setIsProfileFocused(false); // Actualiza el estado cuando se desenfoca perfil
                }
              }}
            />
          ),
          tabBarActiveTintColor: Colors.primary,
          tabBarInactiveTintColor: Colors.inactive,
          tabBarLabelStyle: Fonts.poppinsRegular12,
          headerShown: false,
        })}
      >
        <Tab.Screen
          name={translations[language].inici}
          component={HomeScreen}
        />
        <Tab.Screen name={translations[language].troc} component={TrocScreen} />
        <Tab.Screen
          name={translations[language].buscar}
          component={SearchScreen}
        />
        <Tab.Screen
          name={translations[language].missatges}
          component={MessagesScreen}
        />
        <Tab.Screen
          name={translations[language].perfil}
          component={ProfileScreen}
        />
      </Tab.Navigator>
      {isNeedRegisterVisible && (
        <NeedRegister
          isVisible={isNeedRegisterVisible}
          onClose={() => setIsNeedRegisterVisible(false)}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  profileContainer: {
    borderRadius: PROFILE_BORDER_RADIUS, // 15
    width: PROFILE_CONTAINER_SIZE, // 28
    height: PROFILE_CONTAINER_SIZE, // 28
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.primary, // Fondo azul siempre
  },
  profileImage: {
    width: PROFILE_IMAGE_SIZE, // 26
    height: PROFILE_IMAGE_SIZE, // 26
    borderRadius: PROFILE_IMAGE_RADIUS, // 13
    // No se establece backgroundColor aquí para mantener el fondo azul del contenedor
  },
});
