import React, { useState, useContext, useRef, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
  Modal,
  Animated,
  Platform,
  Alert,
  Linking,
} from "react-native";
import { Fonts, Colors } from "../styles/styles.js";
import { AppContext } from "../../AppContext";
import { SwipeListView } from "react-native-swipe-list-view";
import EditModal from "./EditModal";
import Pro from "./Pro.js";
import ShareModal from "./ShareModal.js";

/** Novedades para mail/push (igual que en FirstOffer) **/
import * as Notifications from "expo-notifications";
import * as Device from "expo-device";
import * as Haptic from "expo-haptics";

const translate = {
  ESP: {
    notificacion: "Notificación",
    pausar: "Pausar",
    editar: "Editar",
    eliminar: "Eliminar",
    reactivar: "Reactivar",
    vendo: "Vendo",
    compro: "Compro",
    alquilo: "Alquilo",
    presto: "Presto",
    regalo: "Regalo",
    prestado: "Prestado",
    regalado: "Regalado",
    servicio: "Servicio",
    limiteAlcanzado:
      "Has alcanzado el límite de ofertas permitidas para tu tipo de cuenta. Puedes pausar, eliminar ofertas o mejorar tu perfil a PRO.",
    tituloLimiteAlcanzado: "Límite Alcanzado",
    cerrar: "Cerrar",
    verPro: "Mirar ventajas PRO",

    // NUEVOS TEXTOS para mail/push
    mailAndPushOffTitle: "⚠️ Correo y notificaciones desactivados",
    mailAndPushOffMessage:
      "Necesitas activar el correo para publicar o reactivar. Además, no tienes notificaciones push. ¿Deseas activar el correo ahora?",
    mailAndPushOffActivate: "Activar ✉️",
    mailAndPushOffCancel: "Cancelar",

    pushOffMailOnTitle: "Activa notificaciones 🔔",
    pushOffMailOnMessage:
      "No recibirás avisos instantáneos si no enciendes las notificaciones push. ¿Deseas hacerlo ahora?",
    pushOffMailOnActivate: "Activar 🔔",
    pushOffMailOnSkip: "Ahora no",

    mailOffPushOnTitle: "¿Activar correo ✉️?",
    mailOffPushOnMessage:
      "Podrás recibir mensajes también en tu bandeja de entrada. ¿Activamos el correo ahora?",
    mailOffPushOnActivate: "Activar ✉️",
    mailOffPushOnSkip: "Ahora no",

    pushPermissionTitle: "Permisos de notificación",
    pushPermissionDenied:
      "Debes habilitar las notificaciones del dispositivo. Te llevaremos a la configuración.",
    pushPermissionError: "Error al solicitar notificaciones push.",
    pushSuccess: "Notificaciones activadas con éxito.",
    pushError: "Error al registrar el token de notificaciones.",
  },
  CAT: {
    notificacion: "Notificació",
    pausar: "Pausar",
    editar: "Editar",
    eliminar: "Eliminar",
    reactivar: "Reactivar",
    vendo: "Venc",
    compro: "Compro",
    alquilo: "Llogo",
    presto: "Deixo",
    regalo: "Regalo",
    prestado: "Prestat",
    regalado: "Regalat",
    servicio: "Servei",
    limiteAlcanzado:
      "Has assolit el límit d'ofertes permeses per al teu tipus de compte. Pots pausar, eliminar ofertes o millorar el teu perfil a PRO.",
    tituloLimiteAlcanzado: "Límit Assolit",
    cerrar: "Tancar",
    verPro: "Veure avantatges PRO",

    // NUEVOS TEXTOS
    mailAndPushOffTitle: "⚠️ Correu i notificacions desactivats",
    mailAndPushOffMessage:
      "Necessites activar el correu per publicar o reactivar. A més, no tens notificacions push. Vols activar el correu ara?",
    mailAndPushOffActivate: "Activar ✉️",
    mailAndPushOffCancel: "Cancel·lar",

    pushOffMailOnTitle: "Activa notificacions 🔔",
    pushOffMailOnMessage:
      "No rebràs avisos instantanis si no encens les notificacions push. Ho vols fer ara?",
    pushOffMailOnActivate: "Activar 🔔",
    pushOffMailOnSkip: "Ara no",

    mailOffPushOnTitle: "Vols activar el correu ✉️?",
    mailOffPushOnMessage:
      "Rebràs missatges també al teu correu electrònic. L'activem ara?",
    mailOffPushOnActivate: "Activar ✉️",
    mailOffPushOnSkip: "Ara no",

    pushPermissionTitle: "Permisos de notificació",
    pushPermissionDenied:
      "Has d'habilitar les notificacions del dispositiu. T'hi portem!",
    pushPermissionError: "Error en sol·licitar les notificacions push.",
    pushSuccess: "Notificacions activades amb èxit.",
    pushError: "Error en registrar el token de notificaciones.",
  },
  ENG: {
    notificacion: "Notification",
    pausar: "Pause",
    editar: "Edit",
    eliminar: "Delete",
    reactivar: "Reactivate",
    vendo: "Sell",
    compro: "Buy",
    alquilo: "Rent",
    presto: "Lend",
    regalo: "Gift",
    prestado: "Lent",
    regalado: "Gifted",
    servicio: "Service",
    limiteAlcanzado:
      "You have reached the limit of offers allowed for your account type. You can pause or delete offers or upgrade your profile to PRO.",
    tituloLimiteAlcanzado: "Limit Reached",
    cerrar: "Close",
    verPro: "View PRO benefits",

    // NEW TEXTS
    mailAndPushOffTitle: "⚠️ Email & notifications disabled",
    mailAndPushOffMessage:
      "You must enable email to post or reactivate. Also, push notifications are off. Would you like to enable email now?",
    mailAndPushOffActivate: "Enable ✉️",
    mailAndPushOffCancel: "Cancel",

    pushOffMailOnTitle: "Enable notifications 🔔",
    pushOffMailOnMessage:
      "You won't receive instant alerts if you don't turn on push notifications. Enable them now?",
    pushOffMailOnActivate: "Enable 🔔",
    pushOffMailOnSkip: "Not now",

    mailOffPushOnTitle: "Enable email ✉️?",
    mailOffPushOnMessage:
      "You can also get messages in your inbox. Enable email now?",
    mailOffPushOnActivate: "Enable ✉️",
    mailOffPushOnSkip: "Not now",

    pushPermissionTitle: "Notification permissions",
    pushPermissionDenied:
      "You need to enable device notifications. We'll take you to settings.",
    pushPermissionError: "Error requesting push notifications.",
    pushSuccess: "Push notifications enabled successfully.",
    pushError: "Error registering the push token.",
  },
};

/* -------------------------------------------------------------------
   Funciones auxiliares para mail/push (mismas que en FirstOffer)
   ------------------------------------------------------------------- */

async function activateMail(serverAddress, token, userData, refreshUserData) {
  try {
    const response = await fetch(`${serverAddress}/api/v1/perfils/actuacions`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        actuacio: "GN",
        notif_email: 1,
        notif_push: userData?.data[0]?.notif_push === 1 ? 1 : 0,
      }),
    });
    if (!response.ok) {
      console.error("Error al activar correo en el servidor");
      return false;
    }
    await refreshUserData();
    return true;
  } catch (error) {
    console.error("Error al activar correo:", error);
    return false;
  }
}

async function enablePushNotifications(language, serverAddress, token) {
  // Vibración háptica (opcional)
  if (Platform.OS !== "web") {
    try {
      await Haptic.impactAsync(Haptic.ImpactFeedbackStyle.Heavy);
    } catch (err) {
      //console.log("Haptic no disponible");
    }
  }

  if (!Device.isDevice) {
    // Emulador / web
    return false;
  }

  const { status: existingStatus } = await Notifications.getPermissionsAsync();
  let finalStatus = existingStatus;

  if (existingStatus !== "granted") {
    const { status } = await Notifications.requestPermissionsAsync();
    finalStatus = status;
  }

  if (finalStatus !== "granted") {
    Alert.alert(
      translate[language].pushPermissionTitle,
      translate[language].pushPermissionDenied,
      [
        {
          text: "OK",
          onPress: () => {
            if (Platform.OS === "ios") {
              Linking.openURL("app-settings:");
            } else {
              Linking.openSettings();
            }
          },
        },
      ]
    );
    return false;
  }

  try {
    const projectId = "097c2eab-348a-4f63-bcf5-de3978115ad3"; 
    const pushToken = (
      await Notifications.getExpoPushTokenAsync({ projectId })
    ).data;

    const response = await fetch(`${serverAddress}/api/v1/perfils/actuacions`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        actuacio: "GTP",
        expoPushToken: pushToken,
      }),
    });

    if (!response.ok) {
      throw new Error(translate[language].pushError);
    }

    Alert.alert("", translate[language].pushSuccess);
    return true;
  } catch (error) {
    Alert.alert(
      "Error",
      translate[language].pushPermissionError + " " + error.message
    );
    return false;
  }
}

/**
 * checkMailPush: Verifica el estado de mail y push antes de reactivar
 * Retorna true si se pueden seguir con la reactivación, false en caso contrario.
 */
async function checkMailPush(
  language,
  userData,
  serverAddress,
  token,
  refreshUserData
) {
  const mailEnabled = userData?.data[0]?.notif_email === 1;

  let pushEnabled = false;
  if (Platform.OS !== "web") {
    const { status } = await Notifications.getPermissionsAsync();
    pushEnabled = status === "granted";
  }

  // WEB => solo mirar mail
  if (Platform.OS === "web") {
    if (!mailEnabled) {
      // No mail => alert modal
      return await new Promise((resolve) => {
        Alert.alert(
          translate[language].mailAndPushOffTitle,
          translate[language].mailAndPushOffMessage,
          [
            {
              text: translate[language].mailAndPushOffActivate,
              onPress: async () => {
                const ok = await activateMail(
                  serverAddress,
                  token,
                  userData,
                  refreshUserData
                );
                resolve(ok);
              },
            },
            {
              text: translate[language].mailAndPushOffCancel,
              onPress: () => resolve(false),
              style: "cancel",
            },
          ]
        );
      });
    }
    // mail está ON => reactivar sin problemas
    return true;
  }

  // iOS/Android => varios escenarios

  // A) mail off, push off
  if (!mailEnabled && !pushEnabled) {
    return await new Promise((resolve) => {
      Alert.alert(
        translate[language].mailAndPushOffTitle,
        translate[language].mailAndPushOffMessage,
        [
          {
            text: translate[language].mailAndPushOffActivate,
            onPress: async () => {
              const ok = await activateMail(
                serverAddress,
                token,
                userData,
                refreshUserData
              );
              resolve(ok);
            },
          },
          {
            text: translate[language].mailAndPushOffCancel,
            onPress: () => resolve(false),
            style: "cancel",
          },
        ]
      );
    });
  }

  // B) mail on, push off
  if (mailEnabled && !pushEnabled) {
    return await new Promise((resolve) => {
      Alert.alert(
        translate[language].pushOffMailOnTitle,
        translate[language].pushOffMailOnMessage,
        [
          {
            text: translate[language].pushOffMailOnActivate,
            onPress: async () => {
              // Activo push => no forzamos a error si falla
              await enablePushNotifications(language, serverAddress, token);
              resolve(true);
            },
          },
          {
            text: translate[language].pushOffMailOnSkip,
            onPress: () => resolve(true), // Permitir seguir
            style: "cancel",
          },
        ]
      );
    });
  }

  // C) mail off, push on
  if (!mailEnabled && pushEnabled) {
    return await new Promise((resolve) => {
      Alert.alert(
        translate[language].mailOffPushOnTitle,
        translate[language].mailOffPushOnMessage,
        [
          {
            text: translate[language].mailOffPushOnActivate,
            onPress: async () => {
              const ok = await activateMail(
                serverAddress,
                token,
                userData,
                refreshUserData
              );
              resolve(ok);
            },
          },
          {
            text: translate[language].mailOffPushOnSkip,
            onPress: () => resolve(true), // seguir sin mail
            style: "cancel",
          },
        ]
      );
    });
  }

  // D) mail y push ON => se deja reactivar
  return true;
}

/* -------------------------------------------------------------------
   LÓGICA DEL COMPONENTE NewOffer
   ------------------------------------------------------------------- */
const getTransactionTypeDescription = (type, aplica_OD, language) => {
  let key = "";
  if (aplica_OD === "O") {
    switch (type) {
      case "V":
        key = "vendo";
        break;
      case "L":
        key = "alquilo";
        break;
      case "P":
        key = "presto";
        break;
      case "G":
        key = "regalo";
        break;
      default:
        key = "servicio";
    }
  } else if (aplica_OD === "D") {
    switch (type) {
      case "V":
        key = "compro";
        break;
      case "L":
        key = "alquilo";
        break;
      case "P":
        key = "prestado";
        break;
      case "G":
        key = "regalado";
        break;
      default:
        key = "servicio";
    }
  }
  // Asegurar que language siempre tiene un valor válido
  const langToUse = translate[language] ? language : "ESP";
  return translate[langToUse][key];
};

const NewOffer = ({ type, setScrollEnabled }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isProVisible, setIsProVisible] = useState(false);
  const [isWebModalVisible, setWebModalVisible] = useState(false);
  const [isNotificationSelected, setIsNotificationSelected] = useState(false);
  const {
    serverAddress,
    token,
    userData,
    language,
    updateUser,
    animationPerformed,
    setAnimationPerformed,
    requestRefresh,
    refreshUserData,
  } = useContext(AppContext);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isImageModalVisible, setImageModalVisible] = useState(false);
  const [selectedImageUri, setSelectedImageUri] = useState(null);
  const [isSwiping, setIsSwiping] = useState(false);
  const animation = useRef(new Animated.Value(0)).current;
  const [offersDemandsList, setOffersDemandsList] = useState([]);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [offerData, setOfferData] = useState(null);
  const previousListLength = useRef(null);
  const previousOffersOrDemandsList = useRef([]);

  // Componente modal para la web (límite de ofertas)
  const WebModal = () => (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isWebModalVisible}
      onRequestClose={closeModals}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <Text style={styles.modalTitle}>
            {translate[language].tituloLimiteAlcanzado}
          </Text>
          <Text style={styles.modalMessage}>
            {translate[language].limiteAlcanzado}
          </Text>
          <View style={styles.buttonContainer}>
            <TouchableOpacity style={styles.button} onPress={closeModals}>
              <Text style={styles.buttonText}>
                {translate[language].cerrar}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.button} onPress={openPro}>
              <Text style={styles.buttonText}>
                {translate[language].verPro}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );

  useEffect(() => {
    const checkNewOffers = async () => {
      const fetchOffersOrDemands = () => {
        return type === "ofrece"
          ? userData?.data[0]?.mevesOfertes?.filter(
              (offer) => offer.situacioOferta !== "B"
            ) || []
          : userData?.data[0]?.mevesDemandes?.filter(
              (demand) => demand.situacioDemanda !== "B"
            ) || [];
      };

      const newOffersOrDemandsList = fetchOffersOrDemands();

      if (
        previousListLength.current !== null &&
        newOffersOrDemandsList.length > previousListLength.current
      ) {
        const previousIds = new Set(
          previousOffersOrDemandsList.current.map(
            (item) => item.id_Oferta || item.id_demanda
          )
        );

        const newItem = newOffersOrDemandsList.find((item) => {
          const id = item.id_Oferta || item.id_demanda;
          return !previousIds.has(id);
        });

        if (newItem) {
          setOfferData(newItem);
          setIsShareModalVisible(true);

          // Actualizar datos
          await refreshUserData();
          requestRefresh();
        }
      }

      previousListLength.current = newOffersOrDemandsList.length;
      previousOffersOrDemandsList.current = newOffersOrDemandsList;
    };

    checkNewOffers();
  }, [userData, type, refreshUserData, requestRefresh]);

  useEffect(() => {
    if (!animationPerformed && offersDemandsList.length === 1) {
      Animated.sequence([
        Animated.timing(animation, {
          toValue: -250,
          duration: 1200,
          useNativeDriver: true,
        }),
        Animated.timing(animation, {
          toValue: 0,
          duration: 1000,
          useNativeDriver: true,
        }),
      ]).start(() => {
        setAnimationPerformed(true);
      });
    }
  }, [offersDemandsList.length, animationPerformed]);

  /* ---------------------------------------------------------
     Comprobación del Límite de Ofertas (ya estaba en tu código)
     --------------------------------------------------------- */
  const checkOfferLimit = (isOffer) => {
    if (!isOffer) {
      // Para demandas no hay límite
      return true;
    }
    const activeOffers = userData?.data[0]?.mevesOfertes?.filter(
      (offer) => offer.situacioOferta === "A"
    ).length;
    const maxAllowedOffers = userData?.data[0]?.maxOfertes_Permesses;

    if (activeOffers >= maxAllowedOffers) {
      Platform.OS === "web" ? setWebModalVisible(true) : showLimitAlert();
      return false;
    }
    return true;
  };

  const showLimitAlert = () => {
    Alert.alert(
      translate[language].tituloLimiteAlcanzado,
      translate[language].limiteAlcanzado,
      [
        { text: translate[language].verPro, onPress: openPro },
        { text: translate[language].cerrar, onPress: closeModals },
      ]
    );
  };

  /* ---------------------------------------------------------
     Manejo de reactivación/pausa/eliminar
     --------------------------------------------------------- */
  // Ajustamos la firma para pasar directamente la info del item
  const modifyOfferOrDemand = async (item, type, action, rowMap) => {
    const isOffer = type === "ofrece";
    const id = item.id_Oferta || item.id_demanda;

    // Al reactivar => checkOfferLimit + checkMailPush
    if (action === "reactivate") {
      if (!checkOfferLimit(isOffer)) {
        return;
      }
      const canProceed = await checkMailPush(
        language,
        userData,
        serverAddress,
        token,
        refreshUserData
      );
      if (!canProceed) {
        return;
      }
    }

    // Cierra la fila por seguridad
    if (rowMap[id]) {
      rowMap[id].closeRow();
    }
    const actuacioType = isOffer ? "SO" : "SD";
    let novaSituacio =
      action === "delete"
        ? "B"
        : action === "pause"
        ? "P"
        : action === "reactivate"
        ? "A"
        : undefined;

    const payloadKey = isOffer ? "id_oferta" : "id_demanda";

    const payload = {
      actuacio: actuacioType,
      [payloadKey]: id.toString(),
      novaSituacio,
    };

    try {
      const response = await fetch(
        `${serverAddress}/api/v1/perfils/actuacions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      const respData = await response.json();

      if (respData.status === "OK") {
        // Cierra toda fila abierta
        if (listViewRef.current) {
          listViewRef.current.closeAllOpenRows();
        }
        const apiUrl = isOffer
          ? `${serverAddress}/api/v1/perfils/mevesofertes`
          : `${serverAddress}/api/v1/perfils/mevesdemandes`;

        fetch(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((r) => r.json())
          .then(async (data) => {
            if (data.status === "OK") {
              const updatedData = filterDeleted({
                ...userData.data[0],
                mevesOfertes: isOffer
                  ? data.data
                  : userData.data[0].mevesOfertes,
                mevesDemandes: !isOffer
                  ? data.data
                  : userData.data[0].mevesDemandes,
              });

              updateUser({ data: [updatedData] });

              // --- Aquí hacemos el REFRESH adicional y el HAPTIC ---
              // 1) Haptic feedback (solo iOS/Android)
              if (Platform.OS !== "web") {
                try {
                  await Haptic.impactAsync(Haptic.ImpactFeedbackStyle.Heavy);
                } catch (err) {
                  // console.log("Haptic no disponible");
                }
              }
              // 2) Refrescamos datos
              await refreshUserData();
              requestRefresh();

              // Si reactivamos y es una OFERTA => mostrar ShareModal
              if (isOffer && action === "reactivate") {
                setOfferData(item);
                setIsShareModalVisible(true);
              }
            } else {
              throw new Error("Error al obtener la lista actualizada");
            }
          })
          .catch((error) => {
            //console.log("Error fetch:", error);
          });
      } else {
        //console.log("Error al modificar oferta/demanda:", respData);
      }
    } catch (error) {
      //console.log("Error en la solicitud fetch:", error);
    }
  };

  function filterDeleted(data) {
    if (!data) return null;
    return {
      ...data,
      mevesOfertes: data.mevesOfertes?.filter(
        (offer) => offer.situacioOferta !== "B"
      ),
      mevesDemandes: data.mevesDemandes?.filter(
        (demand) => demand.situacioDemanda !== "B"
      ),
    };
  }

  const updateOfferOrDemandList = () => {
    const apiUrl =
      type === "ofrece"
        ? `${serverAddress}/api/v1/perfils/mevesofertes`
        : `${serverAddress}/api/v1/perfils/mevesdemandes`;

    fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "OK") {
          const updatedData = filterDeleted({
            ...userData.data[0],
            mevesOfertes:
              type === "ofrece" ? data.data : userData.data[0].mevesOfertes,
            mevesDemandes:
              type !== "ofrece" ? data.data : userData.data[0].mevesDemandes,
          });
          updateUser({ data: [updatedData] });

          // Cerrar todas las filas abiertas del SwipeListView
          if (listViewRef.current) {
            listViewRef.current.closeAllOpenRows();
          }
        } else {
          throw new Error("Error al obtener la lista actualizada");
        }
      })
      .catch((error) => {
        //console.log("Error en la solicitud fetch:", error);
      });
  };

  const openPro = () => {
    setWebModalVisible(false);
    setIsProVisible(true);
  };
  const closeModals = () => {
    setIsProVisible(false);
    setWebModalVisible(false);
  };

  const openModalWithId = (id, type, aplica_OD, fotoOferta, esencia) => {
    setSelectedItem({ id, aplica_OD, fotoOferta, esencia });
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const dataArray =
    type === "ofrece"
      ? userData?.data[0]?.mevesOfertes?.filter(
          (offer) => offer.situacioOferta !== "B"
        ) || []
      : userData?.data[0]?.mevesDemandes?.filter(
          (demand) => demand.situacioDemanda !== "B"
        ) || [];

  const listViewRef = useRef(null);

  const handleDelete = (data, rowMap) => {
    if (listViewRef.current) {
      listViewRef.current.safeCloseOpenRow();
    }
    modifyOfferOrDemand(data.item, type, "delete", rowMap);
  };

  const handleIconPress = () => {
    setIsNotificationSelected(!isNotificationSelected);
  };

  const defaultImageUri = "../img/profiles/default.png";

  const handleImagePress = (imageUri, item) => {
    if (imageUri === defaultImageUri) {
      openModalWithId(
        item.id_Oferta || item.id_demanda,
        type,
        item.aplica_OD,
        item.fotoOferta,
        item.esencia
      );
    } else {
      setSelectedImageUri(imageUri);
      setImageModalVisible(true);
    }
  };

  return (
    <>
      {isShareModalVisible && (
        <ShareModal
          isVisible={isShareModalVisible}
          onClose={() => setIsShareModalVisible(false)}
          type={type}
          offerData={offerData}
        />
      )}

      {/* Modal de imagen */}
      <Modal
        animationType="fade"
        transparent={true}
        visible={isImageModalVisible}
        onRequestClose={() => setImageModalVisible(false)}
      >
        <TouchableOpacity
          style={styles.centeredView}
          activeOpacity={1}
          onPress={() => setImageModalVisible(false)}
        >
          <Image style={styles.modalImage} source={{ uri: selectedImageUri }} />
        </TouchableOpacity>
      </Modal>

      {/* Modal de edición */}
      <Modal
        animationType="slide"
        transparent={true}
        visible={isModalVisible}
        onRequestClose={() => setIsModalVisible(false)}
      >
        <EditModal
          selectedItem={selectedItem}
          closeModal={closeModal}
          userData={userData}
          updateUser={updateUser}
          updateOfferOrDemandList={updateOfferOrDemandList}
        />
      </Modal>

      {/* Modal Web Límite */}
      {Platform.OS === "web" && <WebModal />}

      {/* Modal Pro */}
      <Modal
        animationType="slide"
        transparent={true}
        visible={isProVisible}
        onRequestClose={closeModals}
      >
        <TouchableOpacity
          style={{ flex: 1 }}
          onPress={closeModals}
          activeOpacity={1}
        >
          <Pro closeModal={closeModals} />
        </TouchableOpacity>
      </Modal>

      <SwipeListView
        ref={listViewRef}
        data={dataArray}
        keyExtractor={(item) =>
          item.id_Oferta
            ? item.id_Oferta.toString()
            : item.id_demanda
            ? item.id_demanda.toString()
            : ""
        }
        renderItem={({ item }) => {
          const itemStyle = {
            ...styles.rectangleParent,
            borderColor:
              (type === "ofrece"
                ? item.situacioOferta
                : item.situacioDemanda) === "P"
                ? Colors.grayLight
                : Colors.secondary,
          };

          return (
            <Animated.View style={{ transform: [{ translateX: animation }] }}>
              <TouchableOpacity
                onPress={() => {
                  if (!isSwiping) {
                    openModalWithId(
                      item.id_Oferta || item.id_demanda,
                      type,
                      item.aplica_OD,
                      item.fotoOferta,
                      item.esencia
                    );
                  }
                }}
                style={itemStyle}
                activeOpacity={1}
              >
                <View style={styles.offerContent}>
                  <Text
                    style={[
                      styles.classesDePiano,
                      (type === "ofrece"
                        ? item.situacioOferta
                        : item.situacioDemanda) === "P"
                        ? styles.inactiveStyle
                        : {},
                    ]}
                    numberOfLines={2}
                  >
                    {item.titul}
                  </Text>
                  <Text
                    style={[
                      styles.pucDonarClasses,
                      (type === "ofrece"
                        ? item.situacioOferta
                        : item.situacioDemanda) === "P"
                        ? styles.inactiveStyle
                        : {},
                    ]}
                    numberOfLines={3}
                  >
                    {item.descripcio}
                  </Text>
                </View>
                <Text
                  style={[
                    styles.transactionType,
                    (type === "ofrece"
                      ? item.situacioOferta
                      : item.situacioDemanda) === "P"
                      ? styles.inactiveStyle
                      : {},
                    {
                      top: 10,
                      left: 258,
                      position: "absolute",
                      textAlign: "right",
                      width: 70,
                    },
                  ]}
                  numberOfLines={1}
                >
                  {item.tipoTransac
                    ? getTransactionTypeDescription(
                        item.tipoTransac,
                        item.aplica_OD,
                        language
                      )
                    : ""}
                </Text>
                <Text
                  style={[
                    styles.hora,
                    (type === "ofrece"
                      ? item.situacioOferta
                      : item.situacioDemanda) === "P"
                      ? styles.inactiveStyle
                      : {},
                    {
                      top: 30,
                      left: 258,
                      position: "absolute",
                      textAlign: "right",
                      width: 70,
                    },
                  ]}
                  numberOfLines={1}
                >
                  {item.preu}
                </Text>
                {type === "ofrece" &&
                  getTransactionTypeDescription(
                    item.tipoTransac,
                    item.aplica_OD,
                    language
                  ) !== translate[language]["servicio"] &&
                  (item.fotoOferta ? (
                    <TouchableOpacity
                      onPress={() =>
                        handleImagePress(
                          `${serverAddress}/fotos/${item.fotoOferta}`,
                          item
                        )
                      }
                      style={styles.imageWrapper}
                    >
                      <Image
                        style={[
                          styles.offerImage,
                          item.situacioOferta === "P"
                            ? styles.inactiveStyle
                            : {},
                        ]}
                        source={{
                          uri: `${serverAddress}/fotos/${item.fotoOferta}`,
                        }}
                      />
                    </TouchableOpacity>
                  ) : (
                    <Image
                      style={[
                        styles.imageContainer,
                        item.situacioOferta === "P" ? styles.inactiveStyle : {},
                      ]}
                      source={require("../img/profiles/defaultcam.png")}
                    />
                  ))}
              </TouchableOpacity>
            </Animated.View>
          );
        }}
        renderHiddenItem={(data, rowMap) => (
          <View style={styles.actionContainer}>
            <TouchableOpacity
              style={[
                styles.actionButton,
                {
                  backgroundColor:
                    (type === "ofrece"
                      ? data.item.situacioOferta
                      : data.item.situacioDemanda) === "P"
                      ? "#5C83C2"
                      : "#e9c46a",
                  borderRadius: 10,
                },
              ]}
              onPress={() => {
                // Reactivar o pausar
                if (
                  (type === "ofrece"
                    ? data.item.situacioOferta
                    : data.item.situacioDemanda) === "P"
                ) {
                  modifyOfferOrDemand(data.item, type, "reactivate", rowMap);
                } else {
                  modifyOfferOrDemand(data.item, type, "pause", rowMap);
                }
              }}
            >
              <Text style={styles.actionText}>
                {(type === "ofrece"
                  ? data.item.situacioOferta
                  : data.item.situacioDemanda) === "P"
                  ? translate[language]["reactivar"]
                  : translate[language]["pausar"]}
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={[
                styles.actionButton,
                { backgroundColor: "#61B04F", borderRadius: 10 },
              ]}
              onPress={() =>
                openModalWithId(
                  data.item.id_Oferta || data.item.id_demanda,
                  type,
                  data.item.aplica_OD,
                  data.item.fotoOferta,
                  data.item.esencia
                )
              }
            >
              <Text style={styles.actionText}>
                {translate[language]["editar"]}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                styles.actionButton,
                { backgroundColor: "#D0706C", borderRadius: 10 },
              ]}
              onPress={() => handleDelete(data, rowMap)}
            >
              <Text style={styles.actionText}>
                {translate[language]["eliminar"]}
              </Text>
            </TouchableOpacity>
          </View>
        )}
        disableRightSwipe={true}
        rightOpenValue={-250}
        onSwipeValueChange={({ value }) => {
          setIsSwiping(value !== 0);
          setScrollEnabled(value >= 0);
        }}
        onRowDidOpen={() => {
          setIsSwiping(true);
          setScrollEnabled(true);
        }}
        onRowDidClose={() => {
          setIsSwiping(false);
          setScrollEnabled(true);
        }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalContent: {
    width: 340,
    backgroundColor: "#ffffff",
    borderRadius: 16,
    padding: 20,
    shadowColor: "#000000",
    shadowOpacity: 0.25,
    shadowRadius: 6,
    shadowOffset: { width: 1, height: 1 },
  },
  rectangleParent: {
    width: 340,
    height: 115,
    marginBottom: Platform.OS === "web" ? 9 : 4,
    marginTop: 7,
    paddingVertical: 0,
    borderRadius: 0,
    flexDirection: "row",
    backgroundColor: Colors.white,
    borderTopWidth: 1.4,
    borderBottomWidth: 1.4,
    borderColor: Colors.secondary,
  },
  frameChild: {
    flex: 1,
    borderRadius: 16,
    backgroundColor: Colors.white,
  },
  classesDePiano: {
    ...Fonts.poppinsSemiBold16,
    marginLeft: 8,
    width: "70%",
    marginBottom: 0,
  },
  pucDonarClasses: {
    ...Fonts.poppinsRegular14,
    marginLeft: 8,
    width: "80%",
    height: 59,
    overflow: "hidden",
    marginTop: 0,
  },
  hora: {
    ...Fonts.poppinsMedium14,
    position: "absolute",
    top: 10,
    left: 258,
    width: 70,
    textAlign: "right",
    color: Colors.primary,
  },
  notification21Icon: {
    position: "absolute",
    top: -65,
    left: 289,
    width: 25,
    height: 27,
  },
  notificacin: {
    ...Fonts.poppinsRegular10,
    position: "absolute",
    top: 87,
    left: 272,
  },
  inactiveStyle: {
    opacity: 0.4,
  },
  actionContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: Colors.white,
    width: "73%",
    position: "absolute",
    right: 0,
    height: "100%",
    bottom: 3,
    marginRight: 3,
  },
  actionButton: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    width: 83,
    height: 105,
    marginRight: 0,
  },
  actionText: {
    padding: 10,
    color: "white",
    fontWeight: "bold",
    fontSize: 10,
  },

  offerContent: {
    flex: 1,
    padding: 10,
    justifyContent: "space-between",
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.7)",
  },
  modalImage: {
    width: "80%",
    height: "50%",
    resizeMode: "contain",
  },
  modalView: {
    backgroundColor: Colors.white,
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    width: "80%",
    maxHeight: "80%",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 15,
    color: Colors.primary,
  },
  modalMessage: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 20,
    color: Colors.grayDark,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
  },
  button: {
    borderRadius: 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
    elevation: 2,
    backgroundColor: Colors.primary,
    marginHorizontal: 5,
  },
  buttonText: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  imageContainer: {
    position: "absolute",
    bottom: 5,
    right: 5,
    width: 40,
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
  },
  imageWrapper: {
    position: "absolute",
    bottom: 5,
    right: 5,
    width: 40,
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
  },
  offerImage: {
    width: "100%",
    height: "100%",
    borderRadius: 8,
  },
});

export default NewOffer;
