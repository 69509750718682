import React, { useState, useContext, useEffect } from "react";
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  Platform,
} from "react-native";
import { Colors, Fonts } from "../styles/styles";
import Mensajes from "../components/Mensajes";
import Notifications from "../components/Notifications";
import CardFavorites from "../components/CardFavorites";
import EmptyContent from "../components/EmptyContent";
import { useFocusEffect } from "@react-navigation/native";
import * as Haptics from "expo-haptics";
import { AppContext } from "../../AppContext";
import Loading from "../components/Loading";
import { SocketContext } from "../../SocketContext";

const translate = {
  ESP: {
    mensajes: "Mensajes",
    notificaciones: "Notificaciones",
    favoritos: "Favoritos",
  },
  CAT: {
    mensajes: "Missatges",
    notificaciones: "Notificacions",
    favoritos: "Preferits",
  },
  ENG: {
    mensajes: "Messages",
    notificaciones: "Notifications",
    favoritos: "Favorites",
  },
};

const SelectorMensajesNotificacionesFavoritos = ({
  selectedOption = "mensajes",
}) => {
  const [localSelectedOption, setLocalSelectedOption] = useState(selectedOption);
  const [isFetching, setIsFetching] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [favoritosData, setFavoritosData] = useState([]);
  const { language, token, serverAddress } = useContext(AppContext);
  const {
    hasUnseenMessageNotifications,
    setHasUnseenMessageNotifications,
    hasUnseenGeneralNotifications,
    setHasUnseenGeneralNotifications,
    notifications,
  } = useContext(SocketContext);
  const { clearNotifications } = useContext(SocketContext);

  useFocusEffect(
    React.useCallback(() => {
      if (localSelectedOption === "favoritos") {
        fetchFavoritosData();
      }
    }, [localSelectedOption])
  );

  useFocusEffect(
    React.useCallback(() => {
      if (selectedOption === "mensajes") {
        setHasUnseenMessageNotifications(false); // Marca mensajes como leídos solo al entrar en la pestaña de mensajes
      }
    }, [selectedOption])
  );

  const markNotificationsAsRead = async () => {
    try {
      const response = await fetch(
        `${serverAddress}/api/v1/perfils/actuacions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ actuacio: "BN" }),
        }
      );
      const jsonResponse = await response.json();
      if (jsonResponse.status === "OK") {
        // Aquí puedes realizar acciones adicionales basadas en la respuesta
      } else {
        // Manejo de errores si es necesario
      }
    } catch (error) {
      // Manejo de errores si es necesario
    }
  };

  useEffect(() => {
    if (localSelectedOption === "favoritos") {
      fetchFavoritosData();
    }
  }, [localSelectedOption]);

  useEffect(() => {
    if (selectedOption === "mensajes") {
      setHasUnseenMessageNotifications(false);
    }
  }, []);

  const fetchFavoritosData = async () => {
    setIsFetching(true);
    setShowLoading(false);
    const timer = setTimeout(() => {
      if (isFetching) {
        setShowLoading(true);
      }
    }, 2000); // 2 segundos

    try {
      const response = await fetch(
        `${serverAddress}/api/v1/perfils/meusfavorits`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (data.status === "OK") {
        setFavoritosData(data.data);
      } else {
        setFavoritosData([]);
      }
    } catch (error) {
      // Manejo de errores si es necesario
      setFavoritosData([]);
    } finally {
      setIsFetching(false);
      setShowLoading(false);
      clearTimeout(timer);
    }
  };

  const handleOptionSelect = async (option) => {
    setLocalSelectedOption(option);
    if (option === "mensajes") {
      await clearNotifications(); // Limpiar notificaciones al seleccionar Mensajes
    }

    // Marcar todas las notificaciones como leídas al cambiar entre pestañas
    if (option === "notificaciones") {
      setHasUnseenMessageNotifications(false);
      setHasUnseenGeneralNotifications(false);
      await markNotificationsAsRead();
    } else if (option === "mensajes") {
      setHasUnseenGeneralNotifications(false);
      setHasUnseenMessageNotifications(false);
      await markNotificationsAsRead(); // Marcar notificaciones como leídas
    }

    // Haptics feedback
    if (Platform.OS !== "web") {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    }
  };

  const renderContent = () => {
    switch (localSelectedOption) {
      case "mensajes":
        return <Mensajes />;
      case "notificaciones":
        // Verificar si hay notificaciones para mostrar
        if (notifications && notifications.length > 0) {
          // Si hay notificaciones, mostrarlas
          return <Notifications notifications={notifications} />;
        } else {
          // Si no hay notificaciones, mostrar contenido vacío
          return <EmptyContent contentType="notificaciones" />;
        }
      case "favoritos":
        if (isFetching) {
          if (showLoading) {
            return <Loading />;
          }
          // Mientras se está fetching y no ha pasado 2 segundos, mantener los datos anteriores
          return favoritosData.length > 0 ? (
            favoritosData.map((item, index) => (
              <CardFavorites key={index} item={item} />
            ))
          ) : null; // No mostrar nada hasta que pase el tiempo o se completen los datos
        }
        return favoritosData.length > 0 ? (
          favoritosData.map((item, index) => (
            <CardFavorites key={index} item={item} />
          ))
        ) : (
          <EmptyContent contentType="favoritos" />
        );
      default:
        return null;
    }
  };

  return (
    <ScrollView style={styles.scrollViewStyle}>
      <View style={styles.container}>
        <View style={styles.selector}>
          {Object.keys(translate[language]).map((option, index) => (
            <TouchableOpacity
              key={option}
              style={[
                localSelectedOption === option
                  ? styles.optionContainerSelected
                  : styles.optionContainer,
                index !== 0 && styles.marginLeft,
              ]}
              onPress={() => handleOptionSelect(option)}
            >
              <Text
                style={
                  localSelectedOption === option
                    ? styles.optionSelected
                    : styles.optionInactive
                }
              >
                {translate[language][option]}
                {(option === "notificaciones" &&
                  hasUnseenGeneralNotifications &&
                  localSelectedOption !== "notificaciones") ||
                (option === "mensajes" &&
                  hasUnseenMessageNotifications &&
                  localSelectedOption !== "mensajes") ? (
                  <View style={styles.notificationDot} />
                ) : null}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
        {renderContent()}
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 20,
    alignContent: "center",
    alignItems: "center",
  },
  selector: {
    flexDirection: "row",
    marginBottom: 20,
  },
  optionInactive: {
    fontFamily: Fonts.poppinsRegular,
    fontSize: 16,
    color: Colors.inactive,
    paddingVertical: 10,
  },
  optionSelected: {
    fontFamily: Fonts.poppinsRegular,
    fontSize: 16,
    color: Colors.primary,
    paddingVertical: 10,
  },
  optionContainer: {
    borderBottomWidth: 1,
    borderColor: "transparent",
    paddingBottom: 2,
  },
  optionContainerSelected: {
    borderBottomWidth: 2,
    borderColor: Colors.primary,
    paddingBottom: 0,
  },
  marginLeft: {
    marginLeft: 30,
  },
  notificationDot: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: "red",
    position: "absolute",
    right: -10,
    top: 0,
  },
  scrollViewStyle: {
    flex: 1,
  },
});

export default SelectorMensajesNotificacionesFavoritos;
