import React, { useContext } from 'react';
import { StyleSheet, View, Text, Image, TouchableOpacity, Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Colors, Fonts } from '../styles/styles.js';
import { AppContext } from '../../AppContext';
import profileImages from "../img/profileimage.js";

const translations = {
  ESP: { ofrece: "Ofrece", necesita: "Demanda" },
  CAT: { ofrece: "Ofereix", necesita: "Demanda" },
  ENG: { ofrece: "Offers", necesita: "Needs" },
};

const CardTrocVertical = ({ data, esOferta }) => {
  const navigation = useNavigation();
  const { serverAddress, language } = useContext(AppContext);
  const titulo = data.titul;
  const distanciaFormateada = parseFloat(data.distancia).toFixed(0) + ' Km';
  let imageUrl;
  if (data.foto) {
    imageUrl = { uri: `${serverAddress}/fotos/${data.foto}` };
  } else if (data.id_usuari != null && profileImages[data.id_usuari % 10]) {
    imageUrl = profileImages[data.id_usuari % 10];
  } else {
    imageUrl = require('../img/profiles/default.png'); // Asegúrate de que esta ruta es correcta
  }

  const ofertaNecesitaTexto = esOferta ? translations[language].necesita : translations[language].ofrece;
  const rating = data.Valoracio ? parseFloat(data.Valoracio).toFixed(1) : "-";

  // Función auxiliar para obtener los estilos según el tipo de usuario
  const getCardStyles = (tipoUsuari) => {
    switch(tipoUsuari) {
      case "Premium":
        return {
          borderColor: Colors.secondary,
          backgroundColor: "rgba(255, 223, 158, 0.2)",
          imageBorderStyle: styles.imageBorderPremium,
        };
      case "Colaborador":
        return {
          borderColor: Colors.primary,
          backgroundColor: "rgba(177, 217, 244, 0.2)",
          imageBorderStyle: styles.imageBorderCollaborator,
        };
      default: // Standard
        return {
          borderColor: Colors.grayMedium,
          backgroundColor: Colors.grayLighter,
          imageBorderStyle: styles.imageBorderStandard,
        };
    }
  };

  const cardStyles = getCardStyles(data.tipusUsuari);

  return (
    <TouchableOpacity onPress={() => navigation.navigate('ProfileOther', { userId: data.id_usuari })}>
      <View style={[styles.cardContainer, { borderColor: cardStyles.borderColor, backgroundColor: cardStyles.backgroundColor }]}>
        <Image
          style={[
            styles.cardImage,
            cardStyles.imageBorderStyle,
          ]}
          resizeMode="cover"
          source={imageUrl}
        />

        {data.tipusUsuari === "Premium" && (
          <Text style={styles.proText}>PRO</Text>
        )}

        <Text style={[Fonts.poppinsMedium14, styles.nameText]}>
          {data.nomUsuari + " " + data.cognomUsuari}
        </Text>
        <Text style={[Fonts.poppinsMedium14, styles.trocTypeText]}>
          {ofertaNecesitaTexto}
        </Text>
        <Text style={[Fonts.poppinsRegular12, styles.categoriaText]}>
          {titulo}
        </Text>
        <Text style={[Fonts.poppinsRegular12, styles.kmText]}>
          {distanciaFormateada}
        </Text>
        <View style={styles.valoracionContainer}>
          <Text style={[Fonts.poppinsRegular12, styles.ratingText]}>
            {rating}
          </Text>
          <Image
            style={styles.iconText}
            resizeMode="cover"
            source={require('../img/icons/puntuation.png')}
          />
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    width: 143,
    height: 227,
    marginRight: 8,
    marginBottom: 15,
    marginTop: 8,
    marginLeft: 12,
    borderWidth: 1,
    borderRadius: 16, // Asegúrate de que coincida con el borderRadius del cardBackground
    // backgroundColor y borderColor se manejarán de forma dinámica
  },
  cardImage: {
    position: "absolute",
    left: 27,
    top: 9,
    borderRadius: 69,
    width: 89,
    height: 83,
    backgroundColor: Colors.primary,
    borderWidth: 2, // Grosor del borde de la foto
    // borderColor se manejará de forma dinámica
  },
  proText: {
    position: "absolute",
    top: 4,
    left: 4,
    color: Colors.secondary,
    ...Fonts.poppinsSemiBold14,
  },
  nameText: {
    left: 11,
    top: 97,
    width: 132,
    marginBottom: -25,
    lineHeight: 16 * 1.10,
  },
  trocTypeText: {
    ...Fonts.poppinsMedium14,
    top: 128,
    left: 11,
    position: 'absolute',
  },
  categoriaText: {
    ...Fonts.poppinsRegular12,
    top: 148,
    left: 11,
    position: 'absolute',
    width: 132,
  },
  kmText: {
    position: "absolute",
    top: 210,
    left: 100,
    textAlign: "left",
  },
  valoracionContainer: {
    position: "absolute",
    left: 8,
    bottom: 6,
    flexDirection: "row",
    alignItems: "center",
  },
  ratingText: {
    marginRight: 8,
    marginBottom: -6,
    marginLeft: 2,
  },
  iconText: {
    width: 16,
    height: 16,
    marginBottom: -2,
  },
  premiumBorder: {
    borderColor: Colors.secondary,
  },
  standardBorder: {
    borderColor: Colors.grayMediumDark, // Asegúrate de que sea Colors.grayMediumDark
  },
  imageBorderPremium: {
    borderColor: Colors.secondary, // Borde Premium para la foto
  },
  imageBorderCollaborator: {
    borderColor: Colors.primary, // Borde Colaborador para la foto
  },
  imageBorderStandard: {
    borderColor: Colors.grayMediumDark, // Borde estándar para la foto
  },
});

export default CardTrocVertical;
