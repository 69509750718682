import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  Platform,
  TouchableOpacity,
  Image,
  Alert,
  Linking,
  Dimensions,
  Pressable,
} from "react-native";

// ***** ALMACENAMIENTO SEGURO PARA SABER SI MOSTRAMOS EL TUTORIAL *****
import * as SecureStore from "expo-secure-store";

// ***** COMPONENTES TÚYOS (AJUSTA LAS RUTAS SI ES PRECISO) *****
import CardTrocVertical from "../components/CardTrocVertical";
import { Colors, Fonts } from "../styles/styles";
import { AppContext } from "../../AppContext";
import InfoTrocs from "../components/InfoTrocs";
import Loading from "../components/Loading";
import NoDataTrocVertical from "../components/NoDataTrocVertical";

// ***** PARA MANEJAR EVENTOS CUANDO VUELVES A LA PANTALLA *****
import { useFocusEffect } from "@react-navigation/native";

// ***** NOTIFICACIONES *****
import * as Notifications from "expo-notifications";
import * as Device from "expo-device";

// ***** TRADUCCIONES *****
const translations = {
  ESP: {
    ofertas: "Según tus ofertas",
    demandas: "Según tus demandas",
    explanation:
      "Esta pantalla te muestra a los vecinos con los que haces **Troc** (Match) cuando sois **complementarios** con lo que **ofreces** y/o **demandas**.\n\nLos trocs dobles son aquellos donde hay coincidencias en ambos sentidos: ofreces lo que necesitan y demandas lo que ofrecen.\n\nSi no hay coincidencias, sigue publicando ofertas y demandas, y pronto **serás notificado**.",
    close: "Cerrar",
    enableNotifications: "Activar notificaciones",
    pushPermissionTitle: "Falta un paso",
    pushPermissionDenied:
      "Debes habilitar los permisos de notificación del dispositivo, ¡te llevamos allí!",
    pushTokenError: "Error al activar las notificaciones push.",
    webPushMessage: "Para activar notificaciones push debes descargar la App.",
    pushios:
      "Para activar las Notificaciones descarga nuestra aplicación desde la App Store",
    pushandroid:
      "Para activar las Notificaciones descarga nuestra aplicación desde Google Play",
    exito: "Éxito",
    successMessage: "Ya tienes las notificaciones perfectamente configuradas.",
    coincidencias: " Coincidentes",

    // Textos para el tutorial
    tutorialStep0Title: "¿Qué son los Trocs? 🧩",
    tutorialStep0Desc:
      "Trocalia se basa en las **ofertas y demandas** que publican tus vecinos. Cuando haces **match** con un vecino compatible que ofrece lo que demandas o demanda lo que tu ofreces, aparece en esta pantalla.",
    tutorialStep1Title: "SúperTrocs 🎉",
    tutorialStep1Desc:
      "En esta parte verás los SúperTrocs, usuarios compatibles en **ambos sentidos**. Tus ofertas encajan con sus demandas y ofrecen algo que tu necesitas.",
    tutorialStep2Title: "Trocs parciales 🤝",
    tutorialStep2Desc:
      "Aquí verás los **Trocs parciales**. Usuarios cuyas ofertas encajan con tus demandas.",
    tutorialStep3Title: "Trocs parciales 🤝",
    tutorialStep3Desc:
      "En esta franja verás a usuarios que ofrecen lo que tu necesitas. ¡Sigue publicando ofertas y demandas para encontrar más TROCS 🚀!",
  },
  CAT: {
    ofertas: "Segons les teves ofertes",
    demandas: "Segons les teves demandes",
    explanation:
      "Aquesta pantalla us mostra els veïns amb qui fas **Troc** (Match) quan sou **complementaris** amb el que **oferiu** i/o **demaneu**.\n\nEls trocs dobles són aquells on hi ha coincidències en ambdós sentits: oferiu el que necessiten i demaneu el que ofereixen.\n\nSi no hi ha coincidències, continua publicant ofertes i demandes i aviat **seràs notificat**.",
    close: "Tancar",
    enableNotifications: "Activar notificacions",
    pushPermissionTitle: "Falta un pas",
    pushPermissionDenied:
      "Has d'habilitar els permisos de notificació del dispositiu, t'hi portem!",
    pushTokenError: "Error al activar les notificacions push.",
    webPushMessage: "Per activar notificacions push has de descarregar l'App.",
    pushios:
      "Per activar les Notificacions descarrega la nostra aplicació des de l'App Store",
    pushandroid:
      "Per activar les Notificacions descarrega la nostra aplicació des de Google Play",
    exito: "Èxit",
    successMessage: "Ja tens les notificacions perfectament configurades.",
    coincidencias: " Coincidents",

    // Textos tutorial
    tutorialStep0Title: "Què són els Trocs? 🧩",
    tutorialStep0Desc:
      "Trocalia es basa en les **ofertes i demandes** que publiquen els teus veïns. Quan fas **match** amb un veí compatible que ofereix el que demanes o demana el que tu ofereixes, apareix en aquesta pantalla.",
    tutorialStep1Title: "SúperTrocs 🎉",
    tutorialStep1Desc:
      "En aquesta part veuràs els SúperTrocs, usuaris compatibles en **ambdós sentits**. Les teves ofertes encaixen amb les seves demandes i ofereixen alguna cosa que tu necessites.",
    tutorialStep2Title: "Trocs parcials 🤝",
    tutorialStep2Desc:
      "Aquí veuràs els **Trocs parcials**. Usuaris les ofertes dels quals encaixen amb les teves demandes.",
    tutorialStep3Title: "Trocs parcials 🤝",
    tutorialStep3Desc:
      "En aquesta franja veuràs a usuaris que ofereixen el que tu necessites. Continua publicant ofertes i demandes per trobar més TROCS 🚀!",
  },
  ENG: {
    ofertas: "According to your offers",
    demandas: "According to your demands",
    explanation:
      "This screen shows you the neighbors with whom you make a **Troc** (Match) when you are **complementary** with what you **offer** and/or **demand**.\n\nDouble trocs are those where there are matches in both directions: you offer what they need and demand what they offer.\n\nIf there are no matches, keep posting offers and demands, and soon you will be **notified**.",
    close: "Close",
    enableNotifications: "Enable notifications",
    pushPermissionTitle: "One more step",
    pushPermissionDenied:
      "You need to enable the device's notification permissions, let us take you there!",
    pushTokenError: "Error enabling push notifications.",
    webPushMessage: "To enable push notifications you must download the App.",
    pushios: "To enable Notifications download our app from the App Store",
    pushandroid: "To enable Notifications download our app from Google Play",
    exito: "Success",
    successMessage: "You have successfully configured notifications.",
    coincidencias: " Matching",

    // Tutorial texts
    tutorialStep0Title: "What are Trocs? 🧩",
    tutorialStep0Desc:
      "Trocalia is based on the **offers and demands** that your neighbors publish. When you **match** with a compatible neighbor who offers what you demand or demands what you offer, they appear on this screen.",
    tutorialStep1Title: "SuperTrocs 🎉",
    tutorialStep1Desc:
      "In this part you will see the SuperTrocs, compatible users in **both directions**. Your offers match their demands and they offer something you need.",
    tutorialStep2Title: "Partial Trocs 🤝",
    tutorialStep2Desc:
      "Here you will see the **partial Trocs**. Users whose offers match your demands.",
    tutorialStep3Title: "Partial Trocs 🤝",
    tutorialStep3Desc:
      "In this strip you will see users who offer what you need. Keep posting offers and demands to find more TROCS 🚀!",
  },
};

// ***** FUNCIONES AUXILIARES PARA EL ALMACENAMIENTO *****
const getTutorialVisto = async () => {
  try {
    if (Platform.OS === "web") {
      // localStorage es síncrono, lo envolvemos en una Promise para mantener la interfaz async
      return new Promise((resolve) => {
        const valor = localStorage.getItem("tutorialTrocsVisto");
        resolve(valor);
      });
    } else {
      return await SecureStore.getItemAsync("tutorialTrocsVisto");
    }
  } catch (error) {
    //console.log("Error al obtener el tutorialVisto:", error);
    return null;
  }
};

const setTutorialVisto = async (value) => {
  try {
    if (Platform.OS === "web") {
      localStorage.setItem("tutorialTrocsVisto", value);
      return Promise.resolve();
    } else {
      return await SecureStore.setItemAsync("tutorialTrocsVisto", value);
    }
  } catch (error) {
    //console.log("Error al guardar el tutorialVisto:", error);
    return Promise.reject(error);
  }
};

// ***** COMPONENTE PRINCIPAL TROC SCREEN *****
const TrocScreen = () => {
  const { serverAddress, token, language } = useContext(AppContext);

  // ***** ESTADOS PARA LISTADOS *****
  const [ofertes, setOfertes] = useState([]);
  const [demandes, setDemandes] = useState([]);
  const [supertrocs, setSupertrocs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // ***** TUTORIAL STEP *****
  // 0 => no se muestra
  // 1 => paso central
  // 2 => franja (ofertas)
  // 3 => franja (desde ofertas hasta demandas)
  // 4 => franja (desde demandas hasta final)
  // 5 => tutorial terminado (no se muestra)
  const [tutorialStep, setTutorialStep] = useState(0);

  // Estos estados guardarán la posición de los textos "Ofertas" y "Demandas"
  const [ofertasLayout, setOfertasLayout] = useState(null);
  const [demandasLayout, setDemandasLayout] = useState(null);

  // ***** EFECTO PARA CARGAR SI YA MOSTRAMOS EL TUTORIAL ANTES *****
  useEffect(() => {
    (async () => {
      try {
        const valor = await getTutorialVisto();
        if (!valor) {
          // No existe => primera vez => arrancamos
          setTutorialStep(1);
        }
      } catch (error) {
        //console.log("Error al cargar el tutorial:", error);
      }
    })();
  }, []);

  // ***** AL PULSAR LA PANTALLA EN MODO TUTORIAL *****
  const onPressHighlight = async () => {
    if (tutorialStep === 4) {
      // Último paso => guardamos y salimos
      try {
        await setTutorialVisto("true");
        setTutorialStep(5);
      } catch (error) {
        Alert.alert("Error", "No se pudo guardar el estado del tutorial.");
      }
    } else {
      setTutorialStep((prev) => prev + 1);
    }
  };

  // ***** FETCH DE DATOS *****
  const fetchData = async () => {
    if (!token) {
      setIsLoading(false);
      return;
    }
    try {
      const headers = {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      };

      const resOfertes = await fetch(
        `${serverAddress}/api/v1/perfils/meustrocs/ofertes`,
        headers
      );
      if (!resOfertes.ok) throw new Error("Error en la respuesta de la API");
      const dataOfertes = await resOfertes.json();

      const resDemandes = await fetch(
        `${serverAddress}/api/v1/perfils/meustrocs/demandes`,
        headers
      );
      if (!resDemandes.ok) throw new Error("Error en la respuesta de la API");
      const dataDemandes = await resDemandes.json();

      const resSupertrocs = await fetch(
        `${serverAddress}/api/v1/perfils/meustrocs/supertrocs?offset=0`,
        headers
      );
      if (!resSupertrocs.ok)
        throw new Error("Error en la respuesta de la API para Supertrocs");
      const dataSupertrocs = await resSupertrocs.json();

      if (
        dataSupertrocs.status === "OK" &&
        Array.isArray(dataSupertrocs.data)
      ) {
        setSupertrocs(dataSupertrocs.data);
      } else {
        setSupertrocs([]);
      }

      if (dataOfertes.status === "OK" && Array.isArray(dataOfertes.data)) {
        setOfertes(dataOfertes.data);
      } else {
        setOfertes([]);
      }

      if (dataDemandes.status === "OK" && Array.isArray(dataDemandes.data)) {
        setDemandes(dataDemandes.data);
      } else {
        setDemandes([]);
      }
    } catch (error) {
      //console.log("Error fetching data: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  // ***** REVISAR PERMISOS DE NOTIFICACIONES *****
  const [showEnableNotificationsButton, setShowEnableNotificationsButton] =
    useState(false);

  const checkPushNotificationPermissions = async () => {
    if (!Device.isDevice) {
      // Si no es un dispositivo físico
      setShowEnableNotificationsButton(true);
      return;
    }

    const { status } = await Notifications.getPermissionsAsync();
    if (status !== "granted") {
      setShowEnableNotificationsButton(true);
    } else {
      setShowEnableNotificationsButton(false);
    }
  };

  // ***** REGISTRAR NOTIFICACIONES PUSH *****
  const registerForPushNotificationsAsync = async () => {
    if (!Device.isDevice) {
      Alert.alert(
        translations[language].pushPermissionTitle,
        translations[language].webPushMessage
      );
      return;
    }
    if (Platform.OS === "web") {
      Alert.alert(
        translations[language].pushPermissionTitle,
        translations[language].webPushMessage
      );
      return;
    }
    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;

    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }

    if (finalStatus !== "granted") {
      Alert.alert(
        translations[language].pushPermissionTitle,
        translations[language].pushPermissionDenied,
        [{ text: "OK", onPress: () => openSettings() }]
      );
      return;
    }

    try {
      const projectId = "097c2eab-348a-4f63-bcf5-de3978115ad3";
      const pushToken = (
        await Notifications.getExpoPushTokenAsync({ projectId })
      ).data;
      sendPushTokenToServer(pushToken);
    } catch (error) {
      Alert.alert(
        "Error",
        translations[language].pushTokenError + " " + error.message
      );
    }
  };

  // ***** ENVIAR EL TOKEN AL SERVIDOR *****
  const sendPushTokenToServer = async (pushToken) => {
    if (!token) {
      return;
    }
    const url = `${serverAddress}/api/v1/perfils/actuacions`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          actuacio: "GTP",
          expoPushToken: pushToken,
        }),
      });

      if (response.ok) {
        Alert.alert(
          translations[language].exito,
          translations[language].successMessage
        );
      } else {
        throw new Error(translations[language].pushTokenError);
      }
    } catch (error) {
      Alert.alert(
        "Error",
        translations[language].pushTokenError + " " + error.message
      );
    }
  };

  // ***** ABRIR SETTINGS *****
  const openSettings = () => {
    if (Platform.OS === "ios") {
      Linking.openURL("app-settings:");
    } else {
      Linking.openSettings();
    }
  };

  // ***** CARGAR DATOS CUANDO ENTRA A ESTA PANTALLA *****
  useFocusEffect(
    useCallback(() => {
      fetchData();
      checkPushNotificationPermissions();
    }, [])
  );

  if (isLoading) {
    return <Loading />;
  }

  const showNoDataForOfertas = ofertes.length === 0;
  const showNoDataForDemandes = demandes.length === 0;

  return (
    <View style={styles.containerWrapper}>
      <ScrollView style={styles.scrollContainer}>
        <View style={styles.container}>
          {/* CABECERA */}
          <View style={styles.titleContainer}>
            <Text style={styles.title}>
              <Text style={styles.trocText}>Trocs</Text>
              <Text style={styles.superText}>
                {translations[language].coincidencias}
              </Text>
            </Text>

            {/* Icono de información */}
            <TouchableOpacity onPress={() => setTutorialStep(1)}>
              <Image
                source={require("../img/icons/info.png")}
                style={styles.infoIcon}
              />
            </TouchableOpacity>
          </View>

          {/* SuperTrocs */}
          <InfoTrocs supertrocs={supertrocs} trocs={[]} language={language} />

          {/* Ofertas */}
          <Text
            style={styles.sectionTitle}
            onLayout={(e) => setOfertasLayout(e.nativeEvent.layout)}
          >
            {translations[language].ofertas}
          </Text>
          {showNoDataForOfertas ? (
            <NoDataTrocVertical />
          ) : (
            <ScrollView horizontal showsHorizontalScrollIndicator={false}>
              {ofertes.map((oferta, index) => (
                <CardTrocVertical
                  key={index}
                  data={oferta}
                  esOferta={true}
                  language={language}
                />
              ))}
            </ScrollView>
          )}

          {/* Demandas */}
          <Text
            style={styles.sectionTitle}
            onLayout={(e) => setDemandasLayout(e.nativeEvent.layout)}
          >
            {translations[language].demandas}
          </Text>
          {showNoDataForDemandes ? (
            <NoDataTrocVertical tipo="demanda" />
          ) : (
            <ScrollView horizontal showsHorizontalScrollIndicator={false}>
              {demandes.map((demanda, index) => (
                <CardTrocVertical
                  key={index}
                  data={demanda}
                  esOferta={false}
                  language={language}
                />
              ))}
            </ScrollView>
          )}
        </View>
      </ScrollView>

      {/* ***** TUTORIAL OVERLAY ***** */}
      {tutorialStep >= 1 && tutorialStep <= 4 && (
        <TutorialOverlay
          step={tutorialStep}
          language={language}
          onPressHighlight={onPressHighlight}
          ofertasLayout={ofertasLayout}
          demandasLayout={demandasLayout}
        />
      )}
    </View>
  );
};

// ***** FUNCION AUXILIAR PARA FORMATEAR TEXTO CON NEGRITA *****
const renderFormattedText = (text) => {
  const parts = text.split("**");
  return parts.map((part, index) => {
    if (index % 2 === 1) {
      return (
        <Text key={index} style={styles.boldText}>
          {part}
        </Text>
      );
    } else {
      return <Text key={index}>{part}</Text>;
    }
  });
};

// ***** COMPONENTE PARA LA CAPA DE TUTORIAL *****
const TutorialOverlay = ({
  step,
  language,
  onPressHighlight,
  ofertasLayout,
  demandasLayout,
}) => {
  const screenWidth = Dimensions.get("window").width;
  const screenHeight = Dimensions.get("window").height;

  // Configuración de los 4 pasos de tutorial
  const stepsConfig = [
    {
      id: 1,
      type: "central",
      title: translations[language].tutorialStep0Title,
      desc: translations[language].tutorialStep0Desc,
    },
    {
      id: 2,
      type: "franja",
      title: translations[language].tutorialStep1Title,
      desc: translations[language].tutorialStep1Desc,
      arrowDirection: "up", // Flecha apuntando hacia arriba
    },
    {
      id: 3,
      type: "franja",
      title: translations[language].tutorialStep2Title,
      desc: translations[language].tutorialStep2Desc,
      arrowDirection: "up", // Flecha apuntando hacia arriba
    },
    {
      id: 4,
      type: "franja",
      title: translations[language].tutorialStep3Title,
      desc: translations[language].tutorialStep3Desc,
      arrowDirection: "down", // Flecha apuntando hacia abajo
    },
  ];

  const currentStep = stepsConfig.find((s) => s.id === step);
  if (!currentStep) return null;

  const { title, desc, type, arrowDirection } = currentStep;

  // Función para renderizar la flecha, siempre a unos 20px de la franja
  const renderArrow = (direction) => {
    return (
      <View
        style={[
          styles.arrow,
          direction === "down" ? styles.arrowDown : styles.arrowUp,
        ]}
      />
    );
  };

  // Calcula la franja (highlightTop, highlightBottom) según el step
  let highlightTop = 0;
  let highlightBottom = 0;

  if (type === "franja" && ofertasLayout && demandasLayout) {
    if (step === 2) {
      // Hacer que el highlight empiece desde arriba del todo hasta "Segons les teves ofertes"
      highlightTop = 0; // Desde el inicio de la pantalla
      highlightBottom = ofertasLayout.y;
    } else if (step === 3) {
      // Step 3: Desde justo antes de "Segons les teves ofertes" hasta justo antes de "Segons les teves demandes"
      highlightTop = ofertasLayout.y; // Empieza justo encima del texto
      highlightBottom = demandasLayout.y;
    } else if (step === 4) {
      // Desde encima de "demandas" hasta el final de la pantalla
      highlightTop = demandasLayout.y;
      highlightBottom = screenHeight;
    }
  }

  if (type === "central") {
    // Modo "central" (primer paso)
    return (
      <View style={styles.overlayFull} pointerEvents="box-none">
        <Pressable onPress={onPressHighlight} style={styles.pressableFull}>
          <View style={styles.overlayBackground} />
          <View style={styles.tutorialWindowCentral}>
            <Text style={styles.tutorialTitle}>{title}</Text>
            <Text style={styles.tutorialDesc}>{renderFormattedText(desc)}</Text>
          </View>
          {renderArrow("down")}
        </Pressable>
      </View>
    );
  } else if (type === "franja") {
    // Modo "franja": oscurecer arriba y abajo de la franja, y dejar la franja "visible"
    // 20px de separación para la flecha
    const sideMargin = 3; // margen lateral
    const arrowOffset = 20;

    // Calculamos la posición de la ventana y la flecha según arrowDirection
    let windowStyle = {};
    let arrowPosition = {};

    if (arrowDirection === "up") {
      // Flecha hacia arriba => ventana debajo de la franja
      windowStyle = {
        top: highlightBottom + arrowOffset + 20, // un poco más para despegar la ventana
      };
      arrowPosition = {
        top: highlightBottom + arrowOffset,
      };
    } else {
      // Flecha hacia abajo => ventana encima de la franja
      windowStyle = {
        top: highlightTop - arrowOffset - 120,
      };
      arrowPosition = {
        top: highlightTop - arrowOffset - 30,
      };
    }

    if (step === 4) {
      windowStyle.top = highlightTop - 180;
      arrowPosition.top = highlightTop - 25;
    }

    return (
      <View style={styles.overlayFull} pointerEvents="box-none">
        <Pressable onPress={onPressHighlight} style={styles.pressableFull}>
          {/* Parte superior oscura */}
          <View
            style={[
              styles.overlayBlack,
              { height: highlightTop, top: 0, left: 0 },
            ]}
          />
          {/* Franja destacada con márgenes laterales de 3px */}
          <View
            style={{
              position: "absolute",
              top: highlightTop,
              left: sideMargin,
              width: screenWidth - sideMargin * 2,
              height: highlightBottom - highlightTop,
            }}
          />
          {/* Parte inferior oscura */}
          <View
            style={[
              styles.overlayBlack,
              {
                height: screenHeight - highlightBottom,
                top: highlightBottom,
                left: 0,
              },
            ]}
          />

          {/* Ventana de tutorial */}
          <View style={[styles.tutorialWindow, windowStyle]}>
            <Text style={styles.tutorialTitle}>{title}</Text>
            <Text style={styles.tutorialDesc}>{renderFormattedText(desc)}</Text>
          </View>

          {/* Flecha apuntando a la franja */}
          <View style={[styles.arrowContainer, arrowPosition]}>
            {renderArrow(arrowDirection)}
          </View>
        </Pressable>
      </View>
    );
  }
  return null;
};

// ***** ESTILOS *****
const styles = StyleSheet.create({
  containerWrapper: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  scrollContainer: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  container: {
    flex: 1,
    backgroundColor: Colors.white,
    paddingHorizontal: 0,
    paddingTop: Platform.OS === "android" ? 20 : 28,
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 16,
    marginTop: 40,
    marginBottom: 6,
  },
  title: {
    ...Fonts.poppinsSemiBold24,
  },
  trocText: {
    color: Colors.primary,
  },
  superText: {
    color: Colors.grayDark,
  },
  sectionTitle: {
    ...Fonts.poppinsMedium18,
    marginTop: 24,
    marginBottom: 10,
    marginLeft: 16,
    color: Colors.grayDark,
  },

  // Overlay total
  overlayFull: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,
  },
  pressableFull: {
    flex: 1,
  },
  overlayBackground: {
    flex: 1,
    backgroundColor: "rgba(0,0,0,0.7)",
  },
  overlayBlack: {
    position: "absolute",
    backgroundColor: "rgba(0,0,0,0.7)",
    width: "100%",
  },

  // Ventana de tutorial central
  tutorialWindowCentral: {
    position: "absolute",
    top: "40%",
    left: "10%",
    right: "10%",
    backgroundColor: "#FFFFFF",
    padding: 20,
    borderRadius: 15,
    shadowColor: "#000",
    shadowOpacity: 0.3,
    shadowRadius: 10,
    elevation: 5,
  },

  // Ventana de tutorial para franjas
  tutorialWindow: {
    position: "absolute",
    left: 20, // Margen lateral de 20px
    right: 20, // Margen lateral de 20px
    backgroundColor: "#FFFFFF",
    padding: 20,
    borderRadius: 15,
    shadowColor: "#000",
    shadowOpacity: 0.3,
    shadowRadius: 10,
    elevation: 5,
  },
  tutorialTitle: {
    fontSize: 20,
    fontWeight: "bold",
    color: Colors.primary,
    marginBottom: 10,
    textAlign: "center",
  },
  tutorialDesc: {
    fontSize: 16,
    color: Colors.grayDark,
    textAlign: "center",
    flexWrap: "wrap",
  },
  boldText: {
    fontWeight: "bold",
  },

  // Flecha
  arrow: {
    width: 0,
    height: 0,
    borderLeftWidth: 10,
    borderRightWidth: 10,
    borderTopWidth: 0,
    borderBottomWidth: 20,
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
    backgroundColor: "transparent",
  },
  arrowDown: {
    borderBottomColor: Colors.white,
    borderBottomWidth: 20,
  },
  arrowUp: {
    borderTopWidth: 20,
    borderBottomWidth: 0,
    borderTopColor: Colors.white,
    borderLeftWidth: 10,
    borderRightWidth: 10,
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
  },
  arrowContainer: {
    position: "absolute",
    left: "50%",
    transform: [{ translateX: -10 }],
  },
  infoIcon: {
    width: 20, // Ajusta según el tamaño de tu icono
    height: 20, // Ajusta según el tamaño de tu icono
    marginLeft: 8, // Espacio entre el título y el icono
  },
});

export default TrocScreen;
