import React, { useState, useContext, useEffect } from "react";
import {
  View,
  Modal,
  TouchableOpacity,
  TouchableWithoutFeedback,
  FlatList,
  Platform,
  Alert,
  StyleSheet,
  Text,
} from "react-native";
import { Ionicons } from "@expo/vector-icons";

import ProfileInfo from "../components/ProfileInfo";
import SelectorPresentation from "../selectables/SelectorPresentation";
import FirstOffer from "../components/FirstOffer";
import NewOffer from "../components/NewOffer";
import ProfileOptions from "../components/ProfileOptions";
import OfferModal from "../components/OfferModal";
import CardValoracion from "../components/CardValoracion";
import NotRating from "../components/NotRating";

import { AppContext } from "../../AppContext";
import { Colors, Fonts } from "../styles/styles.js";

const moreImage = require("../img/icons/more.png");
const more2Image = require("../img/icons/more2.png");

const translations = {
  ESP: {
    alertTitle: "Oh, vaya...",
    alertMessage:
      "En estos momentos no se pueden cargar los ajustes. Prueba de nuevo más tarde.",
    noOffers: "Todavía no has publicado ninguna oferta.",
    noDemands: "Todavía no has publicado ninguna demanda.",
    noRatings: "Todavía no has recibido ninguna valoración.",
    publishHint: "Pulsa sobre el rectángulo superior para publicar☝️",
    okButton: "Aceptar",
    continueParticipating: "¡Sigue participando para recibir feedback!",
  },
  CAT: {
    alertTitle: "Oh, caram...",
    alertMessage:
      "En aquests moments no es poden carregar els ajustos. Torna-ho a provar més tard.",
    noOffers: "Encara no has publicat cap oferta.",
    noDemands: "Encara no has publicat cap demanda.",
    noRatings: "Encara no has rebut cap valoració.",
    publishHint: "Prem el rectangle superior per publicar☝️",
    okButton: "D'acord",
    continueParticipating: "¡Segueix participant per rebre feedback!",
  },
  ENG: {
    alertTitle: "Oops...",
    alertMessage: "Settings can't be loaded right now. Please try again later.",
    noOffers: "You haven't published any offers yet.",
    noDemands: "You haven't published any requests yet.",
    noRatings: "You haven't received any reviews yet.",
    publishHint: "Tap the top rectangle to publish☝️",
    okButton: "OK",
    continueParticipating: "Keep participating to receive feedback!",
  },
};

const ProfileScreen = ({ route }) => {
  const { userData, token, serverAddress, language } = useContext(AppContext);

  const [selectedOption, setSelectedOption] = useState("ofrece");
  const [showOptions, setShowOptions] = useState(false);
  const [isProfileOptionsVisible, setIsProfileOptionsVisible] = useState(false);
  const [showOfferModal, setShowOfferModal] = useState(false);
  const [isScrollEnabled, setScrollEnabled] = useState(true);
  const { openLocationModal } = route.params || {};
  const [icon, setIcon] = useState(moreImage);
  const [valoraciones, setValoraciones] = useState([]);

  // Filtramos las ofertas/demandas activas
  const mevesOfertes =
    userData?.data[0]?.mevesOfertes?.filter((o) => o.situacioOferta !== "B") ||
    [];
  const mevesDemandes =
    userData?.data[0]?.mevesDemandes?.filter((d) => d.situacioDemanda !== "B") ||
    [];

  const t = translations[language] || translations.ESP;

  useEffect(() => {
    if (showOptions) {
      const timer = setTimeout(() => {
        if (!isProfileOptionsVisible) {
          Alert.alert(
            t.alertTitle,
            t.alertMessage,
            [{ text: t.okButton }],
            {
              cancelable: false,
            }
          );
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [showOptions, isProfileOptionsVisible, t]);

  const toggleIcon = () => {
    const newIcon = icon === moreImage ? more2Image : moreImage;
    setIcon(newIcon);
    setShowOptions(!showOptions);
  };

  const handleCloseOptions = () => {
    setShowOptions(false);
    setIcon(moreImage);
  };

  const toggleOfferModal = () => {
    setShowOfferModal(!showOfferModal);
  };

  useEffect(() => {
    if (route.params?.selectedOption) {
      setSelectedOption(route.params.selectedOption);
    }
  }, [route.params]);

  useEffect(() => {
    if (route.params?.openOfferModal) {
      setShowOfferModal(true);
    }
  }, [route.params]);

  useEffect(() => {
    fetchValoraciones();
  }, []);

  const fetchValoraciones = async () => {
    try {
      const url = `${serverAddress}/api/v1/perfils/mhanfetcomentaris`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (data.status === "OK" && data.data) {
        setValoraciones(data.data);
      }
    } catch (error) {
      // Manejar error si lo deseas
    }
  };

  return (
    <View style={styles.container}>
      {showOfferModal && (
        <OfferModal type={selectedOption} closeModal={toggleOfferModal} />
      )}
      {/* Modal para ProfileOptions */}
      <Modal
        transparent={true}
        visible={showOptions}
        onRequestClose={handleCloseOptions}
        animationType="none"
      >
        <TouchableWithoutFeedback onPress={handleCloseOptions}>
          <View style={styles.modalOverlay}>
            <TouchableWithoutFeedback>
              <View style={styles.modalContent}>
                <ProfileOptions
                  onVisibilityChange={setIsProfileOptionsVisible}
                  closeModal={handleCloseOptions}
                />
              </View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableWithoutFeedback>
      </Modal>

      <View style={styles.spacer} />
      <View style={styles.profileInfoContainer}>
        <ProfileInfo
          onMoreIconPress={toggleIcon}
          openLocationModalOnInit={openLocationModal}
          icon={icon}
          showOptions={showOptions}
        />
      </View>

      <View style={styles.selectorContainer}>
        <SelectorPresentation
          onSelect={setSelectedOption}
          selectedOption={selectedOption}
        />
      </View>

      <FlatList
        data={[selectedOption]}
        renderItem={({ item }) => (
          <View style={styles.centerContentContainer}>
            {/* ------------- VALORACIONES ------------- */}
            {item === "valoraciones" && (
              <>
                {valoraciones.length === 0 ? (
                  <>
                    <NotRating />
                    {/* Bloque vacío para valoraciones */}
                    <View style={styles.emptyContainer}>
                      <Ionicons
                        name="star-outline"
                        size={60}
                        color={Colors.gray}
                        style={styles.emptyIcon}
                      />
                      <Text style={styles.emptyTitle}>
                        {t.noRatings}
                      </Text>
                      <Text style={styles.emptySubtitle}>
                        {t.continueParticipating}
                      </Text>
                    </View>
                  </>
                ) : (
                  <>
                    {Platform.OS === "web" ? (
                      <>
                        <CardValoracion valoraciones={valoraciones} />
                        <NotRating />
                      </>
                    ) : (
                      <>
                        <NotRating />
                        <CardValoracion valoraciones={valoraciones} />
                      </>
                    )}
                  </>
                )}
              </>
            )}

            {/* ------------- OFRECE ------------- */}
            {item === "ofrece" && (
              <>
                <FirstOffer
                  onPress={toggleOfferModal}
                  selectedOption={selectedOption}
                />
                <NewOffer type="ofrece" setScrollEnabled={setScrollEnabled} />
                {mevesOfertes.length === 0 && (
                  <View style={styles.emptyContainer}>
                    <Ionicons
                      name="pricetags-outline"
                      size={60}
                      color={Colors.gray}
                      style={styles.emptyIcon}
                    />
                    <Text style={styles.emptyTitle}>{t.noOffers}</Text>
                    {/* Texto de “pulsa sobre el rectángulo” */}
                    <Text style={styles.emptySubtitle}>
                      {t.publishHint}
                    </Text>
                  </View>
                )}
              </>
            )}

            {/* ------------- NECESITA ------------- */}
            {item === "necesita" && (
              <>
                <FirstOffer
                  onPress={toggleOfferModal}
                  selectedOption={selectedOption}
                />
                <NewOffer type="necesita" setScrollEnabled={setScrollEnabled} />
                {mevesDemandes.length === 0 && (
                  <View style={styles.emptyContainer}>
                    <Ionicons
                      name="help-circle-outline"
                      size={60}
                      color={Colors.gray}
                      style={styles.emptyIcon}
                    />
                    <Text style={styles.emptyTitle}>{t.noDemands}</Text>
                    <Text style={styles.emptySubtitle}>
                      {t.publishHint}
                    </Text>
                  </View>
                )}
              </>
            )}
          </View>
        )}
        keyExtractor={(item) => item}
        scrollEnabled={isScrollEnabled}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFF",
  },
  spacer: {
    height: 40,
    marginBottom: Platform.select({
      android: 16,
      default: 20,
    }),
  },
  profileInfoContainer: {
    flexDirection: "row",
    marginBottom: -20,
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "space-between",
  },
  selectorContainer: {
    marginBottom: 10,
    alignSelf: "stretch",
    alignItems: "center",
  },
  centerContentContainer: {
    alignItems: "center",
  },
  modalOverlay: {
    flex: 1,
    backgroundColor: "rgba(0,0,0,0.5)",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  modalContent: {
    width: 280,
    backgroundColor: "transparent",
  },

  /* --------------- Estilos para “vacío” --------------- */
  emptyContainer: {
    marginTop: 20,
    marginBottom: 40,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 30,
  },
  emptyIcon: {
    marginBottom: 16,
  },
  emptyTitle: {
    ...Fonts.poppinsSemiBold16,
    textAlign: "center",
    color: Colors.black,
    marginBottom: 6,
  },
  emptySubtitle: {
    ...Fonts.poppinsRegular14,
    textAlign: "center",
    color: Colors.gray,
  },
});

export default ProfileScreen;
