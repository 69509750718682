import React, { useState, useEffect, useContext } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  ScrollView,
  TouchableOpacity,
  Platform,
  Alert,
  Linking,
  Modal,
  ActivityIndicator,
} from "react-native";
import * as Notifications from "expo-notifications";
import { Colors, Fonts } from "../styles/styles";
import { AppContext } from "../../AppContext";
import profileImages from "../img/profileimage.js";
import { useNavigation, useFocusEffect } from "@react-navigation/native";
import EmptyContent from "./EmptyContent";
import { SocketContext } from "../../SocketContext.js";

const translations = {
  ESP: {
    activateNotifications:
      "¿Quieres que te avisemos cuando otros vecinos te escriban un mensaje?",
    alertTitle: "Permite que Trocalia te envíe notificaciones",
    alertMessage: "Toca configuración para actualizar tus preferencias.",
    noOption: "Ahora no",
    settingsOption: "Configuración",
    modalTitle: "Notificaciones disponibles solo en la App",
    modalMessage:
      "Las notificaciones funcionan en la App. Te recomendamos descargarla.",
    closeButtonText: "Cerrar",
    downloadButtonText: "Descargar",
  },
  CAT: {
    activateNotifications:
      "Vols que t'avisem quan altres veïns t'escriguin un missatge?",
    alertTitle: "Permet que Trocalia t'envii notificacions",
    alertMessage: "Toca configuració per actualitzar les teves preferències.",
    noOption: "Ara no",
    settingsOption: "Configuració",
    modalTitle: "Les notificaciones només estan disponibles a l'App",
    modalMessage:
      "Les notificaciones funcionen a l'App. Et recomanem descarregar-la.",
    closeButtonText: "Tancar",
    downloadButtonText: "Descarregar",
  },
  ENG: {
    activateNotifications:
      "Do you want us to notify you when other neighbors write you a message?",
    alertTitle: "Allow Trocalia to send you notifications",
    alertMessage: "Tap settings to update your preferences.",
    noOption: "Not now",
    settingsOption: "Settings",
    modalTitle: "Notifications available only in the App",
    modalMessage:
      "Notifications work in the App. We recommend you download it.",
    closeButtonText: "Close",
    downloadButtonText: "Download",
  },
};

const Mensajes = () => {
  const { token, serverAddress, userData, miID, language } =
    useContext(AppContext);
  const { shouldFetchChats, resetShouldFetchChats } = useContext(SocketContext);

  const navigation = useNavigation();

  const [chats, setChats] = useState([]);
  const [isNotificationsGranted, setIsNotificationsGranted] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Control de carga: evitamos flicker
  const [isFetching, setIsFetching] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);

  const appStoreUrl = "https://apps.apple.com/es/app/trocalia/id6478570314";
  const playStoreUrl =
    "https://play.google.com/store/apps/details?id=com.trocalia";

  // Revisar permisos al montar
  useEffect(() => {
    const checkNotificationPermissions = async () => {
      const { status } = await Notifications.getPermissionsAsync();
      setIsNotificationsGranted(status === "granted");
    };
    checkNotificationPermissions();
  }, []);

  // Solo solicitamos permisos si no están concedidos
  const requestPushPermissions = async () => {
    const { status } = await Notifications.requestPermissionsAsync();
    setIsNotificationsGranted(status === "granted");

    if (status !== "granted") {
      Alert.alert(
        translations[language].alertTitle,
        translations[language].alertMessage,
        [
          { text: translations[language].noOption, style: "cancel" },
          {
            text: translations[language].settingsOption,
            onPress: () => Linking.openSettings(),
          },
        ]
      );
    }
  };

  // Cargamos chats cuando la pantalla entra en foco
  useFocusEffect(
    React.useCallback(() => {
      fetchChatsConControl();
    }, [])
  );

  // Si llega señal desde socket, recargamos
  useEffect(() => {
    if (shouldFetchChats) {
      fetchChatsConControl();
      resetShouldFetchChats();
    }
  }, [shouldFetchChats, resetShouldFetchChats]);

  // Función con control de loader/tiempo
  const fetchChatsConControl = async () => {
    // Si no tenemos chats todavía, activamos el flujo completo (para no “flickerar”)
    if (chats.length === 0) {
      setIsFetching(true);
      setShowLoader(false);
      setHasFetched(false);

      const timer = setTimeout(() => {
        if (isFetching) {
          setShowLoader(true);
        }
      }, 2000);

      try {
        await fetchChats();
      } catch (error) {
        // console.warn("Error fetch", error);
      } finally {
        clearTimeout(timer);
        setIsFetching(false);
        setHasFetched(true);
      }
    } else {
      // Si ya teníamos datos, recargamos en segundo plano para evitar flicker
      try {
        await fetchChats();
      } catch (error) {
        // console.warn("Error fetch", error);
      } finally {
        setHasFetched(true);
      }
    }
  };

  const fetchChats = async () => {
    const response = await fetch(`${serverAddress}/api/v1/perfils/meusxats`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.status === "OK") {
      const chatsConFechaReciente = data.data.map((chat) => ({
        ...chat,
        fechaReciente: chat.missatges.reduce(
          (fechaReciente, mensaje) =>
            new Date(mensaje.dataCreacio) > new Date(fechaReciente)
              ? mensaje.dataCreacio
              : fechaReciente,
          chat.missatges[0].dataCreacio
        ),
      }));

      const chatsOrdenados = chatsConFechaReciente.sort(
        (a, b) => new Date(b.fechaReciente) - new Date(a.fechaReciente)
      );

      setChats(chatsOrdenados);
    } else {
      // Si quieres mantener los chats anteriores en caso de error,
      // comenta la siguiente línea:
      setChats([]);
    }
  };

  const handleDownload = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (Platform.OS === "ios") {
      Linking.openURL(appStoreUrl);
      return;
    } else if (Platform.OS === "android") {
      Linking.openURL(playStoreUrl);
      return;
    }

    // Para web:
    if (
      userAgent.includes("iphone") ||
      userAgent.includes("ipad") ||
      userAgent.includes("macintosh")
    ) {
      Linking.openURL(appStoreUrl);
    } else {
      Linking.openURL(playStoreUrl);
    }
  };

  const esBloqueadoPorMi = (chat) => {
    return chat.usu_bloquejadorXat === miID;
  };

  const navigateToChat = (chat) => {
    if (!userData || !userData.data || userData.data.length === 0) {
      return;
    }
    const otherUserId =
      chat.usu_creadorXat === userData.data[0].id_usuari
        ? chat.usu_receptorXat
        : chat.usu_creadorXat;
    const otherUserPhoto =
      chat.usu_creadorXat === userData.data[0].id_usuari
        ? chat.fotoReceptor
        : chat.fotoCreador;

    const partnerDetails = getChatPartnerDetails(chat);

    navigation.navigate("ChatScreen", {
      otherUserId,
      otherUserPhoto,
      otherUserName: partnerDetails.nombre,
      otherUserSurname: partnerDetails.apellido,
    });
  };

  /**
   * Determina si el último mensaje está sin leer por el usuario actual.
   * Retorna true si el mensaje más reciente NO es del usuario actual y
   * su situacioMissatge no es 'L' (leído).
   */
  const debeMostrarEnNegrita = (mensaje) => {
    if (!userData || !userData.data || userData.data.length === 0) return false;
    const idUsuarioActual = userData.data[0].id_usuari;
    return (
      mensaje.usuariEmisor !== idUsuarioActual &&
      mensaje.situacioMissatge !== "L" &&
      mensaje.situacioMissatge !== null
    );
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    if (date.toDateString() === now.toDateString()) {
      return `${date.getHours()}:${date
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
    } else {
      return `${date.getDate()}/${
        date.getMonth() + 1
      } ${date.getHours()}:${date.getMinutes().toString().padStart(2, "0")}`;
    }
  };

  const getChatPartnerDetails = (chat) => {
    if (!userData || !userData.data || userData.data.length === 0) {
      return null;
    }
    const currentUser = userData.data[0].id_usuari;
    if (chat.usu_receptorXat === currentUser) {
      return {
        nombre: chat.nomUsuCreador,
        apellido: chat.cognomUsuCreador,
        foto: chat.fotoCreador,
        tipo: chat.tipoUsuCreador,
      };
    } else {
      return {
        nombre: chat.nomUsuReceptor,
        apellido: chat.cognomUsuReceptor,
        foto: chat.fotoReceptor,
        tipo: chat.tipoUsuReceptor,
      };
    }
  };

  // --- Renderizado ----

  // Mientras no hayamos hecho ningún fetch:
  // - Si han pasado 2s sin respuesta y NO teníamos chats => mostramos el loader.
  // - Sino (falta poco o ya viene) => mostramos nada (pantalla en blanco).
  if (!hasFetched && chats.length === 0) {
    if (showLoader) {
      return (
        <View style={styles.loaderContainer}>
          <ActivityIndicator size="large" color={Colors.primary} />
        </View>
      );
    } else {
      return null; // ni loader ni EmptyContent, para no ver parpadeo
    }
  }

  // Si ya hemos terminado la carga y no hay chats => EmptyContent
  if (chats.length === 0) {
    return <EmptyContent contentType="mensajes" />;
  }

  // Si hay chats, los mostramos
  return (
    <ScrollView contentContainerStyle={styles.scrollViewContainer}>
      {/* Botón para activar notificaciones si no están concedidas */}
      {!isNotificationsGranted && (
        <TouchableOpacity
          onPress={() => {
            if (Platform.OS === "web") {
              setIsModalVisible(true);
            } else {
              requestPushPermissions();
            }
          }}
          style={styles.notificationPrompt}
        >
          <Text style={styles.notificationText}>
            {translations[language].activateNotifications}
          </Text>
          <Text style={styles.iconText}>💬</Text>
        </TouchableOpacity>
      )}

      {/* Renderizado de los chats */}
      {chats.map((chat, index) => {
        const partner = getChatPartnerDetails(chat);
        if (!partner) return null;

        const bloqueadoPorMi = esBloqueadoPorMi(chat);
        const ultimoMensaje = chat.missatges[0] || {};

        return (
          <TouchableOpacity key={index} onPress={() => navigateToChat(chat)}>
            <View
              style={[
                styles.cardContainer,
                bloqueadoPorMi ? styles.bloqueadoStyle : {},
                partner.tipo === "Premium"
                  ? [styles.cardBorderPremium, styles.cardBackgroundPremium]
                  : partner.tipo === "Colaborador"
                  ? [
                      styles.cardBorderCollaborator,
                      styles.cardBackgroundCollaborator,
                    ]
                  : [styles.cardBorderStandard, styles.cardBackgroundStandard],
                debeMostrarEnNegrita(ultimoMensaje) && styles.unreadContainer,
              ]}
            >
              <View style={styles.profileImageContainer}>
                <Image
                  style={[
                    styles.profileImage,
                    partner.tipo === "Premium"
                      ? styles.imageBorderPremium
                      : partner.tipo === "Colaborador"
                      ? styles.imageBorderCollaborator
                      : styles.imageBorderStandard,
                  ]}
                  source={
                    partner.foto
                      ? { uri: `${serverAddress}/fotos/${partner.foto}` }
                      : profileImages[chat.usu_receptorXat % 10] ||
                        require("../img/profiles/default.png")
                  }
                  resizeMode="cover"
                />
                {debeMostrarEnNegrita(ultimoMensaje) && (
                  <View style={styles.unreadDot} />
                )}
              </View>

              {partner.tipo === "Premium" && (
                <Text style={styles.proText}>PRO</Text>
              )}

              {bloqueadoPorMi && (
                <Image
                  style={styles.blockIcon}
                  source={require("../img/icons/block2.png")}
                />
              )}

              <View style={styles.textContainer}>
                <Text style={[Fonts.poppinsMedium16, styles.title]}>
                  {partner.nombre} {partner.apellido}
                </Text>
                <Text
                  style={[
                    Fonts.poppinsRegular14,
                    styles.descriptionText,
                    debeMostrarEnNegrita(ultimoMensaje)
                      ? styles.negrita
                      : { color: Colors.grayDark },
                  ]}
                  numberOfLines={2}
                >
                  {ultimoMensaje.texte}
                </Text>
              </View>
              <Text style={[Fonts.poppinsRegular12, styles.dateText]}>
                {formatDate(ultimoMensaje.dataCreacio)}
              </Text>
            </View>
          </TouchableOpacity>
        );
      })}

      {/* Modal para web */}
      <Modal
        animationType="slide"
        transparent={true}
        visible={isModalVisible}
        onRequestClose={() => {}}
      >
        <View style={styles.modalBackground}>
          <View style={styles.modalView}>
            <Text style={styles.modalTitle}>
              {translations[language].modalTitle}
            </Text>
            <Text style={styles.modalMessage}>
              {translations[language].modalMessage}
            </Text>
            <View style={styles.buttonContainer}>
              <TouchableOpacity
                style={[styles.button, styles.cancelButton]}
                onPress={() => setIsModalVisible(false)}
              >
                <Text style={styles.buttonText}>
                  {translations[language].closeButtonText}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.button, styles.downloadButton]}
                onPress={handleDownload}
              >
                <Text style={styles.buttonText}>
                  {translations[language].downloadButtonText}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </ScrollView>
  );
};

export default Mensajes;

const styles = StyleSheet.create({
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  scrollViewContainer: {
    flexGrow: 1,
    justifyContent: "flex-start",
    alignItems: "center",
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
  notificationPrompt: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: Colors.white,
    width: 340,
    paddingVertical: 6,
    paddingHorizontal: 12,
    borderRadius: 16,
    marginBottom: 16,
    borderWidth: 1,
    borderColor: Colors.primary,
  },
  iconText: {
    fontSize: 20,
    color: Colors.primary,
  },
  notificationText: {
    color: Colors.primary,
    fontSize: 14,
    textAlign: "left",
    fontWeight: "bold",
    flex: 1,
  },
  cardContainer: {
    flexDirection: "row",
    marginBottom: 16,
    alignItems: "center",
    borderRadius: 16,
    height: 90,
    justifyContent: "flex-start",
    padding: 16,
    width: 340,
    alignSelf: "center",
  },
  cardBorderStandard: {
    borderColor: Colors.grayLighter,
  },
  cardBorderPremium: {
    borderColor: Colors.secondary,
  },
  cardBorderCollaborator: {
    borderColor: Colors.primary,
  },
  cardBackgroundStandard: {
    backgroundColor: Colors.grayLighter,
  },
  cardBackgroundPremium: {
    backgroundColor: "rgba(255, 223, 158, 0.2)",
  },
  cardBackgroundCollaborator: {
    backgroundColor: "rgba(177, 217, 244, 0.2)",
  },
  profileImageContainer: {
    position: "relative",
    marginRight: 15,
  },
  profileImage: {
    borderRadius: 35.5,
    width: 71,
    height: 71,
    backgroundColor: Colors.primary,
    borderWidth: 2,
  },
  imageBorderStandard: {
    borderColor: Colors.grayMediumDark,
  },
  imageBorderPremium: {
    borderColor: Colors.secondary,
  },
  imageBorderCollaborator: {
    borderColor: Colors.primary,
  },
  proText: {
    position: "absolute",
    top: 4,
    left: 4,
    color: Colors.secondary,
    ...Fonts.poppinsSemiBold14,
  },
  textContainer: {
    flex: 1,
    justifyContent: "space-between",
  },
  title: {
    ...Fonts.poppinsMedium16,
  },
  descriptionText: {
    marginTop: 6,
    ...Fonts.poppinsRegular14,
  },
  dateText: {
    position: "absolute",
    top: 10,
    right: 10,
    color: Colors.grayDark,
    fontSize: 12,
  },
  bloqueadoStyle: {
    backgroundColor: "#D3D3D3",
  },
  blockIcon: {
    position: "absolute",
    right: 10,
    bottom: 10,
    width: 25,
    height: 22,
  },
  negrita: {
    ...Platform.select({
      ios: {
        fontFamily: "Helvetica",
        fontWeight: "bold",
      },
      android: {
        fontWeight: "bold",
      },
      default: {
        fontWeight: "bold",
      },
    }),
  },
  unreadContainer: {
    borderWidth: 2,
    borderColor: "rgba(255, 0, 0, 0.07)",
  },
  unreadDot: {
    position: "absolute",
    right: 70,
    top: 60,
    width: 12,
    height: 12,
    borderRadius: 6,
    backgroundColor: "red",
  },
  modalBackground: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    backgroundColor: "white",
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: "80%",
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 15,
    color: Colors.primary,
  },
  modalMessage: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 20,
    color: Colors.grayDark,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
  },
  button: {
    borderRadius: 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
    elevation: 2,
    margin: 5,
    alignSelf: "flex-start",
  },
  downloadButton: {
    backgroundColor: Colors.primary,
  },
  cancelButton: {
    backgroundColor: "#CCCCCC",
  },
  buttonText: {
    color: "white",
    fontWeight: "bold",
    fontSize: 18,
    textAlign: "center",
  },
});
