import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Text,
  View,
  ScrollView,
  StyleSheet,
  Platform,
  TouchableOpacity,
  Modal,
} from "react-native";
import { Colors, Fonts } from "../styles/styles";
import ProfileOthers from "../components/ProfileOthers";
import SelectorValoraciones from "../selectables/SelectorValoraciones";
import CardValoracion from "../components/CardValoracion";
import CardOfertas from "../components/CardOfertas";
import { useRoute, useNavigation } from "@react-navigation/native";
import { AppContext } from "../../AppContext";
import EmptyContent from "../components/EmptyContent";

const ProfileOther = () => {
  const [selectedOption, setSelectedOption] = useState("ofrece");
  const [userData, setUserData] = useState(null);
  const [isUserNotFound, setIsUserNotFound] = useState(false);

  const route = useRoute();
  const userId = route.params?.userId;

  const { serverAddress, token, isTokenLoading } = useContext(AppContext);
  const navigation = useNavigation();
  const { language } = useContext(AppContext);
  const { setPendingProfileId } = useContext(AppContext);

  const scrollViewRef = useRef();
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleBackPress = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      setIsUserNotFound(false);
      navigation.navigate("Home");
    }
  };

  useEffect(() => {
    if (!isTokenLoading) {
      if (token) {
        setUserData(null);
        fetchData();
      } else {
        setPendingProfileId(userId);
        navigation.navigate("Login");
      }
    }
  }, [token, userId, isTokenLoading]);

  const translate = {
    ESP: {
      volver: "Volver atrás",
      usuarioNoEncontrado:
        "Este usuario era un Invitado y ya ha finalizado su sesión.",
    },
    CAT: {
      volver: "Tornar enrere",
      usuarioNoEncontrado:
        "Aquest usuari era un Convidat i ja ha finalitzat la seva sessió.",
    },
    ENG: {
      volver: "Go back",
      usuarioNoEncontrado:
        "This user was a guest and has already ended its session.",
    },
  };

  const fetchData = async () => {
    if (!token || !userId) return;

    try {
      const url = `${serverAddress}/api/v1/perfils/others/perfil/${userId}`;
      const response = await fetch(url, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) {
        throw new Error(`Server error: ${response.status}`);
      }

      const data = await response.json();

      if (
        data.data.status === "NOK" &&
        data.data.error === "L'usuari no existeix"
      ) {
        setIsUserNotFound(true);
      } else {
        const newUserData = data.data[0];
        setUserData(newUserData);

        // Lógica para seleccionar la opción por defecto
        if (
          newUserData?.sevesOfertes?.length === 0 &&
          newUserData?.sevesDemandes?.length > 0
        ) {
          setSelectedOption("necesita");
        } else {
          setSelectedOption("ofrece");
        }
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      // Aquí podrías manejar el error mostrando un mensaje al usuario
    }
  };

  const renderSelectedOption = () => {
    // Si userData es nulo, no renderizar nada
    if (!userData) return null;

    switch (selectedOption) {
      case "ofrece":
        return userData?.sevesOfertes?.length ? (
          <CardOfertas
            data={userData.sevesOfertes}
            userId={userId}
            userData={userData}
          />
        ) : (
          <EmptyContent contentType="ofertas" />
        );

      case "necesita":
        return userData?.sevesDemandes?.length ? (
          <CardOfertas
            data={userData.sevesDemandes}
            userId={userId}
            userData={userData}
          />
        ) : (
          <EmptyContent contentType="necesidades" />
        );

      case "valoraciones":
        return userData?.comentarisRebuts?.length ? (
          <CardValoracion valoraciones={userData.comentarisRebuts} />
        ) : (
          <EmptyContent contentType="valoraciones" />
        );

      default:
        return null;
    }
  };

  const handleScroll = (event) => {
    setScrollPosition(event.nativeEvent.contentOffset.y);
  };

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    scrollViewRef.current?.scrollTo({ y: scrollPosition, animated: false });
  };

  useEffect(() => {
    scrollViewRef.current?.scrollTo({ y: scrollPosition, animated: false });
  }, []);

  return (
    <View style={styles.mainContainer}>
      {(!navigation.canGoBack() || Platform.OS === "web") && (
        <View style={styles.backButtonContainer}>
          <TouchableOpacity onPress={handleBackPress}>
            <Text style={styles.backButtonText}>
              {translate[language].volver}
            </Text>
          </TouchableOpacity>
        </View>
      )}

      {/* Modal para usuario no encontrado */}
      <Modal
        animationType="slide"
        transparent={true}
        visible={isUserNotFound}
        onRequestClose={() => setIsUserNotFound(false)}
      >
        <View style={styles.modalOverlay}>
          <View style={styles.modalContainer}>
            <Text style={styles.modalText}>
              {translate[language].usuarioNoEncontrado}
            </Text>
            <TouchableOpacity
              style={styles.closeButton}
              onPress={handleBackPress}
            >
              <Text style={styles.closeButtonText}>
                {translate[language].volver}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>

      {/* Renderizar el contenido principal solo si userData está cargado */}
      {userData !== null && (
        <ScrollView
          ref={scrollViewRef}
          onScroll={handleScroll}
          scrollEventThrottle={16}
          contentContainerStyle={styles.container}
        >
          <View style={styles.innerContainer}>
            <ProfileOthers userData={userData} reloadData={fetchData} />

            <View style={Platform.OS === "web" ? styles.webSpacing : null}>
              <SelectorValoraciones
                onSelect={handleSelectOption}
                selectedOption={selectedOption}
              />
              <View style={styles.cardContainer}>{renderSelectedOption()}</View>
            </View>
          </View>
        </ScrollView>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  backButtonContainer: {
    position: "absolute",
    top: 10,
    left: 10,
    zIndex: 1,
  },
  backButtonText: {
    color: Colors.primary,
    fontSize: 16,
    textDecorationLine: "underline",
    backgroundColor: "white",
    padding: 5,
    borderRadius: 5,
    ...Fonts.poppinsRegular16,
  },
  container: {
    paddingTop: 10,
    paddingBottom: 30,
    backgroundColor: Colors.white,
    alignItems: "center",
    flexGrow: 1,
  },
  innerContainer: {
    width: "100%",
  },
  webSpacing: {
    marginTop: 0,
  },
  cardContainer: {
    marginTop: 20,
    alignItems: "center",
    width: "100%",
  },
  modalOverlay: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    backgroundColor: Colors.white,
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalText: {
    fontSize: 18,
    marginBottom: 15,
    textAlign: "center",
    ...Fonts.poppinsRegular16,
    color: Colors.grayDark,
  },
  closeButton: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
    backgroundColor: Colors.primary,
    marginTop: 10,
  },
  closeButtonText: {
    color: Colors.white,
    fontSize: 16,
    ...Fonts.poppinsSemiBold16,
  },
});

export default ProfileOther;

