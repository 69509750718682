import React, { useContext, useEffect, useState, useRef } from "react";
import { Text, View, StyleSheet, TouchableOpacity, Platform, Animated, Easing } from "react-native";
import { AppContext } from "../../AppContext";
import { Colors, Fonts } from "../styles/styles.js";
import * as SecureStore from "expo-secure-store";
import { useNavigation } from "@react-navigation/native";
import * as Haptic from "expo-haptics";

const translations = {
  ESP: {
    usersTextPrefix: "¡Somos",
    usersTextSuffix: "vecinos!",
    registerButtonText: "¡Regístrate!🚀",
    xarxa: "Red de Economía Vecinal",
  },
  CAT: {
    usersTextPrefix: "Som",
    usersTextSuffix: "veïns!",
    registerButtonText: "Registra't!🚀",
    xarxa: "Xarxa d’Economia Veïnal",
  },
  ENG: {
    usersTextPrefix: "We are",
    usersTextSuffix: "users!",
    registerButtonText: "Register!🚀",
    xarxa: "Local Economy Network",
  },
};

const RegisterButton = ({ onClose }) => {
  const { language, serverAddress, setToken, eraseUserData } = useContext(AppContext);
  const t = translations[language];
  const [numUsuarios, setNumUsuarios] = useState(null);
  const navigation = useNavigation();

  const bounceAnim = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    const fetchNumUsuarios = async () => {
      try {
        const response = await fetch(`${serverAddress}/api/v1/auxiliars/numUsuaris`);
        const data = await response.json();
        if (data.status === "OK" && data.data && data.data[0]) {
          setNumUsuarios(data.data[0].numUsuaris);
        }
      } catch (error) {
        //console.error("Error fetching number of users:", error);
      }
    };

    fetchNumUsuarios();
  }, [serverAddress]);

  useEffect(() => {
    const startBounceAnimation = () => {
      Animated.sequence([
        Animated.timing(bounceAnim, {
          toValue: 1.15,
          duration: 600,
          easing: Easing.out(Easing.ease),
          useNativeDriver: true,
        }),
        Animated.timing(bounceAnim, {
          toValue: 1,
          duration: 300,
          easing: Easing.out(Easing.ease),
          useNativeDriver: true,
        }),
      ]).start();
    };

    const interval = setInterval(() => {
      startBounceAnimation();
    }, 6000);

    return () => clearInterval(interval);
  }, [bounceAnim]);

  const handleRegister = async () => {
    if (Platform.OS !== "web") {
      await Haptic.impactAsync(Haptic.ImpactFeedbackStyle.Heavy);
    }

    setToken(null);
    eraseUserData();
    if (Platform.OS === "web") {
      localStorage.removeItem("userToken");
    } else {
      await SecureStore.deleteItemAsync("userToken");
    }

    if (onClose) onClose();
    navigation.navigate("Register");
  };

  return (
    <TouchableOpacity style={styles.registerButtonContainer} onPress={handleRegister}>
      {numUsuarios !== null ? (
        <Text style={styles.registerUsersText}>
          {t.usersTextPrefix} <Text style={styles.boldText}>{numUsuarios}</Text> {t.usersTextSuffix}
        </Text>
      ) : (
        <Text style={styles.registerUsersText}>{t.xarxa}</Text>
      )}
      <Animated.Text style={[styles.registerButtonText, { transform: [{ scale: bounceAnim }] }]}>
        {t.registerButtonText}
      </Animated.Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  registerButtonContainer: {
    backgroundColor: Colors.white,
    borderColor: "green",
    borderWidth: 2,
    borderRadius: 10,
    paddingVertical: 2,
    paddingHorizontal: 8,
    alignItems: "center",
  },
  registerUsersText: {
    color: Colors.black,
    fontSize: 10,
    marginBottom: 2,
    textAlign: "center",
  },
  registerButtonText: {
    color: "green",
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
  },
  boldText: {
    fontWeight: "bold",
  },
});

export default RegisterButton;
