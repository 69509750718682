import React, { useContext, useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  Image,
  TouchableOpacity,
  Platform,
} from "react-native";
import { Colors, Fonts } from "../styles/styles.js";
import { AppContext } from "../../AppContext";
import { useNavigation } from "@react-navigation/native";
import NeedRegister from "../selectables/NeedRegister.js"; // Importar el modal NeedRegister

const translate = {
  ESP: {
    need: "Demanda",
    offer: "Ofrece",
    title: "Busco algo que tu ofreces en ",
    addOffer: "Añade una oferta para encontrar usuarios coincidentes!",
    addDemand: "Añade una demanda para encontrar usuarios coincidentes!",
    regaloSofa: "Ofrezco algo que tú necesitas en",
    example: "Vecino de ejemplo",
  },
  CAT: {
    need: "Demanda",
    offer: "Ofereix",
    title: "Busco alguna cosa que tu ofereixes a",
    addOffer: "Afegeix una oferta per trobar usuaris coincidents!",
    addDemand: "Afegeix una demanda per trobar usuaris coincidents!",
    regaloSofa: "Ofereixo alguna cosa que tu necessites a ",
    example: "Veí d'exemple",
  },
  ENG: {
    need: "Need",
    offer: "Offer",
    title: "I am looking for a person for home cleaning who is from",
    addOffer: "Add an offer to find matching users!",
    addDemand: "Add a demand to find matching users!",
    regaloSofa: "I offer something you need in",
    example: "Example neighbor",
  },
};

const NoDataTrocVertical = ({ tipo = "oferta" }) => {
  const { language, userData } = useContext(AppContext);
  const navigation = useNavigation();
  const [isNeedRegisterVisible, setIsNeedRegisterVisible] = useState(false); // Estado para controlar la visibilidad del modal
  const isDemand = tipo === "demanda";

  const handlePress = () => {
    if (userData?.data[0]?.tipusUsuari === "Guest") {
      setIsNeedRegisterVisible(true); // Muestra el modal si el usuario es Guest
    } else {
      const profileScreen = language === "ENG" ? "Profile" : "Perfil";
      navigation.navigate(profileScreen, {
        selectedOption: isDemand ? "necesita" : "ofrece",
        openOfferModal: true,
      });
    }
  };

  const zona = userData ? userData.data[0].zona : "aquí";
  const name = `${translate[language].example}`;
  const imageSource = isDemand
    ? require("../img/profiles/6.png")
    : require("../img/profiles/8.png");
  const title = isDemand
    ? `${translate[language].regaloSofa} ${zona}`
    : `${translate[language].title} ${zona}`;

  return (
    <>
      <View style={styles.container}>
        {/* Primera Tarjeta: Vecino de Ejemplo */}
        <TouchableOpacity onPress={handlePress}>
          <View style={styles.cardContainer}>
            <View style={styles.cardBackground} />
            <Image
              style={styles.cardImage}
              resizeMode="cover"
              source={imageSource}
            />
            <Text style={[Fonts.poppinsMedium14, styles.nameText]}>
              {name}
            </Text>
            <Text style={[Fonts.poppinsMedium14, styles.trocTypeText]}>
              {isDemand ? translate[language].offer : translate[language].need}
            </Text>
            <Text style={[Fonts.poppinsRegular12, styles.categoriaText]}>
              {title}
            </Text>
            <Text style={[Fonts.poppinsRegular12, styles.kmText]}>0 Km</Text>
            <View style={styles.valoracionContainer}>
              <Text style={[Fonts.poppinsRegular12, styles.ratingText]}>
                5.0
              </Text>
              <Image
                style={styles.iconText}
                resizeMode="cover"
                source={require("../img/icons/puntuation.png")}
              />
            </View>
          </View>
        </TouchableOpacity>

        {/* Segunda Tarjeta: Añadir Oferta/Demanda */}
        <TouchableOpacity onPress={handlePress}>
          <View style={[styles.cardContainer, styles.secondaryCard]}>
            <View style={styles.cardBackground} />
            <Text
              style={[Fonts.poppinsRegular12, styles.secondaryCardText]}
            >
              {isDemand
                ? translate[language].addDemand
                : translate[language].addOffer}
            </Text>
          </View>
        </TouchableOpacity>

        {/* Tercera Tarjeta: Solo Fondo Sin Texto */}
        <TouchableOpacity onPress={handlePress}>
          <View style={[styles.cardContainer, styles.emptyCard]}>
            <View style={styles.cardBackground} />
            {/* No se añade contenido adicional */}
          </View>
        </TouchableOpacity>
      </View>
      {isNeedRegisterVisible && (
        <NeedRegister
          isVisible={isNeedRegisterVisible}
          onClose={() => setIsNeedRegisterVisible(false)}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    paddingHorizontal: 14,
  },
  cardContainer: {
    width: 143,
    height: 227,
    marginRight: 8, // Espacio a la derecha de cada tarjeta
    marginBottom: 15,
    marginTop: 8,
    marginLeft: 0, // Sin margen a la izquierda por defecto
    borderRadius: 15,
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.grayLighter,
    overflow: "hidden", // Asegura que el contenido no desborde el contenedor
  },
  cardBackground: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderRadius: 15,
    backgroundColor: Colors.grayLighter,
  },
  cardImage: {
    position: "absolute",
    left: 27,
    top: 9,
    borderRadius: 69,
    width: 89,
    height: 83,
    backgroundColor: Colors.primary,
  },
  nameText: {
    left: 11,
    top: 97,
    width: 132,
    marginBottom: -25,
  },
  trocTypeText: {
    top: 128,
    left: 11,
    position: "absolute",
  },
  categoriaText: {
    top: 148,
    left: 11,
    position: "absolute",
    width: 132,
  },
  kmText: {
    position: "absolute",
    top: 210,
    left: 100,
    textAlign: "left",
  },
  valoracionContainer: {
    position: "absolute",
    left: 8,
    bottom: 6,
    flexDirection: "row",
    alignItems: "center",
  },
  ratingText: {
    marginRight: 8,
    marginBottom: -6,
    marginLeft: 2,
  },
  iconText: {
    width: 16,
    height: 16,
    marginBottom: -2,
  },
  secondaryCard: {
    backgroundColor: Colors.grayLighter,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 10,
    marginLeft: 8, // Ajustado a 8 para consistencia
  },
  secondaryCardText: {
    color: Colors.primary,
    ...Fonts.poppinsMedium16,
    textAlign: "center",
  },
  emptyCard: {
    backgroundColor: Colors.grayLighter, // Mismo color de fondo que las demás tarjetas
    marginLeft: 8, // Añadido margen izquierdo para consistencia
    justifyContent: "center",
    alignItems: "center",
  },
});

export default NoDataTrocVertical;
