import React, { useContext, useState, useEffect, useRef } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
  Platform,
  Alert,
  Modal,
  Animated,
  Easing,
  Linking,
} from "react-native";
import { Fonts, Colors } from "../styles/styles.js";
import { AppContext } from "../../AppContext";
import Pro from "../components/Pro.js";
import addImage from "../img/icons/add2.png";
import * as Haptic from "expo-haptics";
import * as Notifications from "expo-notifications";
import * as Device from "expo-device";

/* -------------------------------------------------------------------
   TRADUCCIONES con copys mejorados y emojis
   ------------------------------------------------------------------- */
const translate = {
  ESP: {
    // Opción de "ofrece" y "necesita"
    ofrece: "Ofrece algo a tu comunidad (¿Qué ofreces?)",
    necesita: "Pide algo a tu comunidad (¿Qué necesitas?)",

    // Límite de ofertas
    limiteAlcanzado:
      "Has alcanzado el número máximo de ofertas permitidas en tu cuenta. Puedes pausar, eliminar ofertas o hacerte PRO para publicar más.",
    tituloLimiteAlcanzado: "Límite de ofertas alcanzado",
    cerrar: "Cerrar",
    verPro: "Ver PRO",

    // CASO WEB: correo desactivado
    mailWebAlertTitle: "❌ Falta activar tu correo",
    mailWebAlertMessage:
      "Sin el correo activado, no podrás publicar ni recibir avisos en tu bandeja de entrada.\n\n¿Deseas habilitarlo ahora?",
    mailWebAlertBtnActivate: "Activar ✉️",
    mailWebAlertBtnCancel: "Cancelar",

    // Casos iOS/Android
    // A) Mail y push desactivados
    mailAndPushOffTitle: "⚠️ ¡Correo y notificaciones desactivados!",
    mailAndPushOffMessage:
      "Sin el correo, no podrás publicar. Además, tampoco recibirás alertas push. ¿Activamos primero el correo para que puedas continuar?",
    mailAndPushOffActivate: "Activar ✉️",
    mailAndPushOffCancel: "Mejor no",

    // B) Mail activado, push desactivado
    pushOffMailOnTitle: "Activa notificaciones 🔔",
    pushOffMailOnMessage:
      "Ya tienes el correo habilitado, pero te perderás avisos instantáneos si no enciendes las notificaciones push. ¿Deseas hacerlo ahora?",
    pushOffMailOnActivate: "Activar 🔔",
    pushOffMailOnSkip: "Ahora no",

    // C) Mail desactivado, push activado
    mailOffPushOnTitle: "¿Activar correo ✉️?",
    mailOffPushOnMessage:
      "Así podrás recibir avisos y mensajes en tu bandeja de entrada. ¡No tendrás que abrir la app para enterarte! ¿Lo activamos ahora?",
    mailOffPushOnActivate: "Activar ✉️",
    mailOffPushOnSkip: "No, gracias",

    // Push permissions
    pushPermissionTitle: "Permisos de notificación",
    pushPermissionDenied:
      "Necesitas habilitar las notificaciones del dispositivo. ¡Te llevamos a la configuración!",
    pushPermissionError: "Error al solicitar notificaciones push.",
    pushSuccess: "Notificaciones activadas con éxito.",
    pushError: "Error al registrar el token de notificaciones.",
  },

  CAT: {
    // Opción de "ofrece" y "necesita"
    ofrece: "Ofereix alguna cosa a la teva comunitat (Què ofereixes?)",
    necesita: "Demana alguna cosa a la teva comunitat (Què necessites?)",

    // Límite de ofertas
    limiteAlcanzado:
      "Has assolit el nombre màxim d'ofertes permeses en el teu compte. Pots pausar o eliminar ofertes, o fer-te PRO per publicar més.",
    tituloLimiteAlcanzado: "Límit d'ofertes assolit",
    cerrar: "Tancar",
    verPro: "Veure PRO",

    // CASO WEB: correo desactivado
    mailWebAlertTitle: "❌ Falta activar el teu correu",
    mailWebAlertMessage:
      "Sense el correu activat, no podràs publicar ni rebre avisos a la bústia.\n\nVols habilitar-lo ara?",
    mailWebAlertBtnActivate: "Activar ✉️",
    mailWebAlertBtnCancel: "Cancel·lar",

    // A) Correu i notificacions desactivats
    mailAndPushOffTitle: "⚠️ Correu i notificacions desactivats!",
    mailAndPushOffMessage:
      "Sense el correu, no podràs publicar. A més, tampoc rebràs alertes push. Activem primer el correu perquè puguis continuar?",
    mailAndPushOffActivate: "Activar ✉️",
    mailAndPushOffCancel: "Millor no",

    // B) Correu activat, push desactivat
    pushOffMailOnTitle: "Activa notificacions 🔔",
    pushOffMailOnMessage:
      "Ja tens el correu habilitat, però et perdràs avisos immediats si no encens les notificacions push. Ho vols fer ara?",
    pushOffMailOnActivate: "Activar 🔔",
    pushOffMailOnSkip: "Ara no",

    // C) Correu desactivat, push activat
    mailOffPushOnTitle: "Vols activar el correu ✉️?",
    mailOffPushOnMessage:
      "Així rebràs avisos i missatges a la safata d'entrada sense haver d'obrir l'app. T'animes a activar-lo ara?",
    mailOffPushOnActivate: "Activar ✉️",
    mailOffPushOnSkip: "No, gràcies",

    // Push
    pushPermissionTitle: "Permisos de notificació",
    pushPermissionDenied:
      "Cal habilitar les notificacions del dispositiu. T'hi portem!",
    pushPermissionError: "Error en sol·licitar les notificacions push.",
    pushSuccess: "Notificacions activades amb èxit.",
    pushError: "Error en registrar el token de notificacions.",
  },

  ENG: {
    // Opción de "ofrece" y "necesita"
    ofrece: "Offer something to your community (What do you offer?)",
    necesita: "Request something from your community (What do you need?)",

    // Límite de ofertas
    limiteAlcanzado:
      "You've reached the maximum number of offers for your account. You can pause, remove offers, or go PRO to publish more.",
    tituloLimiteAlcanzado: "Offer limit reached",
    cerrar: "Close",
    verPro: "View PRO",

    // CASO WEB: correo desactivado
    mailWebAlertTitle: "❌ Email not enabled",
    mailWebAlertMessage:
      "Without enabling email, you can't post or receive inbox alerts.\n\nDo you want to set it up now?",
    mailWebAlertBtnActivate: "Enable ✉️",
    mailWebAlertBtnCancel: "Cancel",

    // A) Mail & push off
    mailAndPushOffTitle: "⚠️ Email & notifications off!",
    mailAndPushOffMessage:
      "You can't post without email. Also, you won't get push alerts. Let's enable email first so you can proceed?",
    mailAndPushOffActivate: "Enable ✉️",
    mailAndPushOffCancel: "No thanks",

    // B) Mail on, push off
    pushOffMailOnTitle: "Turn on notifications 🔔",
    pushOffMailOnMessage:
      "You already have email enabled, but you'll miss instant alerts if you don't enable push notifications. Do it now?",
    pushOffMailOnActivate: "Enable 🔔",
    pushOffMailOnSkip: "Not now",

    // C) Mail off, push on
    mailOffPushOnTitle: "Enable email ✉️?",
    mailOffPushOnMessage:
      "This way, you'll receive inbox messages without opening the app. Ready to enable email now?",
    mailOffPushOnActivate: "Enable ✉️",
    mailOffPushOnSkip: "No, thanks",

    // Push
    pushPermissionTitle: "Notification permissions",
    pushPermissionDenied:
      "You need to enable device notifications. We'll take you to settings!",
    pushPermissionError: "Error requesting push notifications.",
    pushSuccess: "Push notifications enabled successfully.",
    pushError: "Error registering the push token.",
  },
};

/* -------------------------------------------------------------------
   PEQUEÑA FUNCIÓN: Separa "frase (ejemplo)" en dos textos
   ------------------------------------------------------------------- */
const splitText = (text) => {
  const regex = /^(.*?)\s*\((.*?)\)$/;
  const match = text.match(regex);
  if (match) {
    return { main: match[1], example: match[2] };
  }
  return { main: text, example: "" };
};

const FirstOffer = ({ onPress, selectedOption }) => {
  const { userData, language, serverAddress, token, refreshUserData } =
    useContext(AppContext);

  /* ----------------------------------------------
     ESTADOS locales
     ---------------------------------------------- */
  const [isProVisible, setIsProVisible] = useState(false);
  const [isWebModalVisible, setWebModalVisible] = useState(false);

  // **Estado para mostrar/ocultar el modal de "mail inactivo en web"**
  const [showWebMailAlert, setShowWebMailAlert] = useState(false);

  // Animaciones
  const fadeAnim = useRef(new Animated.Value(0)).current; // Opacidad para fadeIn
  const bounceAnim = useRef(new Animated.Value(1)).current; // Escala para bounce
  const scaleAnim = useRef(new Animated.Value(1)).current; // Escala para animación periódica
  const rotateAnim = useRef(new Animated.Value(0)).current; // Rotación para icono

  /* ----------------------------------------------
     Animaciones de montaje
     ---------------------------------------------- */
  useEffect(() => {
    // Animación de fadeIn para el contenedor principal
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 800,
      useNativeDriver: true,
    }).start();

    // Animación de bounce para el icono
    Animated.sequence([
      Animated.timing(bounceAnim, {
        toValue: 1.2,
        duration: 300,
        easing: Easing.out(Easing.ease),
        useNativeDriver: true,
      }),
      Animated.timing(bounceAnim, {
        toValue: 1,
        duration: 300,
        easing: Easing.out(Easing.ease),
        useNativeDriver: true,
      }),
    ]).start();

    // Animación periódica
    const startPeriodicAnimations = () => {
      // Escalado del contenedor
      Animated.sequence([
        Animated.timing(scaleAnim, {
          toValue: 1.05,
          duration: 1800,
          easing: Easing.out(Easing.ease),
          useNativeDriver: true,
        }),
        Animated.timing(scaleAnim, {
          toValue: 1,
          duration: 800,
          easing: Easing.out(Easing.ease),
          useNativeDriver: true,
        }),
      ]).start();

      // Rotación del icono
      Animated.sequence([
        Animated.timing(rotateAnim, {
          toValue: 1, // 360*4
          duration: 2000,
          easing: Easing.linear,
          useNativeDriver: true,
        }),
        Animated.timing(rotateAnim, {
          toValue: 0,
          duration: 0,
          useNativeDriver: true,
        }),
      ]).start();
    };

    // Inicia tras 4s
    const animationTimeout = setTimeout(() => {
      startPeriodicAnimations();
      // Repite cada 10s
      const interval = setInterval(() => {
        startPeriodicAnimations();
      }, 10000);
      return () => clearInterval(interval);
    }, 4000);

    return () => {
      fadeAnim.stopAnimation();
      bounceAnim.stopAnimation();
      scaleAnim.stopAnimation();
      rotateAnim.stopAnimation();
      clearTimeout(animationTimeout);
    };
  }, [fadeAnim, bounceAnim, scaleAnim, rotateAnim]);

  // Interpolación rotación
  const rotateInterpolate = rotateAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ["0deg", "1440deg"], // 4 vueltas
  });

  /* ----------------------------------------------
     Acciones para ver PRO
     ---------------------------------------------- */
  const openPro = () => {
    setWebModalVisible(false);
    setIsProVisible(true);
  };

  const closeModal = () => {
    setIsProVisible(false);
    setWebModalVisible(false);
  };

  const showLimitAlert = () => {
    Alert.alert(
      translate[language].tituloLimiteAlcanzado,
      translate[language].limiteAlcanzado,
      [
        { text: translate[language].verPro, onPress: openPro },
        {
          text: translate[language].cerrar,
          onPress: closeModal,
          style: "cancel",
        },
      ],
      { cancelable: true }
    );
  };

  /* ----------------------------------------------
     Activar mail en el servidor
     ---------------------------------------------- */
  const activateMail = async (onSuccess) => {
    try {
      const response = await fetch(`${serverAddress}/api/v1/perfils/actuacions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          actuacio: "GN",
          notif_email: 1,
          notif_push: userData?.data[0]?.notif_push === 1 ? 1 : 0,
        }),
      });
      if (!response.ok) {
        console.error("Error al activar correo en el servidor");
        return;
      }
      await refreshUserData();
      if (onSuccess) onSuccess();
    } catch (error) {
      console.error("Error al activar correo:", error);
    }
  };

  /* ----------------------------------------------
     Manejo de notificaciones push
     ---------------------------------------------- */
  const enablePushNotifications = async () => {
    if (!Device.isDevice) {
      // Web o emulador
      return false;
    }

    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;

    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }

    if (finalStatus !== "granted") {
      Alert.alert(
        translate[language].pushPermissionTitle,
        translate[language].pushPermissionDenied,
        [
          {
            text: "OK",
            onPress: () => {
              if (Platform.OS === "ios") {
                Linking.openURL("app-settings:");
              } else {
                Linking.openSettings();
              }
            },
          },
        ]
      );
      return false;
    }

    try {
      const projectId = "097c2eab-348a-4f63-bcf5-de3978115ad3";
      const pushToken = (
        await Notifications.getExpoPushTokenAsync({ projectId })
      ).data;
      // Envío al servidor
      const response = await fetch(`${serverAddress}/api/v1/perfils/actuacions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          actuacio: "GTP",
          expoPushToken: pushToken,
        }),
      });

      if (!response.ok) {
        throw new Error(translate[language].pushError);
      }

      Alert.alert("", translate[language].pushSuccess);
      return true;
    } catch (error) {
      Alert.alert(
        "Error",
        translate[language].pushPermissionError + " " + error.message
      );
      return false;
    }
  };

  /* ----------------------------------------------
     Lógica principal de validaciones
     ---------------------------------------------- */
  const checkOfferLimit = async () => {
    // Vibración en iOS/Android
    try {
      if (Platform.OS !== "web") {
        await Haptic.impactAsync(Haptic.ImpactFeedbackStyle.Heavy);
      }
    } catch (error) {
      // console.log("Haptic no disponible", error);
    }

    // 1) ¿Límite de ofertas superado?
    if (selectedOption === "ofrece") {
      const activeOffers = userData?.data[0]?.mevesOfertes?.filter(
        (offer) => offer.situacioOferta === "A"
      ).length;
      const maxAllowedOffers = userData?.data[0]?.maxOfertes_Permesses;
      if (activeOffers >= maxAllowedOffers) {
        if (Platform.OS === "web") {
          setWebModalVisible(true);
        } else {
          showLimitAlert();
        }
        return;
      }
    }

    // 2) Revisar correo/push
    const mailEnabled = userData?.data[0]?.notif_email === 1;
    let pushEnabled = false;

    if (Platform.OS !== "web") {
      const { status } = await Notifications.getPermissionsAsync();
      pushEnabled = status === "granted";
    }

    // En WEB:
    if (Platform.OS === "web") {
      // Si no tiene mail => mostrar el modal "mail inactivo"
      if (!mailEnabled) {
        setShowWebMailAlert(true);
        return;
      } else {
        // Mail activo => adelante
        onPress();
        return;
      }
    }

    // iOS/Android:
    // A) mail y push off
    if (!mailEnabled && !pushEnabled) {
      Alert.alert(
        translate[language].mailAndPushOffTitle,
        translate[language].mailAndPushOffMessage,
        [
          {
            text: translate[language].mailAndPushOffActivate,
            onPress: async () => {
              await activateMail(() => {
                // Sólo si activa el correo, dejamos pasar
                onPress();
              });
            },
          },
          {
            text: translate[language].mailAndPushOffCancel,
            onPress: () => {},
            style: "cancel",
          },
        ]
      );
      return;
    }

    // B) mail on, push off
    if (mailEnabled && !pushEnabled) {
      Alert.alert(
        translate[language].pushOffMailOnTitle,
        translate[language].pushOffMailOnMessage,
        [
          {
            text: translate[language].pushOffMailOnActivate,
            onPress: async () => {
              await enablePushNotifications();
              // Permitir seguir aunque no se active
              onPress();
            },
          },
          {
            text: translate[language].pushOffMailOnSkip,
            onPress: () => {
              onPress();
            },
            style: "cancel",
          },
        ]
      );
      return;
    }

    // C) mail off, push on
    if (!mailEnabled && pushEnabled) {
      Alert.alert(
        translate[language].mailOffPushOnTitle,
        translate[language].mailOffPushOnMessage,
        [
          {
            text: translate[language].mailOffPushOnActivate,
            onPress: async () => {
              await activateMail(() => {
                onPress();
              });
            },
          },
          {
            text: translate[language].mailOffPushOnSkip,
            onPress: () => {
              // Permitir seguir sin mail
              onPress();
            },
            style: "cancel",
          },
        ]
      );
      return;
    }

    // D) ambos activos => adelante
    onPress();
  };

  /* -------------------------------------------------------------------
     COMPONENTE: MODAL LÍMITE EN WEB
     ------------------------------------------------------------------- */
  const WebLimitModal = () => (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isWebModalVisible}
      onRequestClose={closeModal}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <Text style={styles.modalTitle}>
            {translate[language].tituloLimiteAlcanzado}
          </Text>
          <Text style={styles.modalMessage}>
            {translate[language].limiteAlcanzado}
          </Text>
          <View style={styles.buttonContainer}>
            <TouchableOpacity
              style={[styles.button, { backgroundColor: "#dc3545" }]}
              onPress={closeModal}
            >
              <Text style={styles.buttonText}>
                {translate[language].cerrar}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.button, { backgroundColor: "#28a745" }]}
              onPress={openPro}
            >
              <Text style={styles.buttonText}>
                {translate[language].verPro}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );

  /* -------------------------------------------------------------------
     COMPONENTE: MODAL MAIL INACTIVO EN WEB
     ------------------------------------------------------------------- */
  const WebMailModal = () => (
    <Modal
      animationType="fade"
      transparent={true}
      visible={showWebMailAlert}
      onRequestClose={() => setShowWebMailAlert(false)}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <Text style={styles.modalTitle}>
            {translate[language].mailWebAlertTitle}
          </Text>
          <Text style={styles.modalMessage}>
            {translate[language].mailWebAlertMessage}
          </Text>

          <View style={styles.buttonContainer}>
            <TouchableOpacity
              style={[styles.button, { backgroundColor: "#28a745" }]}
              onPress={async () => {
                // Activo mail
                await activateMail(() => {
                  setShowWebMailAlert(false);
                  onPress();
                });
              }}
            >
              <Text style={styles.buttonText}>
                {translate[language].mailWebAlertBtnActivate}
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={[styles.button, { backgroundColor: "#dc3545" }]}
              onPress={() => {
                // Cerrar sin continuar
                setShowWebMailAlert(false);
              }}
            >
              <Text style={styles.buttonText}>
                {translate[language].mailWebAlertBtnCancel}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );

  /* -------------------------------------------------------------------
     COMPONENTE: MODAL PRO
     ------------------------------------------------------------------- */
  const ProModal = () => (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isProVisible}
      onRequestClose={closeModal}
    >
      <TouchableOpacity
        style={{ flex: 1 }}
        onPress={closeModal}
        activeOpacity={1}
      >
        <Pro closeModal={closeModal} />
      </TouchableOpacity>
    </Modal>
  );

  /* ----------------------------------------------
     RENDER
     ---------------------------------------------- */
  return (
    <>
      <Animated.View
        style={[
          styles.frame,
          { opacity: fadeAnim, transform: [{ scale: scaleAnim }] },
        ]}
      >
        <TouchableOpacity
          onPress={checkOfferLimit}
          activeOpacity={0.8}
          accessible={true}
          accessibilityLabel={translate[language][selectedOption]}
        >
          {/* Borde azul externo */}
          <View style={styles.wrapperContainer}>
            {/* Borde blanco */}
            <View style={styles.outerWrapper}>
              {/* Fondo azul interno */}
              <Animated.View style={styles.div}>
                <View style={styles.textContainer}>
                  <Text style={styles.textWrapper}>
                    {splitText(translate[language][selectedOption]).main}
                  </Text>
                  {splitText(translate[language][selectedOption]).example !==
                    "" && (
                    <Text style={styles.subtitle}>
                      {splitText(translate[language][selectedOption]).example}
                    </Text>
                  )}
                </View>
                <Animated.Image
                  source={addImage}
                  style={[
                    styles.add,
                    {
                      transform: [
                        { scale: bounceAnim },
                        { rotate: rotateInterpolate },
                      ],
                    },
                  ]}
                />
              </Animated.View>
            </View>
          </View>
        </TouchableOpacity>
      </Animated.View>

      {/* Modales para web (límite y mail) */}
      {Platform.OS === "web" && <WebLimitModal />}
      {Platform.OS === "web" && <WebMailModal />}

      {/* Modal PRO para todas las plataformas */}
      <ProModal />
    </>
  );
};

/* -------------------------------------------------------------------
   ESTILOS
   ------------------------------------------------------------------- */
const styles = StyleSheet.create({
  frame: {
    width: 340,
    height: 67,
    marginBottom: 20,
    marginTop: 10,
    alignSelf: "center",
  },
  div: {
    borderRadius: 18,
    backgroundColor: Colors.primary,
    height: 67,
    width: 327,
    position: "relative",
    alignItems: "center",
    flexDirection: "row",
    paddingHorizontal: 20,
    borderWidth: 4,
    borderColor: Colors.primary,
    overflow: "hidden",
  },
  outerWrapper: {
    backgroundColor: Colors.white,
    borderRadius: 22,
    padding: 5,
  },
  wrapperContainer: {
    backgroundColor: Colors.primary,
    borderRadius: 24,
    padding: 2,
  },

  textContainer: {
    flex: 1,
    justifyContent: "center",
    paddingRight: 50, // espacio para el icono
  },
  textWrapper: {
    color: Colors.white,
    ...Fonts.poppinsMedium16,
    lineHeight: 20,
    fontWeight: "600",
  },
  subtitle: {
    fontSize: 12,
    lineHeight: 16,
    color: Colors.white,
    marginTop: 2,
    opacity: 0.8,
  },
  add: {
    height: 34,
    width: 34,
    position: "absolute",
    right: 15,
  },
  /* --------- Modal genérico --------- */
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  modalView: {
    backgroundColor: Colors.white,
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    width: "80%",
    maxHeight: "80%",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 15,
    color: Colors.primary,
    textAlign: "center",
  },
  modalMessage: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 20,
    color: Colors.grayDark,
    whiteSpace: "pre-line", // Para que respete saltos de línea en web
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
  },
  button: {
    borderRadius: 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
    elevation: 2,
    marginHorizontal: 5,
    flex: 1,
  },
  buttonText: {
    color: Colors.white,
    fontWeight: "bold",
    textAlign: "center",
    ...Fonts.poppinsMedium14,
  },
});

export default FirstOffer;
