import React, { useContext } from "react";
import { View, Text, StyleSheet, Platform } from 'react-native';
import { Colors, Fonts } from '../styles/styles';
import { AppContext } from '../../AppContext';
import PropTypes from 'prop-types';
import { Ionicons } from '@expo/vector-icons'; // Asegúrate de tener react-native-vector-icons instalado

const messages = {
  ESP: {
    mensajes: "¡Vaya! Parece que aún no tienes ningún mensaje.",
    notificaciones: "Sin notificaciones nuevas por el momento.",
    favoritos: "¡Aún no has añadido a nadie a tus favoritos!",
    ofertas: "Este usuario aún no ha publicado ofertas.",
    necesidades: "Este usuario aún no ha publicado demandas.",
    valoraciones: "Este usuario aún no tiene valoraciones."
  },
  CAT: {
    mensajes: "Oh, sembla que encara no tens cap missatge.",
    notificaciones: "Sense notificacions noves per ara.",
    favoritos: "Encara no has afegit ningú als teus preferits!",
    ofertas: "Aquest usuari encara no ha publicat ofertes.",
    necesidades: "Aquest usuari encara no ha publicat demandes.",
    valoraciones: "Aquest usuari encara no té valoracions."
  },
  ENG: {
    mensajes: "Oops! It looks like you don't have any messages yet.",
    notificaciones: "No new notifications at the moment.",
    favoritos: "You haven't added anyone to your favorites yet!",
    ofertas: "This user hasn't published any offers yet.",
    necesidades: "This user hasn't published any needs yet.",
    valoraciones: "This user doesn't have any reviews yet."
  }
};

const EmptyContent = ({ contentType }) => {
  const { language } = useContext(AppContext);

  const getMessage = () => {
    return messages[language][contentType] || "";
  };

  const getIconName = () => {
    switch (contentType) {
      case 'mensajes':
        return 'chatbubble-ellipses-outline';
      case 'notificaciones':
        return 'notifications-off-outline';
      case 'favoritos':
        return 'heart-outline';
      case 'ofertas':
        return 'pricetags-outline';
      case 'necesidades':
        return 'list-outline';
      case 'valoraciones':
        return 'star-outline';
      default:
        return 'information-circle-outline';
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.card}>
        <Ionicons name={getIconName()} size={60} color={Colors.gray} style={styles.icon} />
        <Text style={styles.text}>{getMessage()}</Text>
      </View>
    </View>
  );
};

EmptyContent.propTypes = {
  contentType: PropTypes.oneOf(['mensajes', 'notificaciones', 'favoritos', 'ofertas', 'necesidades', 'valoraciones']).isRequired,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    marginTop: 14,
  },
  card: {
    backgroundColor: Colors.white,
    width: '100%',
    maxWidth: 350,
    borderRadius: 20,
    borderColor: Colors.grayLighter,
    borderWidth: 1,
    padding: 24,
    alignItems: 'center',
    ...Platform.select({
      ios: {
        shadowColor: 'rgba(0, 0, 0, 0.1)',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 1,
        shadowRadius: 6,
      },
      android: {
        elevation: 6,
      },
      default: {
        shadowColor: "rgba(0, 0, 0, 0.1)",
        shadowOffset: { width: 0, height: 4 },
        shadowRadius: 6,
        shadowOpacity: 1,
      },
    }),
  },
  icon: {
    marginBottom: 16,
  },
  text: {
    ...Fonts.poppinsRegular16,
    color: Colors.black,
    textAlign: 'center',
  },
});

export default EmptyContent;
