import React, { useState, useContext } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Modal,
  Platform,
} from "react-native";
import { Colors, Fonts } from "../styles/styles.js";
import * as Haptics from "expo-haptics";
import Pro from "../components/Pro.js";
import { AppContext } from "../../AppContext";
import NeedRegister from "../selectables/NeedRegister.js";

// Traducciones simplificadas para mostrar únicamente "Ofrecen" o "Demandan"
const translate = {
  ESP: {
    ofrece: "Ofrecen",
    necesita: "Demandan",
  },
  CAT: {
    ofrece: "Ofereixen",
    necesita: "Demanden",
  },
  ENG: {
    ofrece: "Offer",
    necesita: "Demand",
  },
};

const SelectorOfreceNecesita = ({ onSelect }) => {
  const [selectedButton, setSelectedButton] = useState("ofrece");
  const [proModalVisible, setProModalVisible] = useState(false);
  const { language, userData } = useContext(AppContext);
  const [isNeedRegisterVisible, setIsNeedRegisterVisible] = useState(false);

  const t = translate[language];

  const closeProModal = () => {
    setProModalVisible(false);
    if (selectedButton !== "ofrece") {
      setSelectedButton("ofrece");
      onSelect("ofrece");
    }
  };

  const renderNeedRegisterModal = () => {
    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={isNeedRegisterVisible}
        onRequestClose={() => setIsNeedRegisterVisible(false)}
      >
        <NeedRegister onClose={() => setIsNeedRegisterVisible(false)} />
      </Modal>
    );
  };

  const selectButton = async (button) => {
    setSelectedButton(button);
    // Vibración háptica solo en dispositivos nativos
    if (Platform.OS !== "web") {
      await Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy);
    }

    // Si el usuario es invitado y quiere "Demandar"
    if (button === "necesita" && userData?.data[0]?.tipusUsuari === "Guest") {
      setIsNeedRegisterVisible(true);
      return;
    }

    onSelect(button);
  };

  return (
    <View style={styles.groupParent}>
      {renderNeedRegisterModal()}
      {/* Modal Pro */}
      <Modal
        animationType="slide"
        transparent={true}
        visible={proModalVisible}
        onRequestClose={closeProModal}
      >
        <TouchableOpacity
          style={styles.modalOverlay}
          onPress={closeProModal}
          activeOpacity={1}
        >
          <Pro closeModal={closeProModal} />
        </TouchableOpacity>
      </Modal>

      {/* Botón "Ofrecen" */}
      <TouchableOpacity
        onPress={() => selectButton("ofrece")}
        style={[
          styles.button,
          selectedButton === "ofrece" ? styles.selected : styles.unselected,
        ]}
      >
        <Text
          style={[
            selectedButton === "ofrece"
              ? styles.selectedButtonText
              : styles.unselectedButtonText,
          ]}
        >
          {t.ofrece}
        </Text>
      </TouchableOpacity>

      {/* Botón "Demandan" */}
      <TouchableOpacity
        onPress={() => selectButton("necesita")}
        style={[
          styles.button,
          selectedButton === "necesita" ? styles.selected : styles.unselected,
        ]}
      >
        <Text
          style={[
            selectedButton === "necesita"
              ? styles.selectedButtonText
              : styles.unselectedButtonText,
          ]}
        >
          {t.necesita}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  groupParent: {
    flexDirection: "row",
    width: "100%",
    height: 70, // Incrementado para acomodar botones más grandes
    marginTop: 16,
    marginBottom: 14,
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 10, // Aumentado padding vertical
    paddingHorizontal: 18, // Aumentado padding horizontal
    borderRadius: 20, // Bordes suaves
    backgroundColor: Colors.primary,
    minWidth: 130, // Incrementado para botones más grandes
    height: 50, // Incrementada altura
    marginHorizontal: 12, // Aumentado margen entre botones
    ...Platform.select({
      ios: {
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.15,
        shadowRadius: 3,
      },
      android: {
        elevation: 4,
      },
      web: {
        boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
      },
    }),
  },
  selectedButtonText: {
    fontSize: 18, // Aumentado tamaño de fuente
    fontWeight: "600",
    color: Colors.white,
    lineHeight: 22, // Ajustado para mejor alineación
    textAlign: "center",
  },
  unselectedButtonText: {
    fontSize: 18, // Aumentado tamaño de fuente
    fontWeight: "400",
    color: Colors.grayMediumDark,
    lineHeight: 22, // Ajustado para mejor alineación
    textAlign: "center",
  },
  unselected: {
    backgroundColor: Colors.white,
    borderColor: Colors.grayLight,
    borderWidth: 1,
  },
  selected: {
    backgroundColor: Colors.primary,
    borderColor: Colors.primary,
    borderWidth: 2,
  },
  modalOverlay: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
});

export default SelectorOfreceNecesita;
