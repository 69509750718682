import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import {
  Text,
  View,
  Animated,
  ScrollView,
  StyleSheet,
  Dimensions,
  TouchableOpacity,
  RefreshControl,
  Vibration,
  Image,
  Platform,
  AppState,
  Linking,
  TextInput,
  Modal,
  Share,
  Easing,
  Alert,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Fonts, Colors } from "../styles/styles.js";
import * as StoreReview from "expo-store-review";
import Greeting from "../components/Greeting.js";
import Gamification from "../components/Gamification.js";
import CardSVertical from "../components/CardSVertical.js";
import { Buscar } from "../components/SearchBar.js";
import ServiciosObjetos from "../selectables/ServiciosObjetos.js";
import { AppContext } from "../../AppContext";
import { SocketContext } from "../../SocketContext.js";
import * as Haptic from "expo-haptics";
import Publi from "../components/Publi.js";
import * as SecureStore from "expo-secure-store";
import * as Notifications from "expo-notifications";
import NotificationPlatform from "../components/NotificationPlatfom";
import * as Device from "expo-device";
import SubscriptionOk from "../components/SubscriptionOk.js";
import * as Application from "expo-application";
import CustomModal from "./CustomModal.js";
import ShareButton from "../components/ShareButton.js";
import RegisterButton from "../components/RegisterButton.js";
import Popup from "../components/Popup.js";
import Colab from "../components/Colab.js";
import ProColab from "../components/Pro.js";
import { SafeAreaView } from "react-native";

// ===== NUEVOS imports para comportamiento de imágenes =====
import * as ImagePicker from "expo-image-picker";
import { manipulateAsync, SaveFormat } from "expo-image-manipulator";

// Ajustamos el texto en cada idioma (foto -> "Foto\nperfil"):
const translate = {
  ESP: {
    whatDoYouNeed: "¿Qué necesitas hoy?",
    seeMore: "Ver más",
    oferta: "Subir\noferta",
    demanda: "Subir\ndemanda",
    foto: "Foto\nperfil",
    updateAvailable: "📲 Actualización disponible",
    updateMessage:
      "Hay una nueva versión disponible. Por favor, actualiza la aplicación.",
    updateRequired: "Actualización requerida",
    updateRequiredMessage:
      "Para usar la aplicación se requiere una versión más nueva. Por favor, actualice la aplicación.",
    updateNow: "Actualizar ahora",
    close: "Cerrar",
    notificationsTitle: "¡No te pierdas nada importante!",
    notificationsMessage:
      "🔔 Activa las notificaciones para recibir mensajes y eventos importantes. ⚡️ ¡Sé el primero en saber las novedades locales!",
    enableNotifications: "Activar notificaciones",
    mailsTitle: "¡No te pierdas nada importante! 💌",
    mailsMessage:
      "Usamos el mail para comunicarte eventos importantes, por ejemplo cuando un vecino te envía mensaje o nuevos coincidentes.",
    enableMails: "Activar correos",
    helpTitle: "Necesitamos tu ayuda",
    helpMessage: "Si puedes apoyar el proyecto, nos iría genial tu donación.",
    donate: "Donar",
    verificationModalTitle: "Un último paso...",
    verificationModalMessage:
      "Ingresa el código de 4 dígitos que hemos enviado a tu correo {email}. Este paso es para garantizar tu seguridad. Si no recibes el código, revisa la ",
    spamMessage: "carpeta no deseados.",
    verificationCodePlaceholder: "Ingresa el código aquí",
    verificationButton: "Verificar Código",
    notReceivedQuery: "¿No has recibido el código?",
    resendCodeButton: "Obtener un nuevo código",
    notokcode: "Código incorrecto. Por favor, inténtalo de nuevo.",
    codesend: "Se ha enviado un correo con un nuevo código",
    restartButton: "Volver a empezar",
    shareTitle: "¿Quieres invitar a alguien? 😊",
    shareMessage:
      "Compartiendo la Red de Economía Vecinal, ayudas a que más personas se unan a la comunidad. 🚀",
    shareButtonText: "Compartir",
    shareCloseButtonText: "Cerrar",
    needAppTitle: "Descarga la App de Trocalia",
    needAppMessage: (os) =>
      `Estás conectado desde un navegador. Para una mejor experiencia, descarga la app desde ${
        os === "iOS" ? "Apple Store" : "Google Play"
      }.`,
    needAppButtonText: "Descargar",
    shareAppMessage:
      "Hola! Te recomiendo la Xarxa d’Economia Vecinal 📍 permite ofrecer y demandar Servicios y Objetos.\n👇\nhttps://trocalia.net/",
    servicios: "Servicios cercanos",
    objetos: "Objetos cercanos",
    ofertas: "Ofertas",
    demandas: "Demandas",
    // Texto de los pickers de cámara/galería:
    permissionsRequired:
      "Se requieren permisos para acceder a la cámara o la galería.",
    confoto: "Con foto tu perfil luce más 👌",
    camera: "Cámara",
    gallery: "Galería",
    cancel: "Cancelar",
  },
  CAT: {
    whatDoYouNeed: "Què necessites avui?",
    seeMore: "Veure'n més",
    oferta: "Pujar\noferta",
    demanda: "Pujar\ndemanda",
    foto: "Foto\nperfil",
    updateAvailable: "Actualització disponible",
    updateMessage:
      "Hi ha una nova versió disponible. Si us plau, actualitza l'aplicació.",
    updateRequired: "📲 Actualització requerida",
    updateRequiredMessage:
      "Per utilitzar l'aplicació es requereix una versió més nova. Si us plau, actualitza l'aplicació.",
    updateNow: "Actualitzar ara",
    close: "Tancar",
    notificationsTitle: "No et perdis res important!",
    notificationsMessage:
      "🔔 Activa les notificacions per rebre missatges i esdeveniments importants. ⚡️ Sigues el primer a conèixer les novetats locals!",
    enableNotifications: "Activar notificacions",
    mailsTitle: "No et perdis res important! 💌",
    mailsMessage:
      "Fem servir el correu per comunicar-te esdeveniments importants, per exemple quan un veí et fa arribar un missatge o nous coincidents.",
    enableMails: "Activar correus",
    helpTitle: "Necessitem la teva ajuda",
    helpMessage:
      "Si pots donar suport al projecte, ens aniria genial la teva donació.",
    donate: "Donar",
    verificationModalTitle: "Un últim pas...",
    verificationModalMessage:
      "Introdueix el codi de 4 dígits que hem enviat al teu correu {email}. Aquest pas és per garantir la teva seguretat. Si no reps el codi mira la ",
    spamMessage: "carpeta no desitjats.",
    verificationCodePlaceholder: "Introdueix el codi aquí",
    verificationButton: "Verificar Codi",
    notReceivedQuery: "No has rebut el codi?",
    resendCodeButton: "Obtindre un nou codi",
    notokcode: "Codi incorrecte. Si us plau, intenta-ho de nou.",
    codesend: "S'ha enviat un correu amb un nou codi",
    restartButton: "Tornar a començar",
    shareTitle: "Vols convidar algú? 😊",
    shareMessage:
      "Compartint la Xarxa d’Economia Veïnal, ajudes a que més persones s’uneixin a la comunitat. 🚀",
    shareButtonText: "Compartir",
    shareCloseButtonText: "Tancar",
    needAppTitle: "Descarrega l'App de Trocalia",
    needAppMessage: (os) =>
      `Estàs connectat des d'un navegador. Per a una millor experiència, descarrega l'App des de ${
        os === "iOS" ? "Apple Store" : "Google Play"
      }.`,
    needAppButtonText: "Descarregar",
    shareAppMessage:
      "Hola! Et recomano la Xarxa d’Economia Veïnal 📍 permet oferir i demandar Serveis i Objectes.\n👇\nhttps://trocalia.net/",
    servicios: "Serveis propers",
    objetos: "Objectes propers",
    ofertas: "Ofertes",
    demandas: "Demandes",
    // Texto pickers
    permissionsRequired: "Calen permisos per accedir a la càmera o la galeria.",
    confoto: "Amb foto el teu perfil llueix millor 👌",
    camera: "Càmera",
    gallery: "Galeria",
    cancel: "Cancel·lar",
  },
  ENG: {
    whatDoYouNeed: "What do you need today?",
    seeMore: "See more",
    oferta: "Upload\noffer",
    demanda: "Upload\nneed",
    foto: "Photo\nprofile",
    updateAvailable: "📲 Update available",
    updateMessage: "A new version is available. Please update the app.",
    updateRequired: "Update required",
    updateRequiredMessage:
      "To use the app, a newer version is required. Please update the app.",
    updateNow: "Update now",
    close: "Close",
    notificationsTitle: "Don't miss anything important!",
    notificationsMessage:
      "🔔 Enable notifications to receive important messages and events. ⚡️ Be the first to know local news!",
    enableNotifications: "Enable notifications",
    mailsTitle: "Don't miss anything important! 💌",
    mailsMessage:
      "We use email to communicate important events, for example when a neighbor sends you a message or new matches.",
    enableMails: "Enable emails",
    helpTitle: "We need your help",
    helpMessage:
      "If you can support the project, your donation would be greatly appreciated.",
    donate: "Donate",
    verificationModalTitle: "One last step...",
    verificationModalMessage:
      "Enter the 4-digit code we sent to your email {email}. This step is to ensure your security. If you don't receive the code, check your ",
    spamMessage: "Spam folder",
    verificationCodePlaceholder: "Enter the code here",
    verificationButton: "Verify Code",
    notReceivedQuery: "Didn't receive the code?",
    resendCodeButton: "Get a new code",
    notokcode: "Incorrect code. Please try again.",
    codesend: "An email with a new code has been sent",
    restartButton: "Start Over",
    shareTitle: "Will you help us grow?",
    shareMessage:
      "If you know someone who might be interested, share the community.",
    shareButtonText: "Share",
    shareCloseButtonText: "Close",
    needAppTitle: "Enhance your experience",
    needAppMessage: (os) =>
      `You are connected from a browser. For a better experience, download the app from the ${
        os === "iOS" ? "Apple Store" : "Google Play"
      }.`,
    needAppButtonText: "Download",
    shareAppMessage:
      "Hi! I recommend the Local Economy Network 📍 to offer and request Services and Items.\n👇\nhttps://trocalia.net/",
    servicios: "Nearby Services",
    objetos: "Nearby Items",
    ofertas: "Offers",
    demandas: "Needs",
    // Texto pickers
    permissionsRequired:
      "Permissions are required to access the camera or gallery.",
    confoto: "With a photo your profile looks better 👌",
    camera: "Camera",
    gallery: "Gallery",
    cancel: "Cancel",
  },
};

// ==== Para LayoutAnimation en Android (descomenta si lo deseas) ====
// if (
//   Platform.OS === "android" &&
//   UIManager.setLayoutAnimationEnabledExperimental
// ) {
//   UIManager.setLayoutAnimationEnabledExperimental(true);
// }

const capitalizeFirstLetterOfSentence = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const HomeScreen = () => {
  const {
    isTokenLoading,
    token,
    updateUser,
    serverAddress,
    language,
    userData,
    miID,
    setToken,
    updateToken,
    needsRefresh,
    requestRefresh,
    refreshUserData,
  } = useContext(AppContext);
  const navigation = useNavigation();
  const isUserGuest =
  userData &&
  userData.data &&
  userData.data[0] &&
  userData.data[0].tipusUsuari === "Guest";

  const [panelsTitles, setPanelsTitles] = useState({
    panel1_esp: "",
    panel1_cat: "",
    panel1_ing: "",
    panel2_esp: "",
    panel2_cat: "",
    panel2_ing: "",
    panel3_esp: "",
    panel3_cat: "",
    panel3_ing: "",
    panel4_esp: "",
    panel4_cat: "",
    panel4_ing: "",
    panel5_esp: "",
    panel5_cat: "",
    panel5_ing: "",
    panel6_esp: "",
    panel6_cat: "",
    panel6_ing: "",
  });
  const [panelsData, setPanelsData] = useState({
    panel1: [],
    panel2: [],
    panel3: [],
    panel4: [],
    panel5: [],
    panel6: [],
  });
  const [isPanelsLoaded, setIsPanelsLoaded] = useState({
    panel2: false,
    panel3: false,
    panel4: false,
    panel5: false,
    panel6: false,
  });

  const panels = ["panel1", "panel5", "panel4", "panel2", "panel6", "panel3"];
  const [offsets, setOffsets] = useState({
    panel1: 0,
    panel2: 0,
    panel3: 0,
    panel4: 0,
    panel5: 0,
    panel6: 0,
  });
  const [isFetching, setIsFetching] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const lastUpdateRef = useRef(Date.now());

  const { isSubscriptionOkVisible, setIsSubscriptionOkVisible } =
    useContext(SocketContext);

  const appState = useRef(AppState.currentState);
  const lastBackgroundTime = useRef(null);

  const [modalVisible, setModalVisible] = useState(false);
  const [InfoFromServer, setInfoFromServer] = useState(null);
  const [qrModalVisible, setQrModalVisible] = useState(false);
  const [modalData, setModalData] = useState({
    title: "",
    message: "",
    actions: [],
  });

  const [isVerificationModalVisible, setIsVerificationModalVisible] =
    useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationError, setVerificationError] = useState("");
  const [shareButtonKey, setShareButtonKey] = useState(0);
  const [resendCodeSuccessMessage, setResendCodeSuccessMessage] = useState("");
  const [verificationEmail, setVerificationEmail] = useState("");
  const [initialTokenLoad, setInitialTokenLoad] = useState(true);
  const [popupVisible, setPopupVisible] = useState(false);
  const [modalMode, setModalMode] = useState("pro");
  const [popupType, setPopupType] = useState(null);
  const [isProColabModalVisible, setIsProColabModalVisible] = useState(false);
  const QR_MODAL_LAST_SHOWN_KEY = "qrModalLastShown";
  const EIGHT_HOURS_IN_MS = 8 * 60 * 60 * 1000;
  const FIVE_MINUTES_IN_MS = 5 * 60 * 1000;
  const [isAtTop, setIsAtTop] = useState(true);
  const adPositionRef = useRef({});
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  // Para el FAB (animaciones flotantes decorativas)
  const scaleAnim = useRef(new Animated.Value(1)).current;
  const rotateAnim = useRef(new Animated.Value(0)).current;
  const floatX = useRef(new Animated.Value(0)).current;
  const floatY = useRef(new Animated.Value(0)).current;
  const rotateAnimNew = useRef(new Animated.Value(0)).current;
  const scrollViewRef = useRef(null);
  // Valores animados para RegisterButton
const registerButtonOpacity = useRef(new Animated.Value(0)).current;
const registerButtonTranslateX = useRef(new Animated.Value(100)).current;

// Valores animados para ShareButton
const shareButtonOpacity = useRef(new Animated.Value(0)).current;
const shareButtonTranslateX = useRef(new Animated.Value(100)).current;


  const rotateInterpolate = rotateAnim.interpolate({
    inputRange: [-180, 0, 180],
    outputRange: ["-180deg", "0deg", "180deg"],
  });

  const [fabChoice, setFabChoice] = useState(null);
  const [base64Image, setBase64Image] = useState(null);

  // Animación horizontal (decoración)
  useEffect(() => {
    const animateX = () => {
      Animated.sequence([
        Animated.timing(floatX, {
          toValue: 10,
          duration: 6000,
          easing: Easing.inOut(Easing.ease),
          useNativeDriver: true,
        }),
        Animated.timing(floatX, {
          toValue: -10,
          duration: 6000,
          easing: Easing.inOut(Easing.ease),
          useNativeDriver: true,
        }),
      ]).start(() => {
        animateX();
      });
    };
    animateX();
    return () => {
      floatX.stopAnimation();
    };
  }, [floatX]);

  // Animación vertical (decoración)
  useEffect(() => {
    const animateY = () => {
      Animated.sequence([
        Animated.timing(floatY, {
          toValue: 10,
          duration: 4000,
          easing: Easing.inOut(Easing.ease),
          useNativeDriver: true,
        }),
        Animated.timing(floatY, {
          toValue: -10,
          duration: 4000,
          easing: Easing.inOut(Easing.ease),
          useNativeDriver: true,
        }),
      ]).start(() => {
        animateY();
      });
    };
    animateY();
    return () => {
      floatY.stopAnimation();
    };
  }, [floatY]);

  // Rotación continua (decoración)
  useEffect(() => {
    Animated.loop(
      Animated.timing(rotateAnimNew, {
        toValue: 1,
        duration: 8000,
        easing: Easing.linear,
        useNativeDriver: true,
      })
    ).start();
  }, [rotateAnimNew]);

  const rotateInterpolateNew = rotateAnimNew.interpolate({
    inputRange: [0, 1],
    outputRange: ["0deg", "360deg"],
  });

  const handleCloseQrModal = () => {
    setQrModalVisible(false);
  };

  const getLastQrModalShown = () => {
    try {
      const lastShown = localStorage.getItem(QR_MODAL_LAST_SHOWN_KEY);
      return lastShown ? parseInt(lastShown, 10) : null;
    } catch (error) {
      return null;
    }
  };

  const setLastQrModalShown = (timestamp) => {
    try {
      localStorage.setItem(QR_MODAL_LAST_SHOWN_KEY, timestamp.toString());
    } catch (error) {
      // do nothing
    }
  };
  const vibrateWithSound = async () => {
    try {
      if (Platform.OS !== "web") {
        await Haptic.impactAsync(Haptic.ImpactFeedbackStyle.Heavy);
      }
    } catch (error) {}
  };
  const resetPanelData = () => {
    setPanelsData({
      panel1: [],
      panel2: [],
      panel3: [],
      panel4: [],
      panel5: [],
      panel6: [],
    });
    setOffsets({
      panel1: 0,
      panel2: 0,
      panel3: 0,
      panel4: 0,
      panel5: 0,
      panel6: 0,
    });
  };
  const handleCloseSubscriptionOk = () => setIsSubscriptionOkVisible(false);
  const handlePressPublicitari = () => {
    Linking.openURL("https://trocalia.net/anunciants/");
  };

  const toggleProModal = () => {
    setIsProColabModalVisible(!isProColabModalVisible);
    setModalMode("pro");
  };
  const toggleColabModal = () => {
    setIsProColabModalVisible(!isProColabModalVisible);
    setModalMode("colab");
  };
  const handlePressPro = () => {
    setModalMode("pro");
    setPopupVisible(false);
    setIsProColabModalVisible(true);
  };
  const handlePressColab = () => {
    setModalMode("colab");
    setPopupVisible(false);
    setIsProColabModalVisible(true);
  };
  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  // ====== Título para panel:
  const getTitleForPanel = useCallback(
    (panelName, currentLanguage) => {
      let titleText;
      if (panelName === "panel1" || panelName === "panel4") {
        titleText = translate[currentLanguage].ofertas;
      } else if (panelName === "panel5" || panelName === "panel2") {
        titleText = translate[currentLanguage].demandas;
      } else {
        const titleKey =
          currentLanguage === "ENG"
            ? `${panelName}_ing`
            : `${panelName}_${currentLanguage.toLowerCase()}`;
        titleText =
          panelsTitles[titleKey] ||
          capitalizeFirstLetterOfSentence(panelName.replace("_", " "));
      }
  
      const isOfertes = titleText.includes(translate[currentLanguage].ofertas);
  
      return (
        <Text
          style={[
            styles.titleText,
            isOfertes ? styles.ofertesMargin : styles.defaultMargin,
          ]}
        >
          {titleText}
        </Text>
      );
    },
    [panelsTitles]
  );
  

  const handleLogout = async () => {
    setToken(null);
    if (Platform.OS === "web") {
      localStorage.removeItem("userToken");
    } else {
      await SecureStore.deleteItemAsync("userToken");
    }
    navigation.reset({
      index: 0,
      routes: [{ name: "Register" }],
    });
  };

  const fetchUserProfile = useCallback(async () => {
    if (!token) return;
    try {
      const profileResponse = await fetch(`${serverAddress}/api/v1/perfils`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const profileData = await profileResponse.json();

      if (profileResponse.status === 200 && profileData.data.status !== "NOK") {
        updateUser(profileData);
      } else if (
        profileData.data.status === "NOK" &&
        profileData.data.error === "NO_BD"
      ) {
        handleLogout();
      } else {
        updateUser(null);
      }
    } catch (error) {
      updateUser(null);
    }
  }, [token, serverAddress, updateUser, handleLogout]);

  // Para no recargar panel en paralelo
  const fetchingPanelsRef = useRef({
    panel1: false,
    panel2: false,
    panel3: false,
    panel4: false,
    panel5: false,
    panel6: false,
  });

  const fetchPanelData = useCallback(
    async (panelName, offsetParam) => {
      if (fetchingPanelsRef.current[panelName]) return;
      fetchingPanelsRef.current[panelName] = true;
      setIsFetching(true);

      try {
        const offset =
          offsetParam !== undefined ? offsetParam : offsets[panelName];

        const response = await fetch(
          `${serverAddress}/api/v1/perfils/meuspanels/${panelName}?offset=${offset}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) throw new Error("Error al cargar datos del panel");

        const data = await response.json();
        if (data.status === "OK" && data.data.length > 0) {
          setPanelsTitles((prevTitles) => ({
            ...prevTitles,
            [`${panelName}_esp`]: data.data[0][`${panelName}_esp`],
            [`${panelName}_cat`]: data.data[0][`${panelName}_cat`],
            [`${panelName}_ing`]: data.data[0][`${panelName}_ing`],
          }));

          setPanelsData((prevData) => ({
            ...prevData,
            [panelName]:
              offset === 0
                ? data.data[0][panelName]
                : [...prevData[panelName], ...data.data[0][panelName]],
          }));

          setOffsets((prevOffsets) => ({
            ...prevOffsets,
            [panelName]: offset + data.data[0][panelName].length,
          }));

          if (
            ["panel2", "panel3", "panel4", "panel5", "panel6"].includes(
              panelName
            )
          ) {
            setIsPanelsLoaded((prevLoaded) => ({
              ...prevLoaded,
              [panelName]: true,
            }));
          }

          // Determinar la posición del anuncio si es la carga inicial
          if (offset === 0 && adPositionRef.current[panelName] === undefined) {
            adPositionRef.current[panelName] = Math.random() < 0.5 ? 2 : 3;
          }
        }
      } catch (error) {
        // Manejo de errores si es necesario
      } finally {
        fetchingPanelsRef.current[panelName] = false;
        setIsFetching(false);
      }
    },
    [token, serverAddress, offsets, panelsTitles, panelsData]
  );

  const onRefresh = useCallback(async () => {
    vibrateWithSound();
    setRefreshing(true);

    setPanelsTitles({
      panel1_esp: "",
      panel1_cat: "",
      panel1_ing: "",
      panel2_esp: "",
      panel2_cat: "",
      panel2_ing: "",
      panel3_esp: "",
      panel3_cat: "",
      panel3_ing: "",
      panel4_esp: "",
      panel4_cat: "",
      panel4_ing: "",
      panel5_esp: "",
      panel5_cat: "",
      panel5_ing: "",
      panel6_esp: "",
      panel6_cat: "",
      panel6_ing: "",
    });
    resetPanelData();

    await fetchUserProfile();

    await fetchPanelData("panel1", 0);
    await fetchPanelData("panel5", 0);
    await fetchPanelData("panel4", 0);
    await fetchPanelData("panel2", 0);
    await fetchPanelData("panel6", 0);
    await fetchPanelData("panel3", 0);

    setShareButtonKey((prevKey) => prevKey + 1);
    setRefreshing(false);
  }, [fetchUserProfile, fetchPanelData]);

  // Notificaciones push
  async function registerForPushNotificationsAsync() {
    if (!Device.isDevice) return;
    if (Platform.OS === "web") return;

    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== "granted") return;

    try {
      const projectId = "097c2eab-348a-4f63-bcf5-de3978115ad3";
      const pushToken = (
        await Notifications.getExpoPushTokenAsync({ projectId })
      ).data;
      if ((Platform.OS === "android" || Platform.OS === "ios") && pushToken) {
        await sendPushTokenToServer(pushToken);
      }
    } catch (error) {
      // Manejo de errores si es necesario
    }
  }
  async function sendPushTokenToServer(pushToken) {
    if (!token) return;
    const url = `${serverAddress}/api/v1/perfils/actuacions`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          actuacio: "GTP",
          expoPushToken: pushToken,
        }),
      });
      if (!response.ok) {
        // Manejo de errores si es necesario
      }
    } catch (error) {
      // Manejo de errores si es necesario
    }
  }

  useEffect(() => {
    const isUserGuest =
      userData &&
      userData.data &&
      userData.data[0] &&
      userData.data[0].tipusUsuari === "Guest";

    if (isUserGuest) {
      setPopupType("1");
      setPopupVisible(true);
    }

    if (
      token &&
      !isUserGuest &&
      (Platform.OS === "android" || Platform.OS === "ios")
    ) {
      registerForPushNotificationsAsync();
    }
  }, [token, userData]);
  

  const requestReviewAndNotifyServer = async () => {
    const isAvailable = await StoreReview.isAvailableAsync();
    if (isAvailable) {
      await StoreReview.requestReview();
      await sendActionToServer("haValoratLaApp");
    }
  };

  async function sendDeviceInfoToServer() {
    if (
      !token ||
      (userData &&
        userData.data &&
        userData.data[0] &&
        userData.data[0].tipusUsuari === "Guest")
    ) {
      return;
    }    const deviceInfo = {
      deviceType: Device.deviceType,
      osName: Device.osName,
      appVersion: Application.nativeApplicationVersion,
    };
    const url = `${serverAddress}/api/v1/perfils/actuacions`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          actuacio: "deviceInfo",
          deviceInfo,
        }),
      });
      const responseData = await response.json();
      if (response.ok) {
        if (responseData.data.newToken) {
          updateToken(responseData.data.newToken);
        }
        handleServerResponse(responseData.data);
        setInfoFromServer(responseData.data);
      }
    } catch (error) {
      // Manejo de errores si es necesario
    }
  }

  const sendActionToServer = async (action) => {
    if (!token) return;
    try {
      await fetch(`${serverAddress}/api/v1/perfils/actuacions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          actuacio: action,
        }),
      }
    );

    if (response.ok) {
      const data = await response.json();
      // if needed, handle data
    }
  } catch (error) {
    //console.error(error);
  }
};

  const handleMailEnable = async () => {
    try {
      setModalVisible(false);
      const response = await fetch(
        `${serverAddress}/api/v1/perfils/actuacions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            actuacio: "GN",
            notif_email: 1,
            notif_push: userData?.data[0]?.notif_push === 1 ? 1 : 0,
          }),
        }
      );
      if (!response.ok) return;
      await refreshUserData();
    } catch (error) {
      // Manejo de errores si es necesario
    }
  };

  const handleServerResponse = async (data) => {
    const { status } = await Notifications.getPermissionsAsync();
    const notificationsEnabled = status === "granted";
    const mailEnabled = userData?.data?.[0]?.notif_email === 1;


    if (data.popup && [1, 2, 3, 4].includes(data.popup)) {
      setPopupType(data.popup);
      setPopupVisible(true);
    } else {
      setPopupVisible(false);
    }

    if (data.situacioUsuari === "P" && data.email) {
      setVerificationEmail(data.email);
      setIsVerificationModalVisible(true);
    } else if (
      data.updateRequired &&
      (Platform.OS === "android" || Platform.OS === "ios")
    ) {
      setModalData({
        title: translate[language].updateRequired,
        message: translate[language].updateRequiredMessage,
        actions: [
          {
            label: translate[language].updateNow,
            action: handleUpdate,
          },
        ],
      });
      setModalVisible(true);
    } else if (
      data.updateAvailable &&
      (Platform.OS === "android" || Platform.OS === "ios")
    ) {
      setModalData({
        title: translate[language].updateAvailable,
        message: translate[language].updateMessage,
        actions: [
          {
            label: translate[language].updateNow,
            action: handleUpdate,
          },
          {
            label: translate[language].close,
            action: handleCloseModal,
          },
        ],
      });
      setModalVisible(true);
    } else if (
      data.notifications &&
      !notificationsEnabled &&
      (Platform.OS === "android" || Platform.OS === "ios")
    ) {
      setModalData({
        title: translate[language].notificationsTitle,
        message: translate[language].notificationsMessage,
        actions: [
          {
            label: translate[language].enableNotifications,
            action: openSettings,
          },
          {
            label: translate[language].close,
            action: handleCloseModal,
          },
        ],
      });
      setModalVisible(true);
      await sendActionToServer("LiHemDemanatQueActiviLesNotis");
    } else if (data.notifications && !mailEnabled) {
      setModalData({
        title: translate[language].mailsTitle,
        message: translate[language].mailsMessage,
        actions: [
          {
            label: translate[language].enableMails,
            action: handleMailEnable,
          },
          {
            label: translate[language].close,
            action: handleCloseModal,
          },
        ],
      });
      setModalVisible(true);
      await sendActionToServer("LiHemDemanatQueActiviLesNotis");
    } else if (data.customMessage) {
      setModalData({
        title: data.customMessage.title[language],
        message: data.customMessage.message[language],
        actions: data.customMessage.actions.map((action) => ({
          label: action.label[language],
          action:
            action.action === "close"
              ? handleCloseModal
              : () => Linking.openURL(action.action),
        })),
      });
      setModalVisible(true);
      await sendActionToServer("LiHemEnviatCustomMessage");
    } else if (data.customMessage2) {
      setModalData({
        title: data.customMessage2.title[language],
        message: data.customMessage2.message[language],
        actions: data.customMessage2.actions.map((action) => ({
          label: action.label[language],
          action:
            action.action === "close"
              ? handleCloseModal
              : () => Linking.openURL(action.action),
        })),
      });
      setModalVisible(true);
      await sendActionToServer("LiHemEnviatCustomMessage2");
    } else if (
      data.rate &&
      (Platform.OS === "ios" || Platform.OS === "android")
    ) {
      setTimeout(async () => {
        if (await StoreReview.isAvailableAsync()) {
          await requestReviewAndNotifyServer();
        }
      }, 30000);
      await sendActionToServer("LiHemDemanatQueValoriLaApp");
    } else if (
      data.share &&
      (Platform.OS === "ios" || Platform.OS === "android")
    ) {
      setModalData({
        title: translate[language].shareTitle,
        message: translate[language].shareMessage,
        actions: [
          {
            label: translate[language].shareButtonText,
            action: handleShare,
          },
          {
            label: translate[language].shareCloseButtonText,
            action: handleCloseModal,
          },
        ],
      });
      setModalVisible(true);
      await sendActionToServer("LiHemDemanatQueComparteixiLaApp");
    } else if (data.needapp) {
      let os = detectOS();

      if (
        Platform.OS !== "web" &&
        (Device.osName === "iOS" || Device.osName === "Android")
      ) {
        os = Device.osName === "iOS" ? "iOS" : "Android";
      }

      if (Platform.OS === "web") {
        if (os === "desktop") {
          const lastShown = getLastQrModalShown();
          const now = Date.now();

          if (!lastShown || now - lastShown > EIGHT_HOURS_IN_MS) {
            setModalData({
              title: "",
              message: "",
              actions: [],
            });
            setQrModalVisible(true);
            setLastQrModalShown(now);
            await sendActionToServer("LiHemDemanatUtilitzarLaApp");
          }
        } else {
          const lastShown = getLastQrModalShown();
          const now = Date.now();

          if (!lastShown || now - lastShown > EIGHT_HOURS_IN_MS) {
            setModalData({
              title: translate[language].needAppTitle,
              message: translate[language].needAppMessage(os),
              actions: [
                {
                  label: translate[language].needAppButtonText,
                  action: openAppOrRedirect,
                },
                {
                  label: translate[language].close,
                  action: handleCloseModal,
                },
              ],
            });
            setModalVisible(true);
            setLastQrModalShown(now);
            await sendActionToServer("LiHemDemanatUtilitzarLaApp");
          }
        }
      }
    }
  };

  const detectOS = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad/.test(userAgent) && !window.MSStream) return "iPad";
    else if (/iPhone|iPod/.test(userAgent) && !window.MSStream) return "iOS";
    else if (/android/i.test(userAgent)) return "Android";
    else return "desktop";
  };

  const handleShare = async () => {
    const shareMessage = translate[language].shareAppMessage;
    try {
      const result = await Share.share({ message: shareMessage });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
        } else {
        }
        await sendActionToServer("haCompartitLaApp");
      } else if (result.action === Share.dismissedAction) {
      }
    } catch (error) {
      // Manejo de errores si es necesario
    }
  };

  const openAppOrRedirect = () => {
    const appStoreUrl = "https://apps.apple.com/es/app/trocalia/id6478570314";
    const playStoreUrl =
      "https://play.google.com/store/apps/details?id=com.trocalia";
    if (detectOS() === "iOS") {
      Linking.openURL(appStoreUrl);
    } else if (detectOS() === "Android") {
      Linking.openURL(playStoreUrl);
    }
  };

  const openSettings = () => {
    if (Platform.OS === "ios") {
      Linking.openURL("app-settings:");
    } else {
      Linking.openSettings();
    }
  };

  const handleUpdate = () => {
    const url =
      Platform.OS === "android"
        ? "https://play.google.com/store/apps/details?id=com.trocalia"
        : "https://apps.apple.com/es/app/trocalia/id6478570314";
    Linking.openURL(url);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  // Verificación (email)
  const handleCodeSubmit = async () => {
    try {
      const response = await fetch(
        `${serverAddress}/api/v1/perfils/actuacions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            actuacio: "VC",
            email: verificationEmail,
            codi: verificationCode,
          }),
        }
      );
      const data = await response.json();
      if (data.status === "OK" && data.data === "OK") {
        setIsVerificationModalVisible(false);
      } else {
        setVerificationError(translate[language].notokcode);
      }
    } catch (error) {
      setVerificationError("Hubo un problema al verificar el código.");
    }
  };
  const handleResendCode = async () => {
    try {
      const response = await fetch(
        `${serverAddress}/api/v1/perfils/actuacions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            actuacio: "CV",
            email: verificationEmail,
          }),
        }
      );
      const data = await response.json();
      if (data.status === "OK" && data.data === "OK") {
        setResendCodeSuccessMessage(translate[language].codesend);
      }
    } catch (error) {
      // Manejo de errores si es necesario
    }
  };

  // =============== LÓGICA SCROLL: MOSTRAR/OCULTAR "ServiciosObjetos" ===============
  const [scrollDirection, setScrollDirection] = useState("up");
  const [lastScrollOffset, setLastScrollOffset] = useState(0);

  const stickyTranslateY = useRef(new Animated.Value(-120)).current;
  const stickyOpacity = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (isSearchFocused) {
      // Si el usuario está escribiendo en el buscador,
      // forzamos que se MUESTRE el componente "ServiciosObjetos"
      Animated.parallel([
        Animated.timing(stickyTranslateY, {
          toValue: 0,
          duration: 250,
          useNativeDriver: true,
        }),
        Animated.timing(stickyOpacity, {
          toValue: 1,
          duration: 250,
          useNativeDriver: true,
        }),
      ]).start();
    } else {
      // Si NO está en foco el buscador, aplicamos tu lógica anterior:
      if (isAtTop) {
        // Ocultar
        Animated.parallel([
          Animated.timing(stickyTranslateY, {
            toValue: -120,
            duration: 250,
            useNativeDriver: true,
          }),
          Animated.timing(stickyOpacity, {
            toValue: 0,
            duration: 250,
            useNativeDriver: true,
          }),
        ]).start();
      } else {
        // estamos algo desplazados
        if (scrollDirection === "down") {
          // Mostrar
          Animated.parallel([
            Animated.timing(stickyTranslateY, {
              toValue: 0,
              duration: 250,
              useNativeDriver: true,
            }),
            Animated.timing(stickyOpacity, {
              toValue: 1,
              duration: 250,
              useNativeDriver: true,
            }),
          ]).start();
        } else {
          // Ocultar
          Animated.parallel([
            Animated.timing(stickyTranslateY, {
              toValue: -120,
              duration: 250,
              useNativeDriver: true,
            }),
            Animated.timing(stickyOpacity, {
              toValue: 0,
              duration: 250,
              useNativeDriver: true,
            }),
          ]).start();
        }
      }
    }
  }, [isSearchFocused, isAtTop, scrollDirection, stickyTranslateY, stickyOpacity]);

  const handleScroll = (e) => {
    const currentOffset = e.nativeEvent.contentOffset.y;
    const diff = currentOffset - lastScrollOffset;

    if (diff > 3) {
      // El usuario está desplazándose hacia abajo => ocultar ServiciosObjetos
      setScrollDirection("up");
    } else if (diff < -120) {
      // El usuario está desplazándose hacia arriba => mostrar ServiciosObjetos
      setScrollDirection("down");
    }

    // Verificar si está en la parte superior
    const threshold = 50; // Puedes ajustar este valor según tus necesidades
    if (currentOffset <= threshold) {
      setIsAtTop(true);
    } else {
      setIsAtTop(false);
    }

    setLastScrollOffset(currentOffset);
  };

  // Cargar datos iniciales
  useEffect(() => {
    if (token !== null && !initialTokenLoad) {
      onRefresh();
    } else if (token !== null && initialTokenLoad) {
      setInitialTokenLoad(false);
    }
  }, [token]);

  useEffect(() => {
    if (needsRefresh) {
      onRefresh().then(() => {
        requestRefresh(false);
      });
    }
  }, [needsRefresh]);

  useEffect(() => {
    if (
      token &&
      userData &&
      userData.data &&
      userData.data[0] &&
      userData.data[0].tipusUsuari !== "Guest"
    ) {
      sendDeviceInfoToServer();
    }
  }, [token, userData]);

  useEffect(() => {
    const isUserGuest =
      userData &&
      userData.data &&
      userData.data[0] &&
      userData.data[0].tipusUsuari === "Guest";

    if (
      token &&
      !isUserGuest &&
      (Platform.OS === "android" || Platform.OS === "ios")
    ) {
      registerForPushNotificationsAsync();
    }
  }, [token, userData]);

  useEffect(() => {
    const fetchData = async () => {
      if (!initialDataLoaded && token) {
        await fetchUserProfile();
        await fetchPanelData("panel1", 0);
        await fetchPanelData("panel5", 0);
        await fetchPanelData("panel4", 0);
        await fetchPanelData("panel2", 0);
        await fetchPanelData("panel6", 0);
        await fetchPanelData("panel3", 0);
        setInitialDataLoaded(true);
      }
    };
    fetchData();
  }, [token, initialDataLoaded]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isUserGuest) {
        // Cuando se muestra RegisterButton
        animateRegisterButton(1, 0); // Aparece
        // Ocultar ShareButton si está visible
        animateShareButton(0, 100); // Desaparece
      } else {
        // Cuando se muestra ShareButton
        animateShareButton(1, 0); // Aparece
        // Ocultar RegisterButton si está visible
        animateRegisterButton(0, 100); // Desaparece
      }
    }, 2000); // Retraso de 2 segundos
  
    return () => clearTimeout(timeout); // Limpia el timeout si el componente se desmonta
  }, [isUserGuest]);
  
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!isUserGuest) {
        // Solo ShareButton se ve afectado por nocomptador
        if (InfoFromServer?.nocomptador === true) {
          animateShareButton(1, 0);
        } else {
          animateShareButton(1, 0);
        }
      }
    }, 2000); // Retraso de 2 segundos
  
    return () => clearTimeout(timeout); // Limpia el timeout si el componente se desmonta
  }, [InfoFromServer?.nocomptador, isUserGuest]);
  


  useEffect(() => {
    const now = Date.now();
    if (now - lastUpdateRef.current >= 5000) {
      lastUpdateRef.current = now;
      fetchUserProfile();
    }
  }, [userData, updateUser]);

  useEffect(() => {
    const handleAppStateChange = (nextAppState) => {
      if (
        appState.current.match(/inactive|background/) &&
        nextAppState === "active"
      ) {
        const now = Date.now();
        if (
          lastBackgroundTime.current &&
          now - lastBackgroundTime.current > FIVE_MINUTES_IN_MS
        ) {
          onRefresh();
        }
      }
      if (nextAppState === "background") {
        lastBackgroundTime.current = Date.now();
      }
      appState.current = nextAppState;
    };

    const subscription = AppState.addEventListener(
      "change",
      handleAppStateChange
    );

    return () => {
      subscription.remove();
    };
  }, [onRefresh]);

  // FAB: determina si subir oferta/demanda/foto
  useEffect(() => {
    if (!userData || !userData.data || userData.data.length === 0) {
      setFabChoice(null);
      return;
    }
    const isUserGuest = userData.data[0].tipusUsuari === "Guest";
    if (isUserGuest) {
      setFabChoice(null);
      return;
    }
    const hasOfertes =
      userData.data[0].numOfertes !== null &&
      userData.data[0].numOfertes !== "0";
    const hasDemandes =
      userData.data[0].numDemandes !== null &&
      userData.data[0].numDemandes !== "0";
    const hasFoto =
      userData.data[0].foto !== null && userData.data[0].foto !== "";

    const faltaOfertesYDemandes = !hasOfertes && !hasDemandes;
    const faltaFoto = !hasFoto;

    if (faltaOfertesYDemandes && faltaFoto) {
      const r = Math.random();
      setFabChoice(r < 0.5 ? "oferta" : "foto");
    } else if (faltaOfertesYDemandes && !faltaFoto) {
      setFabChoice("oferta");
    } else if (!faltaOfertesYDemandes && faltaFoto) {
      setFabChoice("foto");
    } else if (hasOfertes && !hasDemandes) {
      setFabChoice("demanda");
    } else if (!hasOfertes && hasDemandes) {
      setFabChoice("oferta");
    } else {
      setFabChoice(null);
    }
  }, [userData]);

  // Manejo de imagen
  const t = translate[language];
  const requestPermissions = async () => {
    if (Platform.OS !== "web") {
      const cameraStatus = await ImagePicker.requestCameraPermissionsAsync();
      const libraryStatus =
        await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (
        cameraStatus.status !== "granted" ||
        libraryStatus.status !== "granted"
      ) {
        Alert.alert(t.permissionsRequired, t.confoto);
        return false;
      }
    }
    return true;
  };
  const resizeAndCompressImage = async (uri) => {
    const manipResult = await manipulateAsync(
      uri,
      [{ resize: { width: 500 } }],
      { compress: 0.5, format: SaveFormat.JPEG }
    );
    return manipResult.uri;
  };
  const handleImageResult = async (result) => {
    if (!result.canceled && result.assets) {
      const resizedImageUri = await resizeAndCompressImage(
        result.assets[0].uri
      );
      try {
        const imageData = await fetch(resizedImageUri);
        const blob = await imageData.blob();
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64 = reader.result.split(",")[1];
          setBase64Image(base64);
        };
      } catch (err) {
        // Manejo de errores si es necesario
      }
    }
  };
  const uploadToServer = async (theBase64) => {
    if (!theBase64 || !token) return;
    try {
      const response = await fetch(
        `${serverAddress}/api/v1/perfils/actuacions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ actuacio: "GF", foto: theBase64 }),
        }
      );
      const responseText = await response.text();
      let responseData;
      try {
        responseData = responseText ? JSON.parse(responseText) : null;
      } catch (jsonError) {
        return;
      }
      if (response.ok) {
        if (responseData && responseData.foto) {
          updateUserDataField("foto", responseData.foto);
        }
        await refreshUserData();
      }
    } catch (error) {
      // Manejo de errores si es necesario
    }
  };
  const updateUserDataField = (fieldName, newValue) => {
    if (!userData || !userData.data || userData.data.length === 0) return;    const updatedUserData = { ...userData };
    updatedUserData.data[0][fieldName] = newValue;
    updateUser(updatedUserData);
  };
  useEffect(() => {
    if (base64Image) {
      uploadToServer(base64Image);
    }
  }, [base64Image]);
  const showImagePickerOptions = () => {
    if (Platform.OS === "web") {
      pickImage();
    } else {
      Alert.alert(
        t.permissionsRequired,
        t.confoto,
        [
          { text: t.camera, onPress: () => takePhoto() },
          { text: t.gallery, onPress: () => pickImage() },
          { text: t.cancel, style: "cancel" },
        ],
        { cancelable: true }
      );
    }
  };
  const takePhoto = async () => {
    const allowed = await requestPermissions();
    if (!allowed) return;
    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 0.5,
    });
    handleImageResult(result);
  };
  const pickImage = async () => {
    const allowed = await requestPermissions();
    if (!allowed) return;
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 0.5,
    });
    handleImageResult(result);
  };

  // Función para animar RegisterButton
const animateRegisterButton = (toValueOpacity, toValueTranslateX) => {
  Animated.parallel([
    Animated.timing(registerButtonOpacity, {
      toValue: toValueOpacity,
      duration: 500,
      useNativeDriver: true,
    }),
    Animated.timing(registerButtonTranslateX, {
      toValue: toValueTranslateX,
      duration: 500,
      useNativeDriver: true,
    }),
  ]).start();
};

// Función para animar ShareButton
const animateShareButton = (toValueOpacity, toValueTranslateX) => {
  Animated.parallel([
    Animated.timing(shareButtonOpacity, {
      toValue: toValueOpacity,
      duration: 500,
      useNativeDriver: true,
    }),
    Animated.timing(shareButtonTranslateX, {
      toValue: toValueTranslateX,
      duration: 500,
      useNativeDriver: true,
    }),
  ]).start();
};

  // FAB press
  const navigateToProfileWithAction = () => {
    const routeName = language === "ENG" ? "Profile" : "Perfil";
    let actionType = "";
    if (userData && userData.data && userData.data.length > 0) {
      const hasOfertes =
        userData.data[0].numOfertes !== null &&
        userData.data[0].numOfertes !== "0";
        const hasDemandes =
        userData.data[0].numDemandes !== null &&
        userData.data[0].numDemandes !== "0";
      if (!hasOfertes && !hasDemandes) {
        actionType = "ofrece";
      } else if (hasOfertes && !hasDemandes) {
        actionType = "necesita";
      } else if (!hasDemandes && hasOfertes) {
        actionType = "ofrece";
      }
    }
    navigation.navigate(routeName, {
      selectedOption: actionType,
      openOfferModal: true,
    });
  };
  let fabText = null;
  let fabEmoji = "⚡";
  if (fabChoice) {
    if (fabChoice === "oferta") {
      fabText = translate[language].oferta;
      fabEmoji = "⚡";
    } else if (fabChoice === "demanda") {
      fabText = translate[language].demanda;
      fabEmoji = "⚡";
    } else if (fabChoice === "foto") {
      fabText = translate[language].foto;
      fabEmoji = "📷";
    }
  }
  const handleFabPress = () => {
    if (!fabChoice) return;
    if (fabChoice === "oferta" || fabChoice === "demanda") {
      navigateToProfileWithAction();
    } else if (fabChoice === "foto") {
      showImagePickerOptions();
    }
  };


const isUserPremium =
  userData &&
  userData.data &&
  userData.data[0] &&
  userData.data[0].tipusUsuari === "Premium";
  const insertAdsInData = (data, panelKey) => {
    const newData = [];
    const adPosition = adPositionRef.current[panelKey] || 2; // Por defecto después del segundo

    data.forEach((item, index) => {
      newData.push(
        <CardSVertical
          key={`${panelKey}-item-${index}`}
          data={item}
          showTimeText={panelKey !== "panel6"}
        />
      );

      // Insertar anuncio después de la posición especificada
      if (
        index + 1 === adPosition ||
        (index + 1 > adPosition && (index + 1 - adPosition) % 10 === 0)
      ) {
        newData.push(
          <Publi key={`publi-${panelKey}-${index}`} formatAnunci="Q" />
        );
      }
    });

    return newData;
  };

  // === Sticky headers para "Servicios" y "Objetos" en una sola línea con Id_zona, bajados un poco
  // Orden de hijos en ScrollView:
  //   0 Greeting
  //   1 Gamification
  //   2 Buscar
  //   3 ShareButton
  //   4 StickyHeader "Servicios cercanos"
  //   5 Paneles de servicios
  //   6 StickyHeader "Objetos cercanos"
  //   7 Paneles de objetos
  //   8 Resto de paneles
  // stickyHeaderIndices={[4, 6]}

  return (
    <SafeAreaView style={styles.safeArea}>
      <View style={{ flex: 1, backgroundColor: Colors.white }}>
        {popupVisible && (
          <Popup
            visible={popupVisible}
            popupType={popupType}
            onClose={handleClosePopup}
            onPressPro={handlePressPro}
            onPressColab={handlePressColab}
            onPressPublicitari={handlePressPublicitari}
            onPressCompartir={handleShare}
            sendActionToServer={sendActionToServer}
          />
        )}

        <NotificationPlatform />

        {/* 1. ServiciosObjetos fuera del ScrollView */}
        <Animated.View
          style={[
            styles.serviciosObjetosContainer,
            {
              transform: [{ translateY: stickyTranslateY }],
              opacity: stickyOpacity,
            },
          ]}
        >
          <ServiciosObjetos />
        </Animated.View>

        <Animated.ScrollView
        ref={scrollViewRef}
        contentContainerStyle={{
          paddingTop: Platform.OS === "ios" ? 6 : 24,
          paddingBottom: 64,
        }}
          stickyHeaderIndices={[4, 6]} // Asegura que estos índices correspondan con los headers
          onScroll={handleScroll}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }
          keyboardShouldPersistTaps="handled"
  keyboardDismissMode="on-drag"
        >
          {/* 0 */}
          <Greeting />

          {/* 1 */}
          <Gamification />

          {/* 2 */}
          <View style={styles.centerContainer}>
          <Buscar
  onFocusChange={(focused) => {
    setIsSearchFocused(focused);
    if (focused) {
      // Auto-scroll hacia arriba
      scrollViewRef.current?.scrollTo({ y: 0, animated: true });
    }
  }}
/>
          </View>

{/* 3 */}
<View style={styles.shareContainer} key={shareButtonKey}>
  {isUserGuest ? (
    <Animated.View
      style={{
        opacity: registerButtonOpacity,
        transform: [{ translateX: registerButtonTranslateX }],
      }}
    >
      <RegisterButton />
    </Animated.View>
  ) : (
    <Animated.View
      style={{
        opacity: shareButtonOpacity,
        transform: [{ translateX: shareButtonTranslateX }],
      }}
    >
      <ShareButton
        ocultarcomptador={InfoFromServer?.nocomptador === true}
      />
    </Animated.View>
  )}
</View>


          {/* 4) STICKY HEADER => "Servicios cercanos" + ID en una sola línea, con algo de marginTop */}
          <View style={styles.stickyHeaderContainer}>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Text style={styles.stickyHeaderTitle}>
                {translate[language].servicios}{" "}
                <Text style={styles.stickyHeaderId}>
                  {userData?.data?.[0]?.Id_zona ?? ""}
                </Text>
              </Text>
            </View>
            <View style={styles.headerUnderline} />
          </View>

          {/* 5) Paneles que corresponden a servicios: panel1, panel5 */}
          <View style={styles.cardContainer}>
            {/* panel1 */}
            {panelsData["panel1"].length > 0 && (
              <View>
                <View style={styles.titleContainer}>
                  {getTitleForPanel("panel1", language)}
                </View>
                <ScrollView
                  horizontal
                  showsHorizontalScrollIndicator={false}
                  contentContainerStyle={styles.cardScrollContainer}
                  onScroll={({ nativeEvent }) => {
                    if (
                      !isFetching &&
                      nativeEvent.layoutMeasurement.width +
                        nativeEvent.contentOffset.x >=
                        nativeEvent.contentSize.width - 50
                    ) {
                      fetchPanelData("panel1");
                    }
                  }}
                  scrollEventThrottle={16}
                >
                  {insertAdsInData(panelsData["panel1"], "panel1")}
                </ScrollView>
              </View>
            )}

            {/* panel5 */}
            {panelsData["panel5"].length > 0 && (
              <View>
                <View style={styles.titleContainer}>
                  {getTitleForPanel("panel5", language)}
                </View>
                <ScrollView
                  horizontal
                  showsHorizontalScrollIndicator={false}
                  contentContainerStyle={styles.cardScrollContainer}
                  onScroll={({ nativeEvent }) => {
                    if (
                      !isFetching &&
                      nativeEvent.layoutMeasurement.width +
                        nativeEvent.contentOffset.x >=
                        nativeEvent.contentSize.width - 50
                    ) {
                      fetchPanelData("panel5");
                    }
                  }}
                  scrollEventThrottle={16}
                >
                  {insertAdsInData(panelsData["panel5"], "panel5")}
                </ScrollView>
                <Publi formatAnunci="H" />
              </View>
            )}
          </View>

          {/* 6) STICKY HEADER => "Objetos cercanos" + ID en una sola línea */}
          <View style={styles.stickyHeaderContainer}>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Text style={styles.stickyHeaderTitle}>
                {translate[language].objetos}
              </Text>
              <Text style={styles.stickyHeaderId}>
                {userData?.data?.[0]?.Id_zona ?? ""}
              </Text>
            </View>
            <View style={styles.headerUnderline} />
          </View>

          {/* 7) Paneles que corresponden a objetos: panel4, panel2 */}
          <View style={styles.cardContainer}>
            {/* panel4 */}
            {panelsData["panel4"].length > 0 && (
              <View>
                <View style={styles.titleContainer}>
                  {getTitleForPanel("panel4", language)}
                </View>
                <ScrollView
                  horizontal
                  showsHorizontalScrollIndicator={false}
                  contentContainerStyle={styles.cardScrollContainer}
                  onScroll={({ nativeEvent }) => {
                    if (
                      !isFetching &&
                      nativeEvent.layoutMeasurement.width +
                        nativeEvent.contentOffset.x >=
                        nativeEvent.contentSize.width - 50
                    ) {
                      fetchPanelData("panel4");
                    }
                  }}
                  scrollEventThrottle={16}
                >
                  {insertAdsInData(panelsData["panel4"], "panel4")}
                </ScrollView>
              </View>
            )}

            {/* panel2 */}
            {panelsData["panel2"].length > 0 && (
              <View>
                <View style={styles.titleContainer}>
                  {getTitleForPanel("panel2", language)}
                </View>
                <ScrollView
                  horizontal
                  showsHorizontalScrollIndicator={false}
                  contentContainerStyle={styles.cardScrollContainer}
                  onScroll={({ nativeEvent }) => {
                    if (
                      !isFetching &&
                      nativeEvent.layoutMeasurement.width +
                        nativeEvent.contentOffset.x >=
                        nativeEvent.contentSize.width - 50
                    ) {
                      fetchPanelData("panel2");
                    }
                  }}
                  scrollEventThrottle={16}
                >
                  {insertAdsInData(panelsData["panel2"], "panel2")}
                </ScrollView>
                <Publi formatAnunci="H" />
              </View>
            )}
          </View>

          {/* 8) Resto de paneles (panel6, panel3) */}
          <View style={styles.cardContainer}>
            {/* panel6 */}
            {panelsData["panel6"].length > 0 && (
              <View>
                <View style={styles.titleContainer}>
                  {getTitleForPanel("panel6", language)}
                </View>
                <ScrollView
                  horizontal
                  showsHorizontalScrollIndicator={false}
                  contentContainerStyle={styles.cardScrollContainer}
                  onScroll={({ nativeEvent }) => {
                    if (
                      !isFetching &&
                      nativeEvent.layoutMeasurement.width +
                        nativeEvent.contentOffset.x >=
                        nativeEvent.contentSize.width - 50
                    ) {
                      fetchPanelData("panel6");
                    }
                  }}
                  scrollEventThrottle={16}
                >
                  {insertAdsInData(panelsData["panel6"], "panel6")}
                </ScrollView>
              </View>
            )}

            {/* panel3 */}
            {panelsData["panel3"].length > 0 && (
              <View>
                <View style={styles.titleContainer}>
                  {getTitleForPanel("panel3", language)}
                </View>
                <ScrollView
                  horizontal
                  showsHorizontalScrollIndicator={false}
                  contentContainerStyle={styles.cardScrollContainer}
                  onScroll={({ nativeEvent }) => {
                    if (
                      !isFetching &&
                      nativeEvent.layoutMeasurement.width +
                        nativeEvent.contentOffset.x >=
                        nativeEvent.contentSize.width - 50
                    ) {
                      fetchPanelData("panel3");
                    }
                  }}
                  scrollEventThrottle={16}
                >
                  {insertAdsInData(panelsData["panel3"], "panel3")}
                </ScrollView>
              </View>
            )}
          </View>
        </Animated.ScrollView>

        <SubscriptionOk
          visible={isSubscriptionOkVisible}
          onClose={handleCloseSubscriptionOk}
        />

        <CustomModal
          visible={modalVisible}
          title={modalData.title}
          message={modalData.message}
          actions={modalData.actions}
        />

        <Modal
          animationType="fade"
          transparent={true}
          visible={qrModalVisible}
          onRequestClose={handleCloseQrModal}
        >
          <TouchableOpacity
            style={styles.qrModalBackground}
            onPress={handleCloseQrModal}
          >
            <View style={styles.qrModalView}>
              <Image
                source={require("../img/QR.webp")}
                style={styles.qrImage}
              />
            </View>
          </TouchableOpacity>
        </Modal>

        <Modal
          animationType="slide"
          transparent={true}
          visible={isVerificationModalVisible}
          onRequestClose={() => setIsVerificationModalVisible(false)}
        >
          <View style={styles.modalBackground}>
            <View style={styles.modalView}>
              <Text style={styles.modalTitle}>
                {translate[language].verificationModalTitle}
              </Text>
              <Text style={styles.modalMessage}>
                {translate[language].verificationModalMessage.replace(
                  "{email}",
                  verificationEmail
                )}
                <Text style={styles.boldText}>
                  {translate[language].spamMessage}
                </Text>
              </Text>
              <TextInput
                style={styles.inputCodeBox}
                value={verificationCode}
                onChangeText={setVerificationCode}
                placeholder={translate[language].verificationCodePlaceholder}
                keyboardType="numeric"
                maxLength={4}
              />
              {verificationError ? (
                <Text
                  style={{ color: "red", textAlign: "center", marginTop: 10 }}
                >
                  {verificationError}
                </Text>
              ) : null}
              <View style={styles.buttonContainer}>
                <TouchableOpacity
                  style={styles.button}
                  onPress={handleCodeSubmit}
                >
                  <Text style={styles.buttonText}>
                    {translate[language].verificationButton}
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.resendButton}
                  onPress={handleResendCode}
                >
                  <Text style={styles.resendButtonText}>
                    {translate[language].notReceivedQuery}
                  </Text>
                  <Text style={styles.resendButtonTextBold}>
                    {translate[language].resendCodeButton}
                  </Text>
                </TouchableOpacity>
                {resendCodeSuccessMessage ? (
                  <Text
                    style={{
                      color: "green",
                      textAlign: "center",
                      marginTop: 10,
                    }}
                  >
                    {resendCodeSuccessMessage}
                  </Text>
                ) : null}
                <TouchableOpacity
                  style={styles.restartButton}
                  onPress={handleLogout}
                >
                  <Text style={styles.restartButtonText}>
                    {translate[language].restartButton}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>

        {/* FAB flotante */}
        {fabChoice && fabText && (
          <Animated.View
            style={[
              styles.fabContainer,
              {
                transform: [{ translateX: floatX }, { translateY: floatY }],
              },
            ]}
          >
            <TouchableOpacity style={styles.fab} onPress={handleFabPress}>
              <Text style={styles.fabEmoji}>{fabEmoji}</Text>
              <Text style={styles.fabText}>{fabText}</Text>
            </TouchableOpacity>
          </Animated.View>
        )}

        {/* Modal Pro/Colab */}
        <View>
          <Modal
            visible={isProColabModalVisible}
            animationType="slide"
            onRequestClose={() => setIsProColabModalVisible(false)}
          >
            <ProColab
              closeModal={() => setIsProColabModalVisible(false)}
              initialMode={modalMode}
            />
          </Modal>
        </View>
      </View>
    </SafeAreaView>
  );
};

const { width } = Dimensions.get("window");
const searchBarWidth = 340;

const styles = StyleSheet.create({
  container: {
    paddingBottom: 75,
    alignItems: "center",
    flexGrow: 1,
    width: "100%",
    backgroundColor: Colors.white,
  },
  centerContainer: {
    width: searchBarWidth,
    alignSelf: "center",
    marginTop: 20,
  },
  cardContainer: {
    marginTop: 14,
    width: "100%",
  },
  cardScrollContainer: {
    flexDirection: "row",
    paddingLeft: 10,
    paddingRight: 15,
  },
  titleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 12,
    marginBottom: 0,
    marginRight: 10,
    marginLeft: 10,
  },
  titleText: {
    ...Fonts.poppinsRegular16,
    color: Colors.grayDark,
  },
  serviciosObjetosContainer: {
    position: "absolute",
    top: Platform.select({
      web: 0,
      android: -35,
      ios: -52,
    }), // Ajusta según sea necesario
    left: 0,
    right: 0,
    backgroundColor: Colors.white,
    zIndex: 1000, // Asegúrate de que esté por encima de otros componentes
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.15,
    shadowRadius: 4,
    elevation: 10,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
  },
  // Sticky headers
  stickyHeaderContainer: {
    flexDirection: Platform.select({
      web: "column", // En web apilamos elementos
      default: "row", // En mobile se mantienen en línea
    }),
    alignItems: Platform.select({
      web: "flex-start", // Alineamos a la izquierda en web
      default: "center", // Centramos verticalmente en mobile
    }),
    justifyContent: Platform.select({
      web: "flex-start", // Alineación estándar para web
      default: "space-between", // Distribución correcta en mobile
    }),
    paddingVertical: Platform.OS === "web" ? 30 : 10, // Ajuste para web
    paddingHorizontal: 10,
    marginTop: Platform.OS === "web" ? 0 : 12, // Ajuste para web
    backgroundColor: Colors.white,
    ...Platform.select({
      web: {
        position: "sticky",
        top: 0,
        zIndex: 100,
      },
      default: {
        position: "relative",
      },
    }),
  },

  stickyHeaderTitle: {
    ...Fonts.poppinsMedium18,
    color: Colors.grayDark,
    flexShrink: 1, // Evita que el texto se desborde
  },
  stickyHeaderId: {
    ...Fonts.poppinsMedium18,
    color: Colors.primary,
    marginLeft: 8, // Espacio entre el título y el ID
  },
  shareContainer: {
    position: "absolute",
    top: Platform.OS === "ios" ? 12 : 28, // Ajusta según sea necesario
    right: Dimensions.get("window").width * 0.06,
    zIndex: 1000,
  },
  fabContainer: {
    position: "absolute",
    right: 10,
    bottom: 10,
    zIndex: 100,
  },
  fab: {
    minWidth: 56,
    height: 56,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.primary,
    borderRadius: 28,
    elevation: 12,
    shadowColor: "rgba(0, 0, 0, 0.9)",
    shadowRadius: 8,
    shadowOpacity: 1,
    shadowOffset: { width: 0, height: 0 },
    flexDirection: "row",
    paddingHorizontal: 12,
    padding: 10,
  },
  fabEmoji: {
    fontSize: 18,
    color: "#fff",
  },
  fabText: {
    color: "#fff",
    ...Fonts.poppinsRegular14,
    marginLeft: 4,
  },
  modalBackground: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    backgroundColor: "white",
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: "85%",
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 15,
    color: Colors.primary,
  },
  modalMessage: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 20,
    color: Colors.grayDark,
  },
  boldText: {
    fontWeight: "bold",
  },
  inputCodeBox: {
    width: 200,
    height: 40,
    textAlign: "center",
    marginVertical: 20,
    borderWidth: 1,
    borderColor: Colors.primary,
    borderRadius: 10,
  },
  buttonContainer: {
    marginTop: 20,
    alignItems: "center",
  },
  button: {
    backgroundColor: Colors.primary,
    borderRadius: 20,
    paddingVertical: 15,
    paddingHorizontal: 30,
    elevation: 2,
    width: "85%",
    marginBottom: 16,
  },
  buttonText: {
    color: "white",
    fontWeight: "bold",
    fontSize: 18,
    textAlign: "center",
  },
  resendButton: {
    backgroundColor: "#f8d7da",
    borderRadius: 20,
    padding: 10,
    elevation: 2,
    marginTop: 10,
    width: "75%",
    alignItems: "center",
    marginBottom: 14,
  },
  resendButtonText: {
    color: "#721c24",
    textAlign: "center",
  },
  resendButtonTextBold: {
    color: "#721c24",
    textAlign: "center",
    fontWeight: "bold",
  },
  restartButton: {
    borderColor: Colors.primary,
    borderWidth: 1,
    borderRadius: 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
    marginTop: 10,
    backgroundColor: "white",
  },
  restartButtonText: {
    color: Colors.primary,
    fontWeight: "bold",
    fontSize: 16,
    textAlign: "center",
  },
  qrModalBackground: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    justifyContent: "center",
    alignItems: "center",
  },
  qrModalView: {
    backgroundColor: "white",
    borderRadius: 10,
    padding: 20,
    alignItems: "center",
  },
  qrImage: {
    width: Dimensions.get("window").width * 0.8,
    height: Dimensions.get("window").height * 0.8,
    resizeMode: "contain",
  },
  safeArea: {
    flex: 1,
    backgroundColor: Colors.white,
    paddingTop: Platform.select({
      web: 40, // Desplaza hacia abajo 40px solo en web
      default: 0, // Sin padding adicional en mobile
    }),
  },
  headerUnderline: {
    height: Platform.OS === "web" ? 3 : 2, // Ajuste para web
    backgroundColor: "#ccc", // Color gris
    alignSelf: "flex-start", // Hace que la línea se ajuste al ancho del texto
    marginTop: 4, // Separación entre el texto y la línea
    width: "100%", // Ajusta este valor si quieres que ocupe un porcentaje del texto
    marginBottom: Platform.OS === "web" ? -30 : -10, 
    borderRadius: 10, // Hace que los bordes sean redondeados
    overflow: "hidden",
  },
  ofertesMargin: {
    marginTop: -18, // Margen para "Ofertes" (ajústalo según tu necesidad)
  },
  defaultMargin: {
    marginTop: 0, // Margen para el resto (ajústalo según tu necesidad)
  },
});

export default HomeScreen;
