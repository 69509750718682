import React, { useContext, useMemo, useState, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
  Platform,
  Modal,
  ScrollView,
  Alert,
  Linking,
} from "react-native";
import { Colors, Fonts } from "../styles/styles";
import { NOTIFICATION_MESSAGESID } from "../db/NotificationMessages";
import { AppContext } from "../../AppContext";
import { useNavigation } from "@react-navigation/native";
import profileImages from "../img/profileimage.js";
import { BlurView } from "expo-blur";
import ProColab from "../components/Pro.js";
import * as Notifications from "expo-notifications";

const translate = {
  ESP: {
    year: "años",
    month: "meses",
    day: "días",
    hour: "horas",
    minute: "minutos",
    second: "segundos",
    since: "hace",
    standard: "Un usuario cercano",
    notificationTitle: "Notificación de Trocalia",
    activateNotifications:
      "Activa si quieres que te avisemos de las novedades que hay en ",
    alertTitle: "Permite que Trocalia te envíe notificaciones",
    alertMessage: "Toca configuración para actualizar tus preferencias.",
    noOption: "Ahora no",
    settingsOption: "Configuración",
    modalTitle: "Notificaciones disponibles solo en la App",
    modalMessage:
      "Las notificaciones funcionan en la App. Te recomendamos descargarla.",
    closeButtonText: "Cerrar",
    downloadButtonText: "Descargar",
    convidat: "Invitado",
  },
  CAT: {
    year: "anys",
    month: "mesos",
    day: "dies",
    hour: "hores",
    minute: "minuts",
    second: "segons",
    since: "fa",
    standard: "Un usuari proper",
    notificationTitle: "Notificació de Trocalia",
    activateNotifications:
      "Activa si vols que t'avisem de les novetats que hi ha al ",
    alertTitle: "Permet que Trocalia t'envii notificacions",
    alertMessage: "Toca configuració per actualitzar les teves preferències.",
    noOption: "Ara no",
    settingsOption: "Configuració",
    modalTitle: "Les notificacions només estan disponibles a l'App",
    modalMessage:
      "Les notificacions funcionen a l'App. Et recomanem descarregar-la.",
    closeButtonText: "Tancar",
    downloadButtonText: "Descarregar",
    convidat: "Convidat",
    
  },
  ENG: {
    year: "years",
    month: "months",
    day: "days",
    hour: "hours",
    minute: "minutes",
    second: "seconds",
    since: "since",
    standard: "A nearby user",
    notificationTitle: "Notification from Trocalia",
    activateNotifications:
      "Enable if you want us to notify you about the news in ",
    alertTitle: "Allow Trocalia to send you notifications",
    alertMessage: "Tap settings to update your preferences.",
    noOption: "Not now",
    settingsOption: "Settings",
    modalTitle: "Notifications available only in the App",
    modalMessage:
      "Notifications work in the App. We recommend you download it.",
    closeButtonText: "Close",
    downloadButtonText: "Download",
    convidat: "Guest",
  },
};

const NotificationList = ({ notifications }) => {
  const { language, serverAddress, userData } = useContext(AppContext);
  const navigation = useNavigation();

  const isPremium =
    userData && userData.data && userData.data[0].tipusUsuari === "Premium";
  const isColab =
    userData && userData.data && userData.data[0].tipusUsuari === "Colaborador";

  const [proColabModalVisible, setProColabModalVisible] = useState(false);
  const [isNotificationsGranted, setIsNotificationsGranted] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const zona = userData ? userData.data[0].Id_zona : "";

  const appStoreUrl = "https://apps.apple.com/es/app/trocalia/id6478570314";
  const playStoreUrl =
    "https://play.google.com/store/apps/details?id=com.trocalia";

  const handleDownload = () => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (Platform.OS === "ios") {
      Linking.openURL(appStoreUrl);
      return;
    } else if (Platform.OS === "android") {
      Linking.openURL(playStoreUrl);
      return;
    }

    // Para la web, redirigir según el userAgent
    if (
      userAgent.includes("iphone") ||
      userAgent.includes("ipad") ||
      userAgent.includes("macintosh")
    ) {
      Linking.openURL(appStoreUrl);
    } else {
      Linking.openURL(playStoreUrl);
    }
  };

  useEffect(() => {
    const checkNotificationPermissions = async () => {
      const { status } = await Notifications.getPermissionsAsync();
      setIsNotificationsGranted(status === "granted");
    };
    checkNotificationPermissions();
  }, []);

  const requestPushPermissions = async () => {
    const { status } = await Notifications.requestPermissionsAsync();
    setIsNotificationsGranted(status === "granted");

    if (status !== "granted") {
      Alert.alert(
        translate[language].alertTitle,
        translate[language].alertMessage,
        [
          {
            text: translate[language].noOption,
            style: "cancel",
          },
          {
            text: translate[language].settingsOption,
            onPress: () => {
              if (Platform.OS === "ios") {
                Linking.openURL("app-settings:");
              } else {
                Linking.openSettings();
              }
            },
          },
        ],
        { cancelable: true }
      );
    }
  };

  const parseDate = (dateStr) => {
    return new Date(dateStr);
  };

  const getTimeSince = (dateStr) => {
    const date = parseDate(dateStr);
    const seconds = Math.floor((new Date() - date) / 1000);

    const { year, month, day, hour, minute, second, since } = translate[
      language
    ];

    let interval = seconds / 31536000;
    if (interval > 1) return `${since} ${Math.floor(interval)} ${year}`;

    interval = seconds / 2592000;
    if (interval > 1) return `${since} ${Math.floor(interval)} ${month}`;

    interval = seconds / 86400;
    if (interval > 1) return `${since} ${Math.floor(interval)} ${day}`;

    interval = seconds / 3600;
    if (interval > 1) return `${since} ${Math.floor(interval)} ${hour}`;

    interval = seconds / 60;
    if (interval > 1) return `${since} ${Math.floor(interval)} ${minute}`;

    return `${since} ${Math.floor(seconds)} ${second}`;
  };

  // Quitar duplicados y ordenar
  const sortedNotifications = useMemo(() => {
    if (!notifications) return [];

    // 1. Convertimos las notificaciones en un map para quitar duplicadas por _id
    const uniqueMap = new Map();
    for (const notif of notifications) {
      uniqueMap.set(notif._id, notif);
    }
    // 2. Obtenemos el array sin duplicados
    const dedupedNotifications = Array.from(uniqueMap.values());

    // 3. Ordenamos por fecha (recientes primero)
    return dedupedNotifications.sort(
      (a, b) => parseDate(b.createdAt) - parseDate(a.createdAt)
    );
  }, [notifications]);

  const shouldDisplayHVTP = (hvtpIndex) => {
    return hvtpIndex === 1 || (hvtpIndex > 1 && (hvtpIndex - 1) % 4 === 0);
  };

  const getProfileImageSource = (notification) => {
    const { foto, usu_Origen } = notification.body;
    if (foto) {
      return { uri: `${serverAddress}/fotos/${foto}` };
    } else {
      return (
        profileImages[usu_Origen % 10] || require("../img/profiles/default.png")
      );
    }
  };

  const renderNotificationMessage = (notification) => {
    if (notification.codi === "NOTITXT" && notification.body.text) {
      return notification.body.text;
    }
    return (
      NOTIFICATION_MESSAGESID[notification.codi]?.[language] ||
      "Notificación desconocida"
    );
  };

  const handlePressNotification = (notification, index, hvtpIndex) => {
    if (notification.codi === "HCTO" || notification.codi === "HCTD") {
      navigation.navigate("Perfil");
    } else if (
      notification.codi === "HVTP" &&
      !isPremium &&
      !isColab &&
      !shouldDisplayHVTP(hvtpIndex)
    ) {
      setProColabModalVisible(true);
    } else if (notification.codi === "STROC") {
      navigation.navigate("Troc");
    } else {
      navigation.navigate("ProfileOther", {
        userId: notification.body.id_usuari,
      });
    }
  };

  const renderUserInfo = (notification, index, hvtpIndex) => {
    const isCustomNotification = notification.codi === "NOTITXT";
    if (isCustomNotification) {
      const notificationTitle = translate[language].notificationTitle;
      return <Text style={styles.userName}>{notificationTitle}</Text>;
    } else {
      const isHVTP = notification.codi === "HVTP";
      const shouldUseStandardText =
        isHVTP && !isPremium && !isColab && !shouldDisplayHVTP(hvtpIndex);
      const standardText = translate[language].standard;
  
      const hasNomUsuari =
        notification.body.nomUsuari && notification.body.nomUsuari.trim() !== "";
      const hasCognomUsuari =
        notification.body.cognomUsuari &&
        notification.body.cognomUsuari.trim() !== "";
  
      let name = "";
      if (hasNomUsuari) {
        name = notification.body.nomUsuari;
        if (hasCognomUsuari) {
          name += ` ${notification.body.cognomUsuari}`;
        }
      } else {
        name = translate[language].notificationTitle;
      }
  
      // **Añadir la verificación para "Convidat"**
      if (name.trim() === "Convidat") {
        name = translate[language].convidat;
      }
  
      return (
        <Text style={styles.userName}>
          {shouldUseStandardText ? standardText : name}
        </Text>
      );
    }
  };
  

  const renderImage = (notification, index, hvtpIndex) => {
    const imageSource = getProfileImageSource(notification);
    const isHVTP = notification.codi === "HVTP";

    if (isHVTP && !isPremium && !isColab && !shouldDisplayHVTP(hvtpIndex)) {
      return (
        <View style={styles.imageContainer}>
          <Image style={styles.image} source={imageSource} />
          <BlurView
            style={StyleSheet.absoluteFill}
            intensity={Platform.OS === "ios" ? 10 : 25}
            tint="light"
            experimentalBlurMethod="dimezisBlurView"
          />
        </View>
      );
    } else {
      return <Image style={styles.image} source={imageSource} />;
    }
  };

  return (
    <ScrollView contentContainerStyle={styles.frameScreen}>
      {/* Botón para activar notificaciones si no están activadas */}
      {isNotificationsGranted === false && (
        <TouchableOpacity
          onPress={() => {
            if (Platform.OS === "web") {
              setIsModalVisible(true);
            } else {
              requestPushPermissions();
            }
          }}
          style={styles.notificationPrompt}
        >
          <Text style={styles.notificationText}>
            {translate[language].activateNotifications + zona}
          </Text>
          <Text style={styles.iconText}>🔔</Text>
        </TouchableOpacity>
      )}

      {sortedNotifications.map((notification, index) => {
        const key = `notification-${notification._id}-${index}`;
        const timeSince = getTimeSince(notification.createdAt);

        // Calcula la posición en las HVTP para difuminarlas si corresponde
        const hvtpIndex = sortedNotifications
          .filter((n) => n.codi === "HVTP")
          .indexOf(notification);

        // Notificaciones STROC (ejemplo especial)
        if (notification.codi === "STROC") {
          const message = renderNotificationMessage(notification);
          return (
            <TouchableOpacity
              key={key}
              style={[
                styles.notificationWrapper,
                styles.specialNotificationWrapper,
              ]}
              onPress={() => navigation.navigate("Troc")}
            >
              <View style={[styles.textContainer, styles.specialTextContainer]}>
                <Text style={styles.specialNotificationMessage}>{message}</Text>
              </View>
              <Text style={styles.timeSince}>{timeSince}</Text>
            </TouchableOpacity>
          );
        }

        // Notificaciones normales
        return (
          <TouchableOpacity
            key={key}
            style={styles.notificationWrapper}
            onPress={() =>
              handlePressNotification(notification, index, hvtpIndex)
            }
          >
            {renderImage(notification, index, hvtpIndex)}
            <View style={styles.textContainer}>
              {renderUserInfo(notification, index, hvtpIndex)}
              <Text style={styles.notificationMessage}>
                {renderNotificationMessage(notification)}
              </Text>
            </View>
            <Text style={styles.timeSince}>{timeSince}</Text>
          </TouchableOpacity>
        );
      })}

      {/* Modal para usuarios no premium que intentan ver HVTP */}
      <Modal
        animationType="slide"
        transparent={true}
        visible={proColabModalVisible}
        onRequestClose={() => setProColabModalVisible(false)}
      >
        <ProColab closeModal={() => setProColabModalVisible(false)} initialMode="colab" />
      </Modal>

      {/* Modal para la plataforma web */}
      <Modal
        animationType="slide"
        transparent={true}
        visible={isModalVisible}
        onRequestClose={() => {}}
      >
        <View style={styles.modalBackground}>
          <View style={styles.modalView}>
            <Text style={styles.modalTitle}>
              {translate[language].modalTitle}
            </Text>
            <Text style={styles.modalMessage}>
              {translate[language].modalMessage}
            </Text>
            <View style={styles.buttonContainer}>
              <TouchableOpacity
                style={[styles.button, styles.cancelButton]}
                onPress={() => setIsModalVisible(false)}
              >
                <Text style={styles.buttonText}>
                  {translate[language].closeButtonText}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.button, styles.downloadButton]}
                onPress={handleDownload}
              >
                <Text style={styles.buttonText}>
                  {translate[language].downloadButtonText}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  frameScreen: {
    backgroundColor: "transparent",
    alignItems: "center",
    width: 340,
    marginTop: 4,
    marginBottom: 4,
  },
  notificationPrompt: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: Colors.white,
    width: 340,
    paddingVertical: 6,
    paddingHorizontal: 12,
    borderRadius: 16,
    marginBottom: 16,
    borderWidth: 1,
    borderColor: Colors.primary,
  },
  iconText: {
    fontSize: 20,
    color: Colors.primary,
  },
  notificationText: {
    color: Colors.primary,
    fontSize: 14,
    textAlign: "left",
    fontWeight: "bold",
    flex: 1,
  },
  notificationWrapper: {
    flexDirection: "row",
    backgroundColor: Colors.grayLighter,
    borderRadius: 16,
    marginBottom: 16,
    padding: 16,
    alignItems: "center",
    justifyContent: "flex-start",
    minHeight: 80,
    position: "relative",
    width: "100%",
    borderWidth: 1,
    borderColor: Colors.grayLight,
  },
  imageContainer: {
    width: 45,
    height: 45,
    borderRadius: 30,
    overflow: "hidden",
    marginRight: 12,
  },
  image: {
    width: 45,
    height: 45,
    borderRadius: 30,
    marginRight: 12,
    backgroundColor: Colors.primary,
  },
  textContainer: {
    flex: 1,
  },
  userName: {
    ...Fonts.poppinsMedium16,
    color: Colors.grey,
    marginBottom: 4,
  },
  notificationMessage: {
    ...Fonts.poppinsRegular14,
    color: Colors.darkGrey,
  },
  timeSince: {
    position: "absolute",
    top: 4,
    right: 6,
    fontSize: 12,
    color: Colors.darkGrey,
  },
  specialNotificationWrapper: {
    backgroundColor: Colors.primary,
  },
  specialTextContainer: {
    flex: 1,
  },
  specialNotificationMessage: {
    ...Fonts.poppinsMedium16,
    color: Colors.white,
    textAlign: "center",
  },
  modalBackground: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    backgroundColor: "white",
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: "80%",
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 15,
    color: Colors.primary,
  },
  modalMessage: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 20,
    color: Colors.grayDark,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
  },
  button: {
    borderRadius: 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
    elevation: 2,
    margin: 5,
    alignSelf: "flex-start",
  },
  downloadButton: {
    backgroundColor: Colors.primary,
  },
  cancelButton: {
    backgroundColor: "#CCCCCC",
  },
  buttonText: {
    color: "white",
    fontWeight: "bold",
    fontSize: 18,
    textAlign: "center",
  },
});

export default NotificationList;
