import React, { useState, useContext, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  Text,
  Image,
  TextInput,
  TouchableOpacity,
  ScrollView,
  Platform,
  Dimensions,
  Switch,
  Vibration,
  Modal,
  Linking,
  Animated,
  Easing,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import * as SecureStore from "expo-secure-store";
import Svg, { Path } from "react-native-svg";
import { AppContext } from "../../AppContext";
import trocaliaIcon from "../img/icons/trocalia.png";
import { Colors, Fonts } from "../styles/styles";
import CheckBox from "expo-checkbox";
import Explain from "../components/Explain";
import NotificationPlatform from "../components/NotificationPlatfom";
import NeedPostal from "../selectables/NeedPostal";
import * as Haptic from "expo-haptics";

export default function Register() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [repeatPasswordError, setRepeatPasswordError] = useState("");
  const [emailTimer, setEmailTimer] = useState(null);
  const [passwordTimer, setPasswordTimer] = useState(null);
  const [repeatPasswordTimer, setRepeatPasswordTimer] = useState(null);
  const [hasRepeatPasswordErrorBeenShown, setHasRepeatPasswordErrorBeenShown] =
    useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [showTermsError, setShowTermsError] = useState(false);
  const { pendingProfileId, setPendingProfileId } = useContext(AppContext);
  const [verificationError, setVerificationError] = useState("");
  const [resendCodeSuccessMessage, setResendCodeSuccessMessage] = useState("");
  const [currentStep, setCurrentStep] = useState(0);

  const [postalCode, setPostalCode] = useState("");
  const [postalCodeSuccess, setPostalCodeSuccess] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");
  const [isEmailRegistered, setIsEmailRegistered] = useState(false);
  const [isBusiness, setIsBusiness] = useState(false);
  const scrollViewRef = useRef();
  const [isNeedPostalVisible, setIsNeedPostalVisible] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [numUsuarios, setNumUsuarios] = useState(null);
  const [showUserCount, setShowUserCount] = useState(false);
  const [isQrModalVisible, setIsQrModalVisible] = useState(false);
  const [permitirGuest, setPermitirGuest] = useState(true); // Nuevo estado para permitir invitados
  const [isPostalCodeValid, setIsPostalCodeValid] = useState(false); // Nuevo estado para validar código postal
  const [animatedPlaceholder, setAnimatedPlaceholder] = useState("");
  const [resendButtonDisabled, setResendButtonDisabled] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);
  const resendInterval = useRef(null);
  const headerSlideAnim = useRef(new Animated.Value(-100)).current; // Slide from top
  const logoFadeAnim = useRef(new Animated.Value(0)).current; // Fade in

  useEffect(() => {
    // Slide in the header
    Animated.timing(headerSlideAnim, {
      toValue: 0,
      duration: 1000,
      useNativeDriver: true,
      easing: Easing.out(Easing.ease),
    }).start();

    // Fade in the logo after header animation
    Animated.sequence([
      Animated.timing(logoFadeAnim, {
        toValue: 1,
        duration: 1000,
        useNativeDriver: true,
        delay: 500, // Delay to start after header begins sliding
      }),
    ]).start();
  }, []);

  const {
    setToken,
    updateUser,
    updateOtherData,
    serverAddress,
    language,
    token,
    setHasToken,
  } = useContext(AppContext);
  const navigation = useNavigation();
  const saveModalState = () => {
    localStorage.setItem("qrModalShown", "true");
  };

  // Función para comprobar si ya se ha mostrado el modal
  const checkModalState = () => {
    return localStorage.getItem("qrModalShown") === "true";
  };

  // Constantes de dimensiones
  const { width } = Dimensions.get("window");
  const svgWidth = width;
  const svgHeight = 180;

  const translate = {
    CAT: {
      firstName: "Nom",
      firstNamePlaceholder: "Nom (Obligatori)",
      firstNameValidation:
        "El camp Nom és obligatori, no ha de ser més llarg de 15 caràcters i només pot contenir lletres.",
      lastName: "Cognom",
      lastNamePlaceholder: "Cognom (Opcional)",
      lastNameValidation:
        "El camp Cognom no ha de ser més llarg de 15 caràcters.",
      emailPlaceholder: "elteuemail@gmail.com",
      emailValidation: "Si us plau, introdueixi un correu electrònic vàlid.",
      passwordPlaceholder: "Escriu la teva contrasenya",
      passwordValidation:
        "La contrasenya ha de contenir almenys una majúscula i com a mínim 6 caràcters.",
      repeatPasswordPlaceholder: "Repeteix la contrasenya",
      repeatPasswordValidation: "Les contrasenyes no coincideixen.",
      registerButtonText: "Registrar-se",
      alreadyRegisteredText: "N'ets membre?",
      userGuestPart1: "Vols fer un cop d'ull?",
      userGuestPart2: "Entrar com a convidat",
      loginText: "Entra amb usuari",
      terms: "En marcar aquesta casella, confirmes que has llegit i acceptes",
      conditions: "les Condicions de Servei",
      privacyPolicy: "i Política de Privacitat",
      faq2: "Si ho desitja, pot consultar el nostre apartat de",
      faq: "Preguntes Freqüents",
      passwordValidationMissing:
        "La contrasenya ha de contenir almenys una majúscula i com a mínim 6 caràcters.",
      accessButtonText: "Accedir",
      postalCodeLabel: "Codi Postal",
      areyouBusiness: "Ets empresa?",
      postalCodeValidation: "El codi postal ha de ser de 5 dígits numèrics.",
      postalCodeNotFound:
        "En aquest codi postal encara no hi treballem, si ens escrius a suport@trocalia.net l'afegirem el més ràpid possible.",

      postalCodeFound:
        "Confirmat! {zona} forma part de les zones on Trocalia està disponible.",
      validationError: "Error al validar el codi postal.",
      termsError: "Si us plau, accepta els termes i condicions per continuar",
      emailAlreadyRegistered: "Aquest correu electrònic ja està registrat.",
      emailCheckError: "Error al comprovar el correu electrònic.",
      // Textos para el modal de verificación
      verificationModalTitle: "Un últim pas...",
      verificationModalMessage:
        "Introdueix el codi de 4 dígits que hem enviat al teu correu {email}. Aquest pas és per assegurar la teva seguretat i confirmar la teva identitat. Si no reps el codi mira la ",
      spamMessage: "carpeta no desitjats.",
      verificationCodePlaceholder: "Introdueix el codi aquí",
      verificationButton: "Verificar Codi",
      notReceivedQuery: "No has rebut el codi?",
      resendCodeButton: "Obtenir un nou codi",
      verificationInstructions:
        "El codi de verificació és necessari per completar el teu registre i protegir el teu compte. Si no rep el codi, verifiqui la seva carpeta de correu brossa o sol·liciti un nou codi.",
      notokcode: "Codi incorrecte. Si us plau, intenta-ho de nou.",
      codesend: "S'ha enviat un correu amb un nou codi",
      registerError: "Error en el registre, torni-ho a intentar",
      next: "Següent",
      text1: "Com vols que et diem? 💬",
      text1mini:
        "Un nom, àlies, o com prefereixis que et conegui la comunitat.",
      text2: "Últim pas: Crea el teu compte!",
      text2mini: "Completa el teu registre i descobreix la teva comunitat ✅",
      text0: "Benvingut a Trocalia! 👋",
      text0mini2:
        "La Xarxa d’Economia Veïnal Privada on s'ofereixen, demanen i intercanvien serveis i objectes.",
      text0mini:
        "Comprova si estem a la teva zona introduint el teu codi postal.",
      placeholdercp: " Comprova el teu CP - ex: 08030",
      video: "Veure video tutorial",
      editEmailButton: "Edita Correu Electrònic",
      users1: "Ja som ",
      users2: " veïns!",
      qr: "Recomanem usar l'App per a una millor experiència",
      close: "Tancar",
    },
    ESP: {
      firstName: "Nombre",
      firstNamePlaceholder: "Nombre (Obligatorio)",
      firstNameValidation:
        "El campo Nombre es obligatorio, no debe ser más largo de 15 caracteres y solo puede contener letras.",
      lastName: "Apellido",
      lastNamePlaceholder: "Apellido (Opcional)",
      lastNameValidation:
        "El campo Apellido no debe ser más largo de 15 caracteres.",
      emailPlaceholder: "tuemail@gmail.com",
      emailValidation: "Por favor ingrese un correo electrónico válido.",
      passwordPlaceholder: "Escribe tu contraseña",
      passwordValidation:
        "La contraseña debe contener al menos una mayúscula y al menos 6 caracteres.",
      repeatPasswordPlaceholder: "Repetir contraseña",
      repeatPasswordValidation: "Las contraseñas no coinciden.",
      registerButtonText: "Registrarse",
      alreadyRegisteredText: "¿Eres miembro?",
      userGuestPart1: "¿Quieres echar un vistazo?",
      userGuestPart2: "Entrar como invitado",
      loginText: "Entra con tu cuenta",
      terms: "Al marcar esta casilla, confirmas que has leído y aceptas",
      conditions: "las Condiciones de Servicio",
      privacyPolicy: "y Política de Privacidad",
      faq2: "Si lo deseas, puedes consultar nuestro apartado de",
      faq: "Preguntas Frecuentes",
      passwordValidationMissing:
        "La contraseña debe contener al menos una mayúscula y al menos 6 caracteres.",
      accessButtonText: "Acceder",
      postalCodeLabel: "Código Postal",
      areyouBusiness: "¿Eres empresa?",
      postalCodeValidation: "El código postal debe ser de 5 dígitos numéricos.",
      postalCodeNotFound:
        "En este código postal todavía no trabajamos, si nos escribes a suport@trocalia.net lo añadiremos lo más rápido posible.",
      postalCodeFound:
        "Confirmado! {zona} forma parte de las zonas donde Trocalia está disponible.",
      validationError: "Error al validar el código postal.",
      termsError: "Por favor, acepta los términos y condiciones para continuar",
      emailAlreadyRegistered: "Este correo electrónico ya está registrado.",
      emailCheckError: "Error al comprobar el correo electrónico.",
      // Textos para el modal de verificación
      verificationModalTitle: "Un último paso...",
      verificationModalMessage:
        "Ingresa el código de 4 dígitos que hemos enviado a tu correo {email}. Este paso es para asegurar tu seguridad y confirmar tu identidad. Si no recibes el código, revisa la ",
      spamMessage: "carpeta de spam.",
      verificationCodePlaceholder: "Ingresa el código aquí",
      verificationButton: "Verificar Código",
      notReceivedQuery: "¿No has recibido el código?",
      resendCodeButton: "Obtener un nuevo código",
      verificationInstructions:
        "El código de verificación es necesario para completar tu registro y proteger tu cuenta. Si no recibes el código, verifica tu carpeta de spam o solicita un nuevo código.",
      notokcode: "Código incorrecto. Por favor, inténtalo de nuevo.",
      codesend: "Se ha enviado un correo con un nuevo código",
      registerError: "Error en el registro, inténtelo de nuevo",
      next: "Siguiente",
      text1: "¡Hola! ¿Cómo te llamamos? 💬",
      text1mini:
        "Un nombre, apodo o como prefieras que te conozca la comunidad.",
      text2: "Último paso: ¡Crea tu cuenta!",
      text2mini: "Completa tu registro y descubre tu comunidad ✅",
      text0: "¡Bienvenido a Trocalia! 👋",
      text0mini2:
        "La Red de Economía Vecinal Privada donde se ofrecen, demandan e intercambian servicios y objetos.",
      text0mini:
        "Comprueba si estamos en tu zona introduciendo tu código postal.",
      placeholdercp: " Comprueba tu código postal - ej: 08030",
      video: "Ver video tutorial",
      editEmailButton: "Edita Correo Electrónico",
      users1: "¡Ya somos ",
      users2: " vecinos!",
      qr: "Recomendamos usar la App para una mejor experiencia",
      close: "Cerrar",
    },
    ENG: {
      firstName: "First Name",
      firstNamePlaceholder: "First Name (Required)",
      firstNameValidation:
        "The First Name field is required and should not be longer than 15 characters and can only contain letters.",
      lastName: "Last Name",
      lastNamePlaceholder: "Last Name (Optional)",
      lastNameValidation:
        "The Last Name field should not be longer than 15 characters.",
      emailPlaceholder: "youremail@gmail.com",
      emailValidation: "Please enter a valid email address.",
      passwordPlaceholder: "Enter your password",
      passwordValidation:
        "Password must contain at least one uppercase letter and at least 6 characters.",
      repeatPasswordPlaceholder: "Repeat Password",
      repeatPasswordValidation: "Passwords do not match.",
      registerButtonText: "Register",
      alreadyRegisteredText: "Already registered?",
      userGuestPart1: "Want to take a look?",
      userGuestPart2: "Enter as guest",
      loginText: "Login",
      terms:
        "By checking this box, you confirm that you have read and agree to",
      conditions: "our Terms of Service",
      privacyPolicy: "and Privacy Policy",
      faq2: "If you wish, you can check our",
      faq: "FAQs",
      passwordValidationMissing:
        "Password must contain at least one uppercase letter and at least 6 characters.",
      accessButtonText: "Access",
      postalCodeLabel: "Postal Code",
      areyouBusiness: "Are you a company?",
      postalCodeValidation: "The postal code must be 5 numeric digits.",
      postalCodeNotFound:
        "In this postal code we do not work yet, if you write to us at suport@trocalia.net we will add it as soon as possible.",
      postalCodeFound:
        "Confirmed! {zona} is part of the areas where Trocalia is available.",
      validationError: "Error validating the postal code.",
      termsError: "Please accept the terms and conditions to continue",
      emailAlreadyRegistered: "This email is already registered.",
      emailCheckError: "Error checking the email.",
      // Textos para el modal de verificación
      verificationModalTitle: "One last step...",
      verificationModalMessage:
        "Enter the 4-digit code we sent to your email {email}. This step is to ensure your security and confirm your identity. If you don't receive the code, check your ",
      spamMessage: "Spam folder.",
      verificationCodePlaceholder: "Enter the code here",
      verificationButton: "Verify Code",
      notReceivedQuery: "Didn't receive the code?",
      resendCodeButton: "Get a new code",
      verificationInstructions:
        "The verification code is necessary to complete your registration and protect your account. If you don't receive the code, check your spam folder or request a new code.",
      notokcode: "Incorrect code. Please try again.",
      codesend: "An email with a new code has been sent",
      registerError: "Registration error, please try again",
      next: "Next",
      text1: "Hello! How should we call you? 💬",
      text1mini:
        "A name, nickname or however you prefer the community to know you.",
      text2: "Last step: Create your account!",
      text2mini: "Complete your registration to discover your community ✅",
      text0: "Welcome to Trocalia! 👋",
      text0mini2:
        "The Neighborhood Economy Network, where services and items are offered, requested, and exchanged.",
      text0mini: "Check if we are in your area by entering your postal code.",
      placeholdercp: " Check your postal code - ex: 08030",
      video: "Watch tutorial video",
      editEmailButton: "Edit Email",
      users1: "We are already ",
      users2: " users!",
      qr: "We recommend using the App for a better experience",
      close: "Close",
    },
  };

  function openAppOrRedirect() {
    var appStoreUrl = "https://apps.apple.com/es/app/trocalia/id6478570314";
    var playStoreUrl =
      "https://play.google.com/store/apps/details?id=com.trocalia";

    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location = appStoreUrl; // Redirige a la App Store para iOS
    } else if (/android/i.test(userAgent)) {
      window.location = playStoreUrl; // Redirige a Google Play para Android
    }
  }

  //AQUESTA ÉS LA FUNCIÓ QUE S'HA DE DESCOMENTAR PER A QUE FUNCIONI EL REDIRECCIONAMENT A L'APP

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${serverAddress}/api/v1/auxiliars/promocionarApp`
        );
        const data = await response.json();

        // Verifica si la propiedad data.promocionarApp es true
        if (data.status === "OK" && data.promocionarApp === true) {
          openAppOrRedirect();
        }
      } catch (error) {
        //console.error("Error al llamar a la API:", error);
      }
    };

    // Verifica que la plataforma sea web antes de hacer la llamada
    if (Platform.OS === "web") {
      fetchData();
    }
  }, [serverAddress]);

  useEffect(() => {
    const fetchNumUsuarios = async () => {
      try {
        const response = await fetch(
          `${serverAddress}/api/v1/auxiliars/numUsuaris`
        );
        const data = await response.json();

        // Verifica si la API responde correctamente
        if (data.status === "OK" && data.data && data.data[0]) {
          //console.log("Número de usuarios obtenido:", data.data[0].numUsuaris);
          setNumUsuarios(data.data[0].numUsuaris);
          setShowUserCount(true); // Mostrar el conteo de usuarios
        } else {
          //console.log('Error en la respuesta de la API');
        }
      } catch (error) {
        //console.error("Error fetching number of users:", error);
      }
    };

    fetchNumUsuarios();
  }, [serverAddress]);

  useEffect(() => {
    const fetchPermitirGuest = async () => {
      try {
        const response = await fetch(
          `${serverAddress}/api/v1/auxiliars/permitirGuest`
        );
        const data = await response.json();

        // Verifica que la estructura sea la esperada y que `permitirGuest` sea 0 o 1
        if (
          data.status === "OK" &&
          Array.isArray(data.data) &&
          data.data.length > 0 &&
          typeof data.data[0].permitirGuest === "number"
        ) {
          // Interpreta 1 como permitir y 0 como no permitir
          setPermitirGuest(data.data[0].permitirGuest === 1);
        } else {
          // console.error(
          //   "Respuesta inesperada de la API para permitirGuest:",
          //   data
          // );
        }
      } catch (error) {
        //console.error("Error al llamar a la API permitirGuest:", error);
      }
    };

    fetchPermitirGuest();
  }, [serverAddress]);

  useEffect(() => {
    if (Platform.OS === "web") {
      // Verificar si window.matchMedia está disponible
      if (typeof window !== "undefined" && window.matchMedia) {
        const isDesktop = window.matchMedia("(min-width: 1024px)").matches;

        if (isDesktop) {
          const alreadyShown = checkModalState();
          if (!alreadyShown) {
            const timer = setTimeout(() => {
              setIsQrModalVisible(true);
            }, 3000);

            return () => clearTimeout(timer);
          }
        }
      } else {
        //console.warn("window.matchMedia no está disponible en este entorno.");
      }
    }
  }, []);

  const closeQrModal = () => {
    setIsQrModalVisible(false);
    saveModalState();
  };

  // Shake Animation for CheckBox (existing)
  const [shakeAnimation, setShakeAnimation] = useState(new Animated.Value(0));

  const startShake = () => {
    Animated.sequence([
      Animated.timing(shakeAnimation, {
        toValue: 10,
        duration: 100,
        useNativeDriver: true,
      }),
      Animated.timing(shakeAnimation, {
        toValue: -10,
        duration: 100,
        useNativeDriver: true,
      }),
      Animated.timing(shakeAnimation, {
        toValue: 10,
        duration: 100,
        useNativeDriver: true,
      }),
      Animated.timing(shakeAnimation, {
        toValue: 0,
        duration: 100,
        useNativeDriver: true,
      }),
    ]).start();
  };

  const shakeInputAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (!translate[language]) return; // Verifica que la traducción esté disponible
    const fullText = translate[language].placeholdercp || ""; // Define el texto completo

    let currentIndex = 0;
    setAnimatedPlaceholder(""); // Reinicia el texto

    const interval = setInterval(() => {
      setAnimatedPlaceholder((prev) => {
        const newText = prev + fullText[currentIndex];
        currentIndex++;

        if (currentIndex >= fullText.length) {
          clearInterval(interval);
        }

        return newText;
      });
    }, 90); // Ajusta el intervalo según sea necesario

    return () => {
      clearInterval(interval); // Asegura que el intervalo se limpie al desmontar
    };
  }, [postalCode, language]); // Asegura que el efecto dependa de postalCode y language

  // Function to shake the postal code input
  const shakeInput = () => {
    Animated.sequence([
      Animated.timing(shakeInputAnim, {
        toValue: 10,
        duration: 100,
        useNativeDriver: true,
      }),
      Animated.timing(shakeInputAnim, {
        toValue: -10,
        duration: 100,
        useNativeDriver: true,
      }),
      Animated.timing(shakeInputAnim, {
        toValue: 10,
        duration: 100,
        useNativeDriver: true,
      }),
      Animated.timing(shakeInputAnim, {
        toValue: 0,
        duration: 100,
        useNativeDriver: true,
      }),
    ]).start();
  };

  const [isVerificationModalVisible, setIsVerificationModalVisible] =
    useState(false);
  const [verificationCode, setVerificationCode] = useState("");

  const showVerificationModal = () => setIsVerificationModalVisible(true);
  const hideVerificationModal = () => setIsVerificationModalVisible(false);

  const handleCodeSubmit = async () => {
    // Verificar si el email termina en '@trocalia.net'
    if (email.endsWith("@trocalia.net")) {
      try {
        // Simula una respuesta exitosa
        hideVerificationModal();
        navigation.navigate("Home"); // Navegar a la pantalla correcta
      } catch (error) {
        //console.error("Error al verificar el código:", error);
        setVerificationError("Hubo un problema al verificar el código.");
      }
    } else {
      // Para otros correos, utiliza la validación de código real
      try {
        const response = await fetch(
          `${serverAddress}/api/v1/perfils/actuacions`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Usando el token del contexto
            },
            body: JSON.stringify({
              actuacio: "VC",
              email: email,
              codi: verificationCode,
            }),
          }
        );

        const data = await response.json();

        if (data.status === "OK" && data.data === "OK") {
          hideVerificationModal();
          if (pendingProfileId) {
            navigation.navigate("ProfileOther", { userId: pendingProfileId });
            setPendingProfileId(null);
          } else {
            navigation.navigate("Home");
          }
        } else {
          setVerificationError(translate[language].notokcode);
        }
      } catch (error) {
        //console.error("Error al verificar el código:", error);
        setVerificationError("Hubo un problema al verificar el código.");
      }
    }
  };

  const handleResendCode = async () => {
    try {
      const response = await fetch(
        `${serverAddress}/api/v1/perfils/actuacions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            actuacio: "CV",
            email: email,
          }),
        }
      );

      const data = await response.json();

      if (data.status === "OK" && data.data === "OK") {
        setResendCodeSuccessMessage(translate[language].codesend);
        setResendButtonDisabled(true); // Desactiva el botón
        setResendTimer(60); // Reinicia el temporizador

        // Inicia la cuenta regresiva
        resendInterval.current = setInterval(() => {
          setResendTimer((prevTimer) => {
            if (prevTimer <= 1) {
              clearInterval(resendInterval.current);
              setResendButtonDisabled(false); // Reactiva el botón
              return 60; // Reinicia el temporizador para futuras reutilizaciones
            }
            return prevTimer - 1;
          });
        }, 1000);
      } else {
        // Manejo de errores si el reenvío falla
        alert(
          translate[language].registerError ||
            "Error al reenviar el código. Por favor, inténtelo de nuevo."
        );
      }
    } catch (error) {
      // Manejo de errores de la solicitud
      alert("Error al reenviar el código: " + error.message);
    }
  };

  const validatePostalCode = async (code) => {
    setPostalCode(code);
    if (code.length === 5) {
      try {
        const response = await fetch(
          `${serverAddress}/api/v1/auxiliars/zones/${code}`
        );
        const data = await response.json();

        if (data.status === "OK") {
          const zona = data.data[0].zona; // Extrae la zona de la respuesta
          setPostalCodeError("");
          // Formatea el mensaje para incluir la zona dinámicamente
          setPostalCodeSuccess(
            translate[language].postalCodeFound.replace("{zona}", zona)
          );
          setIsPostalCodeValid(true); // Marca el código postal como válido
        } else {
          setPostalCodeError(
            translate[language].postalCodeNotFound ||
              "Código postal no encontrado."
          );
          setPostalCodeSuccess("");
          setIsPostalCodeValid(false); // Marca el código postal como inválido
        }
      } catch (error) {
        //console.error("Error al validar el código postal:", error);
        setPostalCodeError(
          translate[language].validationError ||
            "Error al validar el código postal."
        );
        setPostalCodeSuccess("");
        setIsPostalCodeValid(false); // Marca el código postal como inválido
      }
    } else {
      setPostalCodeError(
        translate[language].postalCodeValidation ||
          "El código postal debe ser de 5 dígitos numéricos."
      );
      setPostalCodeSuccess("");
      setIsPostalCodeValid(false); // Marca el código postal como inválido
    }
  };

  const validateFirstName = (text) => {
    setFirstName(text);
    const regex = /^[\p{L}\s'-]+$/u;
    if (!regex.test(text) || text.length > 15) {
      setFirstNameError(translate[language].firstNameValidation);
    } else {
      setFirstNameError("");
    }
  };

  const validateLastName = (text) => {
    setLastName(text);
    const regex = /^[\p{L}\s'-]+$/u;
    if (!regex.test(text) || text.length > 15) {
      setLastNameError(translate[language].lastNameValidation);
    } else {
      setLastNameError("");
    }
  };

  // Validación para el email
  const validateEmail = (text) => {
    let updatedEmail = text;

    const corrections = {
      "jmail.com": "gmail.com",
      "gmail.cim": "gmail.com",
      "gmaik.com": "gmail.com",
      "gmsil.com": "gmail.com",
      "yaho.com": "yahoo.com",
      "yaho.es": "yahoo.es",
      "hotmial.com": "hotmail.com",
      "hotmial.es": "hotmail.es",
      "hormail.com": "hotmail.com",
      "hotmal.es: ": "hotmail.es",
      "hotmal.com": "hotmail.com",
      "gamil.com": "gmail.com",
      "gnail.com": "gmail.com",
      "gmaul.com": "gmail.com",
      "yhaoo.com": "yahoo.com",
      "yahoi.com": "yahoo.com",
      "yahoi.es": "yahoo.es",
      "yahoo.cm": "yahoo.com",
      "yahoo.con": "yahoo.com",
      "yhaoo.es": "yahoo.es",
      "yahho.com": "yahoo.com",
      "hoymail.com": "hotmail.com",
      "hotamil.com": "hotmail.com",
      "homail.com": "hotmail.com",
      "hotrmail.com": "hotmail.com",
      "outlok.com": "outlook.com",
      "htomail.com": "hotmail.com",
      "htmail.com": "hotmail.com",
      "hotnail.com": "hotmail.com",
      "hotmal.com": "hotmail.com",
      "hotmali.com": "hotmail.com",
      "hotemail.com": "hotmail.com",
      "outllok.com": "outlook.com",
      "oulook.com": "outlook.com",
      "outllook.com": "outlook.com",
      "hotmil.com": "hotmail.com",
      "hotmil.es": "hotmail.es",
      "hotmai.com": "hotmail.com",
      "hotmai.es": "hotmail.es",
      "hotmaill.com": "hotmail.com",
      "hotmaill.es": "hotmail.es",
      "hotmaul.com": "hotmail.com",
      "hotmaul.es": "hotmail.es",
      "gmial.com": "gmail.com",
      "gmal.com": "gmail.com",
      "gmail.cm": "gmail.com",
      "gmail.con": "gmail.com",
      "gmal.es": "gmail.es",
      "gimail.com": "gmail.com",
      "gimail.es": "gmail.es",
      "gmaill.com": "gmail.com",
      "hitmail.com": "hotmail.com",
      "hotmail.cpm": "hotmail.com",
      "hitmail.es": "hotmail.es",
      "gmil.com": "gmail.com",
      "gemail.com": "gmail.com",
      "gmai.com": "gmail.com",
      "hotmail.con": "hotmail.com",
      "hormail.com": "hotmail.com",
      "hormail.es": "hotmail.es",
      "icloud.con": "icloud.com",
      "icloud.cm": "icloud.com",
      "iclaud.com": "icloud.com",
      "iclout.com": "icloud.com",
      "gmail.comm": "gmail.com",
      "hotmaiil.com": "hotmail.com",
      "hotmaiil.es": "hotmail.es",
      "hotmaill.es": "hotmail.es",
      "hotmail.comm": "hotmail.com",
    };

    Object.keys(corrections).forEach((key) => {
      if (updatedEmail.includes(key)) {
        updatedEmail = updatedEmail.replace(key, corrections[key]);
      }
    });

    setEmail(updatedEmail);

    if (emailTimer) {
      clearTimeout(emailTimer);
    }

    setEmailTimer(
      setTimeout(() => {
        if (
          !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(updatedEmail)
        ) {
          setEmailError("Por favor ingrese un correo electrónico válido.");
        } else if (updatedEmail.includes("@trocalia")) {
          setEmailError(
            "Los correos con @trocalia son para uso interno solamente."
          );
        } else {
          setEmailError("");
        }
      }, 2500)
    );
  };

  // Validación para la contraseña con debounce
  const validatePassword = (text) => {
    setPassword(text);
    if (passwordTimer) {
      clearTimeout(passwordTimer);
    }
    setPasswordTimer(
      setTimeout(() => {
        if (!/[A-Z]/.test(text) || text.length < 6) {
          setPasswordError(translate[language].passwordValidationMissing);
        } else {
          setPasswordError("");
        }
      }, 5000)
    );
  };

  const validateRepeatPassword = (text) => {
    setRepeatPassword(text);

    // Comprobamos si el error ya ha sido mostrado alguna vez.
    if (hasRepeatPasswordErrorBeenShown) {
      if (text !== password) {
        setRepeatPasswordError(translate[language].repeatPasswordValidation);
      } else {
        setRepeatPasswordError("");
      }
    } else {
      // Si no, seguimos usando el debounce.
      if (repeatPasswordTimer) {
        clearTimeout(repeatPasswordTimer);
      }
      setRepeatPasswordTimer(
        setTimeout(() => {
          if (text !== password) {
            setRepeatPasswordError(
              translate[language].repeatPasswordValidation
            );
            setHasRepeatPasswordErrorBeenShown(true); // Marcamos que el error ya se ha mostrado
          } else {
            setRepeatPasswordError("");
          }
        }, 2500) // Puedes ajustar este tiempo según sea necesario
      );
    }
  };

  const checkEmail = async (email) => {
    try {
      const response = await fetch(`${serverAddress}/api/v1/auxiliars/email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          actuacio: "EM",
          email,
        }),
      });
      const data = await response.json();
      if (data.status === "OK") {
        setIsEmailRegistered(true);
        setEmailError(
          translate[language].emailAlreadyRegistered ||
            "mail ya está registrado"
        );
      } else {
        setIsEmailRegistered(false);
        setEmailError("");
      }
    } catch (error) {
      //console.error("Error checking email:", error);
      setEmailError(
        translate[language].emailCheckError ||
          "Error al verificar el correo electrónico"
      );
    }
  };
  const areFieldsValidPostalStep = () => {
    let isValid = true;

    // Verifica si el código postal tiene exactamente 5 dígitos.
    if (postalCode.length !== 5) {
      setPostalCodeError(
        translate[language].postalCodeValidation ||
          "El código postal debe ser de 5 dígitos numéricos."
      );
      isValid = false;
    } else if (!postalCodeSuccess || postalCodeError) {
      // Además, verifica si el código postal ha sido validado correctamente (postalCodeSuccess no está vacío)
      // y asegúrate de que no existan mensajes de error asociados con la validación del código postal (postalCodeError está vacío).
      isValid = false;
      // Si el código tiene 5 dígitos pero aún no se ha validado o hay un error, mantén o establece el mensaje de error adecuado.
      if (!postalCodeError) {
        setPostalCodeError(
          translate[language].postalCodeNotFound ||
            "Código postal no encontrado."
        );
      }
    }

    return isValid;
  };

  const areFieldsValidFirstStep = () => {
    let isValid = true;

    // Validación para el campo del nombre
    if (!firstName || firstName.length > 15) {
      setFirstNameError(translate[language].firstNameValidation);
      isValid = false;
    } else {
      setFirstNameError("");
    }

    // Validación para el campo del apellido
    if (lastName.length > 15) {
      setLastNameError(translate[language].lastNameValidation);
      isValid = false;
    } else {
      setLastNameError("");
    }

    // Validación para el código postal
    if (!/^\d{5}$/.test(postalCode) || postalCodeError || !postalCodeSuccess) {
      setPostalCodeError(
        translate[language].postalCodeValidation ||
          "El código postal debe ser de 5 dígitos numéricos."
      );
      isValid = false;
    } else {
      setPostalCodeError("");
    }

    return isValid;
  };

  const areFieldsValidSecondStep = () => {
    let isValid = true;
    let onlyTermsError = true;

    // Validación para el campo del correo electrónico
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
      setEmailError(translate[language].emailValidation);
      isValid = false;
      onlyTermsError = false;
    } else {
      setEmailError("");
    }

    // Validación para el campo de la contraseña
    if (!/[A-Z]/.test(password) || password.length < 6) {
      setPasswordError(translate[language].passwordValidationMissing);
      isValid = false;
      onlyTermsError = false;
    } else {
      setPasswordError("");
    }

    // Validación para el campo de repetir contraseña
    if (repeatPassword !== password) {
      setRepeatPasswordError(translate[language].repeatPasswordValidation);
      isValid = false;
      onlyTermsError = false;
    } else {
      setRepeatPasswordError("");
    }

    // Verificación para el correo electrónico registrado
    if (isEmailRegistered) {
      setEmailError(
        translate[language].emailAlreadyRegistered ||
          "Este correo electrónico ya está registrado"
      );
      isValid = false;
      onlyTermsError = false;
    }

    // Validación para los términos y condiciones
    if (!termsChecked) {
      setShowTermsError(true);
      startShake();
      isValid = false;
      if (onlyTermsError) {
        scrollToTerms();
      }
    } else {
      setShowTermsError(false);
    }

    return isValid;
  };

  const scrollToTerms = () => {
    scrollViewRef.current?.scrollTo({
      y: 400, // Desplazamiento en píxeles
      animated: true, // Habilita la animación suave
    });
  };
  useEffect(() => {
    return () => {
      if (resendInterval.current) {
        clearInterval(resendInterval.current);
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      // Limpieza de temporizadores
      if (emailTimer) clearTimeout(emailTimer);
      if (passwordTimer) clearTimeout(passwordTimer);
      if (repeatPasswordTimer) clearTimeout(repeatPasswordTimer);
    };
  }, [emailTimer, passwordTimer, repeatPasswordTimer]);

  const handleRegister = async () => {
    if (Platform.OS !== "web") {
      // Añadir la respuesta háptica antes de continuar con la lógica de registro
      await Haptic.impactAsync(Haptic.ImpactFeedbackStyle.Heavy);
    }
    if (isRegistering) return; // Evitar procesamiento adicional si ya se está registrando

    if (currentStep === 0) {
      if (areFieldsValidPostalStep()) {
        setCurrentStep(1);
      }
    } else if (currentStep === 1) {
      if (areFieldsValidFirstStep()) {
        setCurrentStep(2);
      }
    } else if (currentStep === 2) {
      if (areFieldsValidSecondStep()) {
        setIsRegistering(true); // Empezar el registro y marcar como en proceso
        setShowTermsError(false);

        try {
          const response = await fetch(`${serverAddress}/newuser`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              firstName,
              lastName,
              email,
              password,
              postalCode,
              modoNegoci: isBusiness ? "E" : "P",
              idioma: language,
            }),
          });

          const data = await response.json();
          if (response.status === 200 || response.status === 201) {
            if (data.token && data.token !== "NOK") {
              if (Platform.OS === "web") {
                localStorage.setItem("userToken", data.token);
              } else {
                await SecureStore.setItemAsync("userToken", data.token);
              }
              setToken(data.token);
              showVerificationModal();
            } else {
              alert(
                translate[language].registerError ||
                  "Error en el registro. Por favor, inténtelo de nuevo."
              );
            }
          } else {
            alert(
              "Error en el registro: " + (data.error || "Error desconocido")
            );
          }
        } catch (error) {
          alert("Error en el proceso de registro: " + error.message);
        } finally {
          setIsRegistering(false); // Finalizar el proceso de registro
        }
      }
    }
  };

  const handleUsePostalCode = (code) => {
    setPostalCode(code); // Establece el código postal ingresado
    handleGuestLogin(code); // Llama a tu lógica de login como invitado con este código
    setIsNeedPostalVisible(false); // Cierra el modal
  };

  const handleUseRandomPostalCode = () => {
    const randomCode = "08030"; // Define aquí tu lógica para un código aleatorio si es necesario
    handleGuestLogin(randomCode); // Llama a tu lógica de login como invitado con el código aleatorio
    setIsNeedPostalVisible(false); // Cierra el modal
  };

  const handleGuestLogin = () => {
    if (postalCode) {
      loginGuest(postalCode);
    } else {
      setIsNeedPostalVisible(true); // Muestra el modal para el código postal
    }
  };

  // Función para manejar el inicio de sesión como invitado
  // Asume que esta función está dentro de tu componente Register
  const loginGuest = async (postalCode) => {
    const body = JSON.stringify({
      email: "guest@trocalia.net",
      zona: postalCode,
    });

    try {
      const response = await fetch(`${serverAddress}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: body,
      });

      const data = await response.json();

      if (response.ok && data.tokenGuest) {
        if (Platform.OS === "web") {
          localStorage.setItem("userToken", data.tokenGuest);
        } else {
          await SecureStore.setItemAsync("userToken", data.tokenGuest);
        }
        setToken(data.tokenGuest);
        setIsNeedPostalVisible(false); // Oculta el modal si estaba abierto
        navigation.navigate("Home");
      } else {
        alert("No se pudo iniciar sesión como invitado.");
      }
    } catch (error) {
      //console.error("Error durante la autenticación de invitado", error);
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <NotificationPlatform />
      <ScrollView
        ref={scrollViewRef}
        contentContainerStyle={{ ...styles.container, paddingBottom: 0 }}
      >
        <Animated.View
          style={[
            styles.blueHeader,
            {
              transform: [{ translateY: headerSlideAnim }],
            },
          ]}
        >
          <Svg height={svgHeight} width={svgWidth}>
            <Path
              d={`M0,0 L${svgWidth},0 L${svgWidth},160 C${svgWidth},160 ${
                svgWidth / 2
              },200 0,160 Z`}
              fill={Colors.primary}
            />
          </Svg>
        </Animated.View>

        <View style={styles.loginFormWrapper}>
          <Animated.View style={{ opacity: logoFadeAnim }}>
            <View style={styles.trocaliaContainer}>
              <Image
                style={styles.trocaliaLogo}
                resizeMode="cover"
                source={trocaliaIcon}
              />
            </View>
          </Animated.View>

          {currentStep === 0 && (
            <View style={styles.textWrapper}>
              <Text style={styles.textSemiBold}>
                {translate[language].text0}
              </Text>
              <Text style={[styles.textRegular, styles.textSpacing]}>
                {translate[language].text0mini2}
              </Text>
            </View>
          )}

          {currentStep === 1 && (
            <View style={styles.textWrapper}>
              <Text style={styles.textSemiBold}>
                {translate[language].text1}
              </Text>
              <Text style={[styles.textRegular, styles.textSpacing]}>
                {translate[language].text1mini}
              </Text>
            </View>
          )}

          {currentStep === 2 && (
            <View style={styles.textWrapper}>
              <Text style={styles.textSemiBold}>
                {translate[language].text2}
              </Text>
              <Text style={[styles.textRegular, styles.textSpacing]}>
                {translate[language].text2mini}
              </Text>
            </View>
          )}

          {currentStep === 0 && (
            // Paso 1: Solo Código Postal
            <View>
              <View style={styles.inputWrapper}>
                <Animated.View
                  style={[
                    {
                      transform: [{ translateX: shakeInputAnim }],
                      position: "relative",
                    },
                  ]}
                >
                  <TextInput
                    style={[
                      styles.inputBox,
                      styles.inputBorder,
                      { fontStyle: "italic", borderColor: Colors.primary },
                    ]}
                    value={postalCode}
                    onChangeText={(text) => {
                      setPostalCode(text);
                      if (text.length === 5) {
                        validatePostalCode(text); // Llamar a la función de validación cuando la longitud sea 5
                      }
                    }}
                    placeholder="" // Ocultar el placeholder original
                    placeholderTextColor={Colors.grayMediumDark}
                    keyboardType="numeric"
                    maxLength={5} // Limitar a 5 dígitos
                  />
                  {/* Placeholder Animado */}
                  {postalCode === "" && (
                    <Text style={styles.animatedPlaceholderText}>
                      {animatedPlaceholder}
                    </Text>
                  )}
                  {postalCodeError ? (
                    <Text style={{ color: "red", marginTop: 8 }}>
                      {postalCodeError}
                    </Text>
                  ) : postalCodeSuccess ? (
                    <Text style={{ color: "green", marginTop: 8 }}>
                      {postalCodeSuccess}
                    </Text>
                  ) : null}
                </Animated.View>
                <Text style={[styles.inputLabel, styles.labelText]}>
                  {translate[language].postalCodeLabel}
                </Text>
              </View>
              <TouchableOpacity
                style={[
                  styles.loginBtnBox,
                  styles.standardLayout,
                  {
                    backgroundColor: isPostalCodeValid
                      ? Colors.primary
                      : Colors.grayMedium,
                  },
                ]}
                onPress={() => {
                  if (isPostalCodeValid) {
                    handleRegister();
                  } else {
                    shakeInput();
                  }
                }}
              >
                <Text style={[styles.loginBtnText, styles.btnText]}>
                  {translate[language].next}
                </Text>
              </TouchableOpacity>
              {/* Ja registrat? */}
              <TouchableOpacity onPress={() => navigation.navigate("Login")}>
                <View style={styles.bottomSection}>
                  <Text style={[styles.rememberText, styles.standardText]}>
                    {translate[language].alreadyRegisteredText}
                  </Text>
                  <Text
                    style={[
                      styles.forgotPasswordText,
                      styles.standardText,
                      { color: Colors.grayMedium }, // Cambiar color a gray.medium
                    ]}
                  >
                    {translate[language].loginText}
                  </Text>
                </View>
              </TouchableOpacity>
              {showUserCount && (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginVertical: 10,
                  }}
                >
                  <Text style={[Fonts.poppinsMedium16]}>
                    {translate[language].users1}
                  </Text>
                  <View
                    style={{
                      backgroundColor: Colors.primary, // Uso del color primario para el fondo
                      paddingHorizontal: 4, // Reducir el espacio horizontal para un impacto visual menor
                      borderRadius: 6, // Bordes ligeramente redondeados
                      borderWidth: 1, // Borde más delgado
                      borderColor: Colors.grayMedium, // Cambiado a color gris para el borde
                    }}
                  >
                    <Text
                      style={[
                        Fonts.poppinsSemiBold16,
                        { color: Colors.white, paddingVertical: 1 },
                      ]}
                    >
                      {numUsuarios}
                    </Text>
                  </View>
                  <Text style={[Fonts.poppinsMedium16]}>
                    {translate[language].users2}
                  </Text>
                </View>
              )}

              {/* Botón para acceso como invitado */}
              {permitirGuest && (
                <View style={styles.userGuestTextContainer}>
                  <TouchableOpacity onPress={handleGuestLogin}>
                    <Text style={styles.userGuestPart1Style}>
                      {translate[language].userGuestPart1}
                    </Text>
                  </TouchableOpacity>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <TouchableOpacity onPress={handleGuestLogin}>
                      <Text style={styles.userGuestPart2Style}>
                        {translate[language].userGuestPart2}
                      </Text>
                    </TouchableOpacity>
                    <View style={styles.verticalSeparator}></View>
                    <TouchableOpacity
                      onPress={() =>
                        Linking.openURL(
                          "https://youtube.com/shorts/EcF_RhF7K-o"
                        )
                      }
                    >
                      <Text style={styles.videoLinkStyle}>
                        {translate[language].video}
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              )}
            </View>
          )}
          {currentStep === 1 && (
            <View>
              {/* Input para Nombre */}
              <View style={styles.inputWrapper}>
                <View>
                  <TextInput
                    style={[styles.inputBox, styles.inputBorder]}
                    value={firstName}
                    onChangeText={validateFirstName}
                    placeholder={translate[language].firstNamePlaceholder}
                  />
                  {firstNameError ? (
                    <Text style={{ color: "red" }}>{firstNameError}</Text>
                  ) : null}
                </View>
                <Text style={[styles.inputLabel, styles.labelText]}>
                  {translate[language].firstName}
                </Text>
              </View>

              {/* Input para Apellido */}
              <View style={styles.inputWrapper}>
                <View>
                  <TextInput
                    style={[styles.inputBox, styles.inputBorder]}
                    value={lastName}
                    onChangeText={validateLastName}
                    placeholder={translate[language].lastNamePlaceholder}
                  />
                  {lastNameError ? (
                    <Text style={{ color: "red" }}>
                      {translate[language].lastNameValidation}
                    </Text>
                  ) : null}
                </View>
                <Text style={[styles.inputLabel, styles.labelText]}>
                  {translate[language].lastName}
                </Text>
              </View>
              <TouchableOpacity
                style={[styles.loginBtnBox, styles.standardLayout]}
                onPress={handleRegister} // Esta función ahora manejará el cambio de paso
              >
                <Text style={[styles.loginBtnText, styles.btnText]}>
                  {translate[language].next}
                </Text>
              </TouchableOpacity>
              {/* Ja registrat? */}
              <TouchableOpacity onPress={() => navigation.navigate("Login")}>
                <View style={styles.bottomSection}>
                  <Text style={[styles.rememberText, styles.standardText]}>
                    {translate[language].alreadyRegisteredText}
                  </Text>
                  <Text
                    style={[
                      styles.forgotPasswordText,
                      styles.standardText,
                      { color: Colors.grayMedium }, // Cambiar color a gray.medium
                    ]}
                  >
                    {translate[language].loginText}
                  </Text>
                </View>
              </TouchableOpacity>

              {/* Botón para acceso como invitado */}
              {permitirGuest && (
                <View style={styles.userGuestTextContainer}>
                  <TouchableOpacity onPress={handleGuestLogin}>
                    <Text style={styles.userGuestPart1Style}>
                      {translate[language].userGuestPart1}
                    </Text>
                  </TouchableOpacity>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <TouchableOpacity onPress={handleGuestLogin}>
                      {/* <Text style={styles.userGuestPart2Style}>
                        {translate[language].userGuestPart2}
                      </Text> */}
                    </TouchableOpacity>
                    {/* <View style={styles.verticalSeparator}></View> */}
                    <TouchableOpacity
                      onPress={() =>
                        Linking.openURL(
                          "https://youtu.be/QHBNbg13-QI?si=g33PnLfqRaePgp9f"
                        )
                      }
                    >
                      <Text style={styles.videoLinkStyle}>
                        {translate[language].video}
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              )}
            </View>
          )}

          {currentStep === 2 && (
            <View>
              {/* Input para Email */}
              <View style={styles.inputWrapper}>
                <View>
                  <TextInput
                    style={[styles.inputBox, styles.inputBorder]}
                    value={email}
                    onChangeText={validateEmail}
                    onBlur={() => checkEmail(email)} // Verificar el correo electrónico al cambiar el foco
                    placeholder={translate[language].emailPlaceholder}
                  />
                  {emailError ? (
                    <Text style={{ color: "red" }}>{emailError}</Text>
                  ) : null}
                </View>
                <Text style={[styles.inputLabel, styles.labelText]}>Email</Text>
              </View>

              {/* Input para Password */}
              <View style={styles.passwordInputWrapper}>
                <View>
                  <TextInput
                    style={[styles.passwordInputBox, styles.standardLayout]}
                    value={password}
                    onChangeText={validatePassword}
                    placeholder={translate[language].passwordPlaceholder}
                    secureTextEntry={!showPassword}
                  />
                  <TouchableOpacity
                    onPress={() => setShowPassword(!showPassword)}
                  >
                    <Image
                      style={styles.visibilityIcon}
                      resizeMode="cover"
                      source={require("../img/icons/eye.png")}
                    />
                  </TouchableOpacity>
                  {passwordError ? (
                    <Text style={{ color: "red" }}>{passwordError}</Text>
                  ) : null}
                </View>
                <Text style={[styles.passwordLabel, styles.labelText]}>
                  Password
                </Text>
              </View>

              {/* Input para Repetir Contraseña */}
              <View
                style={[styles.inputWrapper, styles.repeatPasswordInputWrapper]}
              >
                <View>
                  <TextInput
                    style={[styles.inputBox, styles.inputBorder]}
                    value={repeatPassword}
                    onChangeText={validateRepeatPassword}
                    placeholder={translate[language].repeatPasswordPlaceholder}
                    secureTextEntry={!showRepeatPassword}
                  />
                  <TouchableOpacity
                    style={styles.eyeIconContainer}
                    onPress={() => setShowRepeatPassword(!showRepeatPassword)}
                  >
                    <Image
                      style={styles.visibilityIcon}
                      resizeMode="cover"
                      source={require("../img/icons/eye.png")}
                    />
                  </TouchableOpacity>
                  {repeatPasswordError ? (
                    <Text style={{ color: "red" }}>{repeatPasswordError}</Text>
                  ) : null}
                </View>
                <Text style={[styles.inputLabel, styles.labelText]}>
                  Password
                </Text>
              </View>

              {/* Pregunta si es empresa y Switch */}
              <View style={styles.switchContainer}>
                <Text style={[styles.switchLabel, Fonts.poppinsRegular16]}>
                  {translate[language].areyouBusiness}
                </Text>
                <Switch
                  value={isBusiness}
                  onValueChange={setIsBusiness}
                  style={styles.switchInstance}
                />
              </View>

              {/* Botón para completar el registro */}
              <View style={styles.loginBtnWrapper}>
                <TouchableOpacity
                  style={[
                    styles.loginBtnBox,
                    isRegistering && styles.disabledButton,
                  ]}
                  onPress={handleRegister}
                  disabled={isRegistering}
                >
                  <Text style={styles.loginBtnText}>
                    {isRegistering
                      ? "Registrando..."
                      : translate[language].registerButtonText}
                  </Text>
                </TouchableOpacity>
              </View>
              {/* Ja registrat? */}
              <Modal
                animationType="slide"
                transparent={true}
                visible={isVerificationModalVisible}
                onRequestClose={hideVerificationModal}
              >
                <View style={styles.modalBackground}>
                  <View style={styles.modalView}>
                    <Text style={styles.modalTitle}>
                      {translate[language].verificationModalTitle}
                    </Text>
                    <Text style={styles.modalMessage}>
                      {
                        translate[language].verificationModalMessage.split(
                          "{email}"
                        )[0]
                      }
                      <Text style={styles.boldText}>{email}</Text>
                      {
                        translate[language].verificationModalMessage.split(
                          "{email}"
                        )[1]
                      }
                      <Text style={styles.boldText}>
                        {translate[language].spamMessage}
                      </Text>
                    </Text>
                    <TextInput
                      style={styles.inputCodeBox}
                      value={verificationCode}
                      onChangeText={setVerificationCode}
                      placeholder={
                        translate[language].verificationCodePlaceholder
                      }
                      keyboardType="numeric"
                      maxLength={4}
                    />
                    {verificationError ? (
                      <Text
                        style={{
                          color: "red",
                          textAlign: "center",
                          marginTop: 10,
                        }}
                      >
                        {verificationError}
                      </Text>
                    ) : null}
                    <View style={styles.buttonContainer}>
                      <TouchableOpacity
                        style={styles.button}
                        onPress={handleCodeSubmit}
                      >
                        <Text style={styles.buttonText}>
                          {translate[language].verificationButton}
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={[
                          styles.resendButton,
                          resendButtonDisabled
                            ? styles.resendButtonDisabled
                            : null,
                        ]}
                        onPress={handleResendCode}
                        disabled={resendButtonDisabled}
                      >
                        <Text style={styles.resendButtonText}>
                          {translate[language].notReceivedQuery}
                        </Text>
                        <Text
                          style={
                            resendButtonDisabled
                              ? styles.resendButtonTextDisabled
                              : styles.resendButtonTextBold
                          }
                        >
                          {translate[language].resendCodeButton}{" "}
                          {!resendButtonDisabled ? null : ` (${resendTimer}s)`}
                        </Text>
                      </TouchableOpacity>

                      {resendCodeSuccessMessage ? (
                        <Text
                          style={{
                            color: "green",
                            textAlign: "center",
                            marginTop: 10,
                          }}
                        >
                          {resendCodeSuccessMessage}
                        </Text>
                      ) : null}
                    </View>
                    <Text style={styles.verificationInstructions}>
                      {translate[language].verificationInstructions}
                    </Text>
                    <TouchableOpacity
                      onPress={() => {
                        hideVerificationModal();
                        setCurrentStep(2); // Asegúrate de que este es el paso correcto para editar el correo
                      }}
                      style={styles.editButton}
                    >
                      <Text style={styles.editButtonText}>
                        {translate[language].editEmailButton}
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </Modal>
              {/* <TouchableOpacity onPress={() => navigation.navigate("Login")}>
                <View style={styles.bottomSection}>
                  <Text style={[styles.rememberText, styles.standardText]}>
                    {translate[language].alreadyRegisteredText}
                  </Text>
                  <Text
                    style={[
                      styles.forgotPasswordText,
                      styles.standardText,
                      { color: Colors.grayMedium }, // Cambiar color a gray.medium
                    ]}
                  >
                    {translate[language].loginText}
                  </Text>
                </View>
              </TouchableOpacity> */}

              {/* Checkbox para Términos y Condiciones */}

              <View style={styles.checkboxContainer}>
                <TouchableOpacity
                  onPress={() => setTermsChecked(!termsChecked)}
                  style={styles.touchableArea}
                >
                  <Animated.View
                    style={{ transform: [{ translateX: shakeAnimation }] }}
                  >
                    <CheckBox
                      value={termsChecked}
                      onValueChange={(newValue) => {
                        setTermsChecked(newValue);
                        if (newValue) {
                          setShowTermsError(false);
                        }
                      }}
                      style={[
                        styles.checkbox,
                        showTermsError ? styles.checkboxError : null,
                      ]}
                    />
                  </Animated.View>
                </TouchableOpacity>
                <View style={styles.termsWrapper}>
                  <Text style={styles.termsText}>
                    {translate[language].terms}{" "}
                    <TouchableOpacity
                      onPress={() => navigation.navigate("Terms")}
                    >
                      <Text style={styles.termsLink}>
                        {translate[language].conditions}
                      </Text>
                    </TouchableOpacity>{" "}
                    <TouchableOpacity
                      onPress={() => navigation.navigate("Privacy")}
                    >
                      <Text style={styles.termsLink}>
                        {translate[language].privacyPolicy}
                      </Text>
                    </TouchableOpacity>
                    .{" "}
                    <Text style={styles.termsText}>
                      {translate[language].faq2}{" "}
                    </Text>
                    <TouchableOpacity
                      onPress={() => navigation.navigate("Faq")}
                    >
                      <Text style={styles.termsLink}>
                        {translate[language].faq}
                      </Text>
                    </TouchableOpacity>
                  </Text>
                </View>
              </View>
              {showTermsError && (
                <Text style={styles.errorText}>
                  {translate[language].termsError}
                </Text>
              )}
            </View>
          )}
        </View>
        <Explain />
        <NeedPostal
          isVisible={isNeedPostalVisible}
          onClose={() => setIsNeedPostalVisible(false)}
          onUsePostalCode={loginGuest} // Llama a `loginGuest` con el código ingresado
          onUseRandomPostalCode={() => loginGuest("08030")} // Usa "08030" como código predeterminado
        />
      </ScrollView>
      <Modal
        visible={isQrModalVisible}
        transparent={true}
        animationType="slide"
        onRequestClose={closeQrModal}
      >
        <TouchableOpacity
          style={styles.modalContainer}
          activeOpacity={1}
          onPress={closeQrModal}
        >
          <View style={styles.modalContent}>
            <Text style={styles.modalTitle}>{translate[language].qr}</Text>
            <Image style={styles.qrImage} source={require("../img/QR.webp")} />
            <TouchableOpacity onPress={closeQrModal} style={styles.closeButton}>
              <Text style={styles.closeButtonText}>
                {translate[language].close}
              </Text>
            </TouchableOpacity>
          </View>
        </TouchableOpacity>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: "#FFFFFF",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  blueHeader: {
    width: "100%",
    height: 120,
    marginTop: -40,
    backgroundColor: Colors.primary,
    zIndex: 0,
    borderBottomLeftRadius: 30,
    borderBottomRightRadius: 30,
    alignItems: "center",
    ...Platform.select({
      web: {
        marginTop: 20,
      },
    }),
  },
  trocaliaContainer: {
    marginTop: 20,
    alignItems: "center",
  },
  trocaliaLogo: {
    width: 240,
    height: 50,
    top: -40,
    ...Platform.select({
      web: {
        top: -90,
      },
    }),
  },
  loginFormWrapper: {
    width: 315,
    marginTop: 0,
    alignItems: "center",
  },
  emailInputWrapper: {
    marginTop: 20,
  },
  emailInputBox: {
    width: 312,
    height: 49,
    borderColor: "rgba(0, 0, 0, 0.4)",
    borderRadius: 10,
    borderWidth: 1,
    backgroundColor: "rgba(255, 255, 255, 0.08)",
    color: Colors.grayDark,
  },
  emailLabel: {
    fontFamily: Fonts.poppinsRegular14.fontFamily,
    color: "#695c5c",
    position: "absolute",
    top: -20,
    left: "50%",
    transform: [{ translateX: -25 }],
  },
  passwordInputWrapper: {
    marginTop: 20,
    marginBottom: 20,
  },
  passwordInputBox: {
    width: 312,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "rgba(0, 0, 0, 0.4)",
    borderRadius: 10,
    backgroundColor: "rgba(255, 255, 255, 0.08)",
    color: Colors.grayDark,
    paddingLeft: 10,
    paddingRight: 40,
  },
  inputWrapper: {
    marginTop: 20,
    position: "relative",
  },
  repeatPasswordInputWrapper: {
    marginTop: 0,
  },
  inputBox: {
    width: 312,
    height: 49,
    borderColor: "rgba(0, 0, 0, 0.4)",
    borderRadius: 10,
    borderWidth: 1,
    backgroundColor: "rgba(255, 255, 255, 0.08)",
    color: Colors.grayDark,
    paddingLeft: 10, // Asegura que el texto no se superponga con el placeholder
    paddingRight: 40, // Espacio para el ícono de visibilidad si es necesario
  },
  inputLabel: {
    fontFamily: Fonts.poppinsRegular14.fontFamily,
    color: Colors.grayDark,
    position: "absolute",
    top: -20,
    left: "50%",
    transform: [{ translateX: -25 }], // Ajustado para la nueva posición
  },
  visibilityIcon: {
    width: 18,
    height: 18,
    position: "absolute",
    top: -35,
    right: 10,
  },
  passwordLabel: {
    fontFamily: Fonts.poppinsRegular14.fontFamily,
    color: "#695c5c",
    position: "absolute",
    top: -20,
    left: "50%",
    transform: [{ translateX: -39 }],
  },
  loginBtnWrapper: {
    marginTop: 0,
  },
  loginBtnBox: {
    borderRadius: 5,
    backgroundColor: Colors.primary,
    width: 312,
    height: 48,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 28,
    marginTop: 44, // Aumentar el margen superior
  },
  loginBtnText: {
    color: "white",
    fontFamily: Fonts.poppinsSemiBold16.fontFamily,
    fontSize: 16,
  },
  bottomSection: {
    flexDirection: "row",
    marginTop: 10,
    marginBottom: 20,
    alignItems: "center",
    justifyContent: "center",
  },
  rememberText: {
    ...Fonts.poppinsRegular16,
    color: Colors.grayDark,
  },
  forgotPasswordText: {
    marginLeft: 10,
    fontWeight: "500",
    fontFamily: "Poppins-Medium",
    color: Colors.primary,
    // textDecorationLine: "underline",
  },

  flexCenter: {
    alignItems: "center",
  },
  standardText: {
    ...Fonts.poppinsRegular16,
  },
  standardLayout: {
    width: 312,
    height: 48,
  },
  inputBorder: {
    borderWidth: 1,
    borderStyle: "solid",
  },
  labelText: {
    ...Fonts.poppinsRegular14,
    height: 21,
    textAlign: "center",
    color: "#695c5c",
    position: "absolute",
    top: -20,
    left: "50%",
    transform: [{ translateX: 65 }], // Ajustado para la nueva posición
  },
  switchContainer: {
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 20,
  },
  switchLabel: {
    ...Fonts.poppinsRegular16,
    color: Colors.grayDark,
  },
  switchInstance: {
    marginTop: 10,
    // marginBottom: 10,
  },
  checkboxContainer: {
    flexDirection: "space-between",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10,
  },
  checkbox: {
    marginRight: 10,
    marginLeft: 0,
  },
  checkboxLabel: {
    width: 320,
  },
  errorText: {
    color: "red",
    textAlign: "center",
    marginTop: 5,
  },
  label: {
    margin: 8,
  },
  checkboxError: {
    borderColor: "red",
    borderWidth: 2,
  },
  touchableArea: {
    padding: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  // termsWrapper: {
  //   marginTop: 10,
  //   flexDirection: "row",
  //   flexWrap: "wrap",
  //   alignItems: "center",
  //   justifyContent: "center",
  // },
  termsWrapper: {
    width: "100%",
    marginTop: 10,
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 300,
  },
  termsText: {
    fontSize: 14,
    color: "#000",
    lineHeight: 25,
  },
  termsLink: {
    color: Colors.primary,
    textDecorationLine: "underline",
    lineHeight: 20,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },

  modalView: {
    backgroundColor: "white",
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: "85%",
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 15,
    color: Colors.primary,
  },
  modalMessage: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 20,
    color: Colors.grayDark,
  },
  boldText: {
    fontWeight: "bold", // Estilo para negrita
  },
  inputCodeBox: {
    width: 200,
    height: 40,
    textAlign: "center",
    marginVertical: 20,
    borderWidth: 1,
    borderColor: Colors.primary,
    borderRadius: 10,
  },
  buttonContainer: {
    marginTop: 20,
    alignItems: "center",
  },
  button: {
    backgroundColor: "#4CAF50",
    borderRadius: 20,
    paddingVertical: 15,
    paddingHorizontal: 30,
    elevation: 2,
    width: "85%",
    marginBottom: 16,
  },
  buttonText: {
    color: "white",
    fontWeight: "bold",
    fontSize: 18,
    textAlign: "center",
  },
  resendButton: {
    backgroundColor: "#f8d7da",
    borderRadius: 20,
    padding: 10,
    elevation: 2,
    marginTop: 10,
    width: "75%",
    alignItems: "center",
    marginBottom: 14,
  },
  resendButtonText: {
    color: "#721c24",
    textAlign: "center",
  },

  modalBackground: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  resendButtonTextBold: {
    color: "#721c24",
    textAlign: "center",
    fontWeight: "bold",
  },
  verificationInstructions: {
    color: Colors.grayDark,
    textAlign: "center",
    marginTop: 10,
    fontSize: 11,
    lineHeight: 14,
  },
  textWrapper: {
    paddingHorizontal: 4, // Ajusta este valor según sea necesario
    alignItems: "flex-start", // Alinea los elementos hijos a la izquierda
    width: "100%", // Asegura que el contenedor tenga el ancho completo
    marginTop: Platform.select({
      web: -40, // Menor margen para web
      default: 34, // Margen mayor para otras plataformas (iOS, Android)
    }),
    marginBottom: 20,
  },
  textSemiBold: {
    ...Fonts.poppinsMedium18,
    color: Colors.grayDark,
    // textAlign: 'center',
  },
  textRegular: {
    ...Fonts.poppinsRegular16,
    color: Colors.grayDark,
    // textAlign: 'center',
  },
  userGuestTextContainer: {
    marginTop: 8,
  },
  userGuestPart1Style: {
    color: Colors.grayDark, // Asegura este color se define en tu estructura de estilos
    fontSize: 16,
    marginTop: 15, // Ajusta según necesites para más espacio
    textAlign: "center", // Asegura que el texto esté centrado
  },
  userGuestPart2Style: {
    color: "green", // Color verde para este texto
    fontSize: 16,
    textDecorationLine: "underline", // Subraya el texto
    marginTop: 5, // Espacio entre userGuestPart1 y userGuestPart2
    textAlign: "center", // Asegura que el texto esté centrado
  },
  verticalSeparator: {
    height: "100%", // Ajusta la altura según necesites
    width: 1,
    backgroundColor: "#000", // O el color que prefieras
    marginHorizontal: 10, // Espacio horizontal para separar el texto del separador
    marginTop: 5, // Ajusta según necesites para más espacio
  },

  videoLinkStyle: {
    color: "green", // Color verde para este texto
    fontSize: 16,
    textDecorationLine: "underline", // Subraya el texto
    marginTop: 5, // Espacio entre userGuestPart1 y userGuestPart2
    textAlign: "center", // Asegura que el texto esté centrado
  },
  disabledButton: {
    backgroundColor: "#ccc",
    borderColor: "#aaa",
  },
  editButton: {
    marginTop: 10,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    backgroundColor: "#f0f0f0", // Un color neutro que funcione bien con el resto de la interfaz
    borderWidth: 1,
    borderColor: "#dcdcdc",
  },
  editButtonText: {
    textAlign: "center",
    color: "#333", // Asegurando buena legibilidad
    fontFamily: Fonts.poppinsRegular14.fontFamily, // Asegura que usas la fuente correcta
    fontSize: Fonts.poppinsRegular14.fontSize, // Tamaño de fuente consistente
  },
  textSpacing: {
    marginTop: 6, // Ajusta este valor según necesites
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalContent: {
    width: "80%",
    backgroundColor: "#fff",
    padding: 20,
    borderRadius: 10,
    alignItems: "center",
  },
  modalTitle: {
    fontSize: 20,
    marginBottom: 20,
  },
  qrImage: {
    width: Dimensions.get("window").width * 0.8, // 80% del ancho de la pantalla
    height: Dimensions.get("window").height * 0.8, // 80% de la altura de la pantalla
    resizeMode: "contain",
  },
  closeButton: {
    marginTop: 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: "#007BFF",
    borderRadius: 5,
  },
  closeButtonText: {
    color: "#fff",
    fontSize: 16,
  },
  animatedPlaceholderText: {
    position: "absolute",
    left: 10, // Ajusta este valor según el padding de tu TextInput
    top: 14, // Ajusta este valor para alinear verticalmente con el texto del input
    color: Colors.grayMediumDark,
    fontStyle: "italic",
    pointerEvents: "none", // Asegura que el texto no interfiera con las interacciones del usuario
  },
  resendButtonDisabled: {
    backgroundColor: "#ccc", // Color gris para indicar deshabilitado
  },
  resendButtonTextDisabled: {
    color: "#666", // Texto más oscuro para el estado deshabilitado
  },
});
